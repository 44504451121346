.gj-button-md,
.gj-text-align-center {
  text-align: center;
}
.gj-button-md,
.gj-modal {
  overflow: hidden;
  outline: 0;
}
.gj-button-md,
.gj-button-md-group {
  position: relative;
  vertical-align: middle;
}
.gj-button,
.gj-button-md,
.gj-cursor-pointer {
  cursor: pointer;
}
.gj-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #000;
  border-radius: 3px;
  padding: 6px 10px;
}
.gj-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gj-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gj-margin-left-5 {
  margin-left: 5px;
}
.gj-margin-left-10 {
  margin-left: 10px;
}
.gj-width-full {
  width: 100%;
}
.gj-font-size-16 {
  font-size: 16px;
}
.gj-hidden {
  display: none;
}
.gj-button-md {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  line-height: 36px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gj-button-md:hover {
  background-color: rgba(158, 158, 158, 0.2);
}
.gj-button-md:disabled {
  color: rgba(0, 0, 0, 0.26);
  background: 0 0;
}
.gj-button-md .gj-icon,
.gj-button-md .material-icons {
  vertical-align: middle;
}
.gj-button-md.gj-button-md-icon {
  width: 24px;
  height: 31px;
  min-width: 24px;
  padding: 0;
  display: table;
}
ul.gj-list-bootstrap li [data-role="image"],
ul.gj-list-md li [data-role="checkbox"],
ul.gj-list-md li [data-role="image"] {
  height: 24px;
  width: 24px;
}
.gj-button-md.gj-button-md-icon .gj-icon,
.gj-button-md.gj-button-md-icon .material-icons {
  display: table-cell;
  margin-right: 0;
  width: 24px;
  height: 24px;
}
.gj-button-md.active {
  background-color: rgba(158, 158, 158, 0.4);
}
.gj-button-md-group {
  display: inline-block;
}
.gj-textbox-md {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  padding: 4px 0;
  margin: 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
.gj-textbox-md:active,
.gj-textbox-md:focus {
  /* border-bottom: 2px solid rgba(0, 0, 0, 0.42); */
  outline: 0;
}
.gj-textbox-md::placeholder {
  color: #7e7172;;
}
.gj-textbox-md:-ms-input-placeholder {
  color: #7e7172;;
}
.gj-textbox-md::-ms-input-placeholder {
  color: #7e7172;;
}
.gj-md-spacer-24 {
  min-width: 24px;
  width: 24px;
  display: inline-block;
}
.gj-md-spacer-32 {
  min-width: 32px;
  width: 32px;
  display: inline-block;
}
.gj-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1203;
  display: none;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.54118);
  transition: 0.2s ease opacity;
  will-change: opacity;
}
ul.gj-list li [data-role="wrapper"] {
  display: table;
  width: 100%;
}
ul.gj-list li [data-role="checkbox"],
ul.gj-list li [data-role="image"] {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
ul.gj-list li [data-role="display"] {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}
ul.gj-list li [data-role="display"]:empty:before {
  content: "\200b";
}
ul.gj-list-bootstrap {
  padding-left: 0;
  margin-bottom: 0;
}
ul.gj-list-bootstrap li {
  padding: 0;
}
ul.gj-list-bootstrap li [data-role="wrapper"] {
  padding: 0 10px;
}
ul.gj-list-bootstrap li [data-role="checkbox"] {
  width: 24px;
  padding: 3px;
}
ul.gj-list-bootstrap li [data-role="display"] {
  padding: 8px 0 8px 4px;
}
.list-group-item.active ul li,
.list-group-item.active:focus ul li,
.list-group-item.active:hover ul li {
  text-shadow: none;
  color: initial;
}
ul.gj-list-md {
  padding: 0;
  list-style: none;
  line-height: 24px;
  letter-spacing: 0;
  color: #616161;
}
.gj-picker-md,
ul.gj-list-md li {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1;
}
ul.gj-list-md li {
  display: list-item;
  list-style-type: none;
  padding: 0;
  min-height: unset;
  box-sizing: border-box;
  align-items: center;
  cursor: default;
  overflow: hidden;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
ul.gj-list-md li [data-role="display"] {
  padding: 8px 0 8px 5px;
  order: 0;
  flex-grow: 2;
  text-decoration: none;
  box-sizing: border-box;
  align-items: center;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
ul.gj-list-md li.disabled > [data-role="wrapper"] > [data-role="display"] {
  color: #9e9e9e;
}
.gj-list-md-active {
  background: #e0e0e0;
  color: #ff3b00;
}
.gj-picker {
  position: absolute;
  z-index: 1203;
  background-color: #fff;
}
.gj-picker .selected {
  color: #fff;
}
.gj-picker-md {
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e0e0e0;
}
.gj-modal .gj-picker-md {
  border: 0;
}
.gj-picker-md [role="header"] {
  color: rgba(255, 255, 255, 0.54);
  display: flex;
  background: #ff3b00;
  align-items: baseline;
  user-select: none;
  justify-content: center;
}
.gj-picker-md [role="footer"] {
  float: right;
  padding: 10px;
}
.gj-picker-md [role="footer"] button.gj-button-md {
  color: #ff3b00;
  font-weight: 700;
  font-size: 13px;
}
.gj-picker-bootstrap {
  border: 1px solid #e0e0e0;
}
.gj-picker-bootstrap .selected {
  color: #888;
}
.gj-picker-bootstrap [role="header"] {
  background: #eee;
  color: #aaa;
}
/* @font-face {
  font-family: gijgo-material;
  src: url(../fonts/gijgo-material.eot?235541);
  src: url(../fonts/gijgo-material.eot?235541#iefix) format("embedded-opentype"),
    url(../fonts/gijgo-material.ttf?235541) format("truetype"),
    url(../fonts/gijgo-material.woff?235541) format("woff"),
    url(../fonts/gijgo-material.svg?235541#gijgo-material) format("svg");
  font-weight: 400;
  font-style: normal;
} */
.gj-icon {
  font-family: gijgo-material !important;
  font-size: 24px;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.gj-icon.undo:before {
  content: "\e900";
}
.gj-icon.vertical-align-top:before {
  content: "\e901";
}
.gj-icon.vertical-align-center:before {
  content: "\e902";
}
.gj-icon.vertical-align-bottom:before {
  content: "\e903";
}
.gj-icon.arrow-dropup:before {
  content: "\e904";
}
.gj-icon.clock:before {
  content: "\e905";
}
.gj-icon.refresh:before {
  content: "\e906";
}
.gj-icon.last-page:before {
  content: "\e907";
}
.gj-icon.first-page:before {
  content: "\e908";
}
.gj-icon.cancel:before {
  content: "\e909";
}
.gj-icon.clear:before {
  content: "\e90a";
}
.gj-icon.check-circle:before {
  content: "\e90b";
}
.gj-icon.delete:before {
  content: "\e90c";
}
.gj-icon.arrow-upward:before {
  content: "\e90d";
}
.gj-icon.arrow-forward:before {
  content: "\e90e";
}
.gj-icon.arrow-downward:before {
  content: "\e90f";
}
.gj-icon.arrow-back:before {
  content: "\e910";
}
.gj-icon.list-numbered:before {
  content: "\e911";
}
.gj-icon.list-bulleted:before {
  content: "\e912";
}
.gj-icon.indent-increase:before {
  content: "\e913";
}
.gj-icon.indent-decrease:before {
  content: "\e914";
}
.gj-icon.redo:before {
  content: "\e915";
}
.gj-icon.align-right:before {
  content: "\e916";
}
.gj-icon.align-left:before {
  content: "\e917";
}
.gj-icon.align-justify:before {
  content: "\e918";
}
.gj-icon.align-center:before {
  content: "\e919";
}
.gj-icon.strikethrough:before {
  content: "\e91a";
}
.gj-icon.italic:before {
  content: "\e91b";
}
.gj-icon.underlined:before {
  content: "\e91c";
}
.gj-icon.bold:before {
  content: "\e91d";
}
.gj-icon.arrow-dropdown:before {
  content: "\e91e";
}
.gj-icon.done:before {
  content: "\e91f";
}
.gj-icon.pencil:before {
  content: "\e920";
}
.gj-icon.minus:before {
  content: "\e921";
}
.gj-icon.plus:before {
  content: "\e922";
}
.gj-icon.chevron-up:before {
  content: "\e923";
}
.gj-icon.chevron-right:before {
  content: "\e924";
}
.gj-icon.chevron-down:before {
  content: "\e925";
}
.gj-icon.chevron-left:before {
  content: "\e926";
}
.gj-icon.event:before {
  content: "\e927";
}
.gj-draggable {
  cursor: move;
}
.gj-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1203;
}
.gj-dialog-bootstrap [data-role="title"],
.gj-dialog-bootstrap4 [data-role="title"] {
  display: inline;
}
.gj-dialog-bootstrap,
.gj-dialog-bootstrap4,
.gj-dialog-md {
  z-index: 1202;
  overflow: hidden;
}
.gj-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}
.gj-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}
.gj-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.gj-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}
.gj-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}
.gj-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}
.gj-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}
.gj-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}
.gj-dialog-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 0;
}
.gj-dialog-scrollable [data-role="body"] {
  overflow-x: hidden;
  overflow-y: scroll;
}
.gj-dialog-bootstrap [data-role="close"] {
  line-height: 1.42857143;
}
.gj-dialog-bootstrap4 [data-role="close"] {
  line-height: 1.5;
}
.gj-dialog-md {
  background-color: #fff;
  border: none;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
}
.gj-dialog-md-header {
  padding: 24px 24px 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
.gj-dialog-md-title {
  margin: 0;
  font-weight: 400;
  display: inline;
  line-height: 28px;
  font-size: 20px;
}
.gj-dialog-md-close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  float: right;
  line-height: 28px;
  font-size: 28px;
}
.gj-dialog-md-body {
  padding: 20px 24px 24px;
  color: rgba(0, 0, 0, 0.54);
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.gj-dialog-md-footer {
  padding: 8px 8px 8px 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.gj-dialog-md-footer > :first-child {
  margin-right: 0;
}
.gj-dialog-md-footer > * {
  margin-right: 8px;
  height: 36px;
}
div.gj-grid-wrapper {
  margin: auto;
  position: relative;
  clear: both;
  z-index: 1;
}
table.gj-grid {
  margin: auto;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
table.gj-grid thead th [data-role="selectAll"] {
  margin: auto;
}
table.gj-grid-bootstrap thead th [data-role="sorticon"],
table.gj-grid-md thead th [data-role="sorticon"] {
  margin-left: 5px;
}
table.gj-grid thead th [data-role="title"],
table.gj-grid thead th [data-role="sorticon"] {
  display: inline-block;
}
table.gj-grid thead th {
  overflow: hidden;
  text-overflow: ellipsis;
}
table.gj-grid.autogrow-header-row thead th {
  overflow: auto;
  text-overflow: initial;
  white-space: pre-wrap;
  -ms-word-break: break-word;
  word-break: break-word;
}
table.gj-grid > tbody > tr > td {
  overflow: hidden;
  position: relative;
}
table.gj-grid tbody div[data-role="display"] {
  vertical-align: middle;
  text-indent: 0;
  white-space: pre-wrap;
  -ms-word-break: break-word;
  word-break: break-word;
}
table.gj-grid.fixed-body-rows tbody div[data-role="display"] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -ms-word-break: initial;
  word-break: initial;
}
table.gj-grid tfoot div[data-role="display"] {
  vertical-align: middle;
  text-indent: 0;
  display: flex;
}
table.gj-grid .fa {
  padding: 2px;
}
table.gj-grid > tbody > tr > td > div {
  padding: 2px;
  overflow: hidden;
}
div.gj-grid-wrapper div.gj-grid-loading-cover {
  background: #bbb;
  opacity: 0.5;
  position: absolute;
  vertical-align: middle;
}
div.gj-grid-wrapper div.gj-grid-loading-text {
  position: absolute;
  font-weight: 700;
}
table.gj-grid-bootstrap thead th {
  background-color: #f5f5f5;
  vertical-align: middle;
}
table.gj-grid-bootstrap thead th [data-role="sorticon"] i.gj-icon,
table.gj-grid-bootstrap thead th [data-role="sorticon"] i.material-icons {
  position: absolute;
  font-size: 20px;
  top: 15px;
}
table.gj-grid-bootstrap tbody tr td div[data-role="display"] {
  padding: 0;
}
.gj-grid-bootstrap-4 .gj-checkbox-bootstrap {
  display: inline-block;
  padding-top: 2px;
}
.gj-grid-bootstrap-4 tbody tr.active {
  background-color: rgba(0, 0, 0, 0.075);
}
.gj-grid-md {
  position: relative;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background-color: #fff;
}
.gj-grid-md td:first-of-type,
.gj-grid-md th:first-of-type {
  padding-left: 24px;
}
.gj-grid-md td,
.gj-grid-md th {
  padding: 12px 18px;
  position: relative;
  box-sizing: border-box;
}
.gj-grid-md th {
  vertical-align: bottom;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0;
  height: 56px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  text-align: right;
}
.gj-grid-md td {
  height: 48px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
.gj-grid-bootstrap tbody tr.gj-grid-top-border td,
.gj-grid-md tbody tr.gj-grid-top-border td {
  border-top: 2px solid #777;
}
.gj-grid-bootstrap tbody tr.gj-grid-bottom-border td,
.gj-grid-md tbody tr.gj-grid-bottom-border td {
  border-bottom: 2px solid #777;
}
.gj-grid-md tbody tr {
  position: relative;
  height: 48px;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color;
}
.gj-grid-md tbody tr:hover {
  background-color: #eee;
}
.gj-grid-md tbody tr.gj-grid-md-select {
  background-color: #f5f5f5;
}
table.gj-grid-md thead th [data-role="sorticon"] i.gj-icon,
table.gj-grid-md thead th [data-role="sorticon"] i.material-icons {
  position: absolute;
  font-size: 16px;
  top: 19px;
}
table.gj-grid-md thead th.gj-grid-select-all {
  padding-bottom: 3px;
}
@media only all {
  td.display-1120,
  td.display-320,
  td.display-480,
  td.display-640,
  td.display-800,
  td.display-960,
  th.display-1120,
  th.display-320,
  th.display-480,
  th.display-640,
  th.display-800,
  th.display-960 {
    display: none;
  }
}
@media screen and (min-width: 20em) {
  table.gj-grid-bootstrap td.display-320,
  table.gj-grid-bootstrap th.display-320 {
    display: table-cell;
  }
}
@media screen and (min-width: 30em) {
  table.gj-grid-bootstrap td.display-480,
  table.gj-grid-bootstrap th.display-480 {
    display: table-cell;
  }
}
@media screen and (min-width: 40em) {
  table.gj-grid-bootstrap td.display-640,
  table.gj-grid-bootstrap th.display-640 {
    display: table-cell;
  }
}
@media screen and (min-width: 50em) {
  table.gj-grid-bootstrap td.display-800,
  table.gj-grid-bootstrap th.display-800 {
    display: table-cell;
  }
}
@media screen and (min-width: 60em) {
  table.gj-grid-bootstrap td.display-960,
  table.gj-grid-bootstrap th.display-960 {
    display: table-cell;
  }
}
@media screen and (min-width: 70em) {
  table.gj-grid-bootstrap td.display-1120,
  table.gj-grid-bootstrap th.display-1120 {
    display: table-cell;
  }
}
.gj-grid-md tfoot tr th {
  padding-right: 14px;
}
.gj-grid-md tfoot tr[data-role="pager"] .gj-grid-mdl-pager-label {
  padding-left: 5px;
  padding-right: 5px;
}
.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md {
  margin-left: 12px;
}
.gj-grid-md tfoot tr[data-role="pager"] .gj-dropdown-md [role="presenter"] {
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.54);
}
.gj-grid-md
  tfoot
  tr[data-role="pager"]
  .gj-dropdown-md
  [role="presenter"]
  [role="display"] {
  text-align: right;
}
.gj-grid-md tfoot tr[data-role="pager"] .gj-grid-md-limit-select {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.54);
}
.gj-grid-bootstrap tfoot tr[data-role="pager"] th {
  line-height: 30px;
  background-color: #f5f5f5;
}
.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > button,
.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > div {
  margin-right: 5px;
}
.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div button {
  height: 34px;
}
.gj-grid-bootstrap-4
  tfoot
  tr[data-role="pager"]
  th
  div
  .gj-dropdown-bootstrap-4
  .gj-dropdown-expander-mi
  .gj-icon {
  top: 5px;
}
.gj-grid-bootstrap-3 tfoot tr[data-role="pager"] th > div > input {
  margin-right: 5px;
  width: 40px;
  text-align: right;
  display: inline-block;
  font-weight: 700;
}
.gj-grid-bootstrap-4 tfoot tr[data-role="pager"] th > div > div.input-group {
  width: 40px;
}
.gj-grid-bootstrap-4
  tfoot
  tr[data-role="pager"]
  th
  > div
  > div.input-group
  input {
  text-align: right;
  font-weight: 700;
  height: 34px;
  padding-top: 2px;
  padding-bottom: 6px;
}
.gj-grid-bootstrap tfoot tr[data-role="pager"] th > div > select {
  display: inline-block;
  margin-right: 5px;
  width: 60px;
}
.gj-grid-bootstrap
  tfoot
  tr[data-role="pager"]
  th
  .gj-dropdown-bootstrap
  .gj-list-bootstrap
  [data-role="display"] {
  line-height: 14px;
}
.gj-grid-bootstrap
  tfoot
  tr[data-role="pager"]
  th
  .gj-dropdown-bootstrap
  [role="presenter"]
  [role="display"] {
  font-weight: 700;
}
.gj-grid-bootstrap
  tfoot
  tr[data-role="pager"]
  th
  .gj-dropdown-bootstrap-3
  [role="presenter"] {
  padding: 2px 8px;
}
.gj-grid-bootstrap
  tfoot
  tr[data-role="pager"]
  th
  .gj-dropdown-bootstrap-4
  [role="presenter"] {
  padding: 1px 8px;
}
.gj-grid thead tr th div.gj-grid-column-resizer-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  padding: 0;
}
span.gj-grid-column-resizer {
  position: absolute;
  right: 0;
  width: 10px;
  top: -100px;
  height: 300px;
  z-index: 1203;
  cursor: e-resize;
}
.gj-grid-resize-cursor {
  cursor: e-resize;
}
.gj-grid-md tbody tr td.gj-grid-left-border,
.gj-grid-md thead tr th.gj-grid-left-border {
  border-left: 3px solid #777;
}
.gj-grid-md tbody tr td.gj-grid-right-border,
.gj-grid-md thead tr th.gj-grid-right-border {
  border-right: 3px solid #777;
}
.gj-grid-bootstrap tbody tr td.gj-grid-left-border,
.gj-grid-bootstrap thead tr th.gj-grid-left-border {
  border-left: 5px solid #ddd;
}
.gj-grid-bootstrap tbody tr td.gj-grid-right-border,
.gj-grid-bootstrap thead tr th.gj-grid-right-border {
  border-right: 5px solid #ddd;
}
.gj-dirty {
  position: absolute;
  top: 0;
  left: 0;
  border-style: solid;
  border-width: 3px;
  border-color: red transparent transparent red;
  padding: 0;
  overflow: hidden;
  vertical-align: top;
}
.gj-grid-md tbody tr td.gj-grid-management-column {
  padding: 3px;
}
.gj-grid-md tbody tr td[data-mode="edit"] {
  padding: 0 18px;
}
.gj-grid-bootstrap tbody tr td[data-mode="edit"],
.gj-grid-bootstrap tbody tr td[data-mode="edit"] [data-role="edit"],
.gj-grid-md tbody .gj-dropdown-md [role="presenter"] [role="display"] {
  padding: 0;
}
.gj-grid-bootstrap-3 tbody tr td.gj-grid-management-column {
  padding: 3px;
}
.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] {
  height: 38px;
}
.gj-grid-bootstrap-3
  tbody
  tr
  td[data-mode="edit"]
  [data-role="edit"]
  input[type="text"] {
  height: 37px;
  padding: 8px;
}
.gj-grid-bootstrap-3
  tbody
  tr
  td[data-mode="edit"]
  .gj-dropdown-bootstrap
  [role="presenter"] {
  border: 0;
  border-radius: 0;
  height: 37px;
  padding-left: 8px;
}
.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap {
  height: 37px;
}
.gj-grid-bootstrap-3
  tbody
  tr
  td[data-mode="edit"]
  .gj-datepicker-bootstrap
  [role="input"] {
  height: 37px;
  border: 0;
  border-radius: 0;
}
.gj-grid-bootstrap-3
  tbody
  tr
  td[data-mode="edit"]
  .gj-datepicker-bootstrap
  [role="right-icon"] {
  border: 0;
  border-radius: 0;
}
.gj-grid-bootstrap-3 tbody tr td[data-mode="edit"] .gj-checkbox-bootstrap {
  display: inline-block;
  padding-top: 10px;
  height: 32px;
}
.gj-grid-bootstrap-4 tbody tr td.gj-grid-management-column {
  padding: 6px;
}
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  [data-role="edit"]
  input[type="text"] {
  height: 48px;
  padding-left: 12px;
}
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-dropdown-bootstrap
  [role="presenter"] {
  border: 0;
  border-radius: 0;
  height: 48px;
  padding-left: 12px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-dropdown-bootstrap-4
  [role="expander"].gj-dropdown-expander-mi
  .gj-icon,
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-dropdown-bootstrap-4
  [role="expander"].gj-dropdown-expander-mi
  .material-icons {
  top: 13px;
}
.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-datepicker-bootstrap {
  height: 48px;
}
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-datepicker-bootstrap
  [role="input"] {
  height: 48px;
  border: 0;
  border-radius: 0;
}
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-datepicker-bootstrap
  [role="right-icon"] {
  background-color: #fff;
}
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-datepicker-bootstrap
  [role="right-icon"]
  button {
  border: 0;
  border-radius: 0;
  width: 43px;
  position: relative;
}
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-datepicker-bootstrap
  [role="right-icon"]
  .gj-icon,
.gj-grid-bootstrap-4
  tbody
  tr
  td[data-mode="edit"]
  .gj-datepicker-bootstrap
  [role="right-icon"]
  .material-icons {
  top: 13px;
  left: 10px;
  font-size: 24px;
}
.gj-grid-bootstrap-4 tbody tr td[data-mode="edit"] .gj-checkbox-bootstrap {
  display: inline-block;
  padding-top: 15px;
  height: 42px;
}
.gj-grid-md thead tr[data-role="filter"] th {
  border-top: 1px solid #e0e0e0;
}
div.gj-grid-wrapper div.gj-grid-bootstrap-toolbar {
  background-color: #f5f5f5;
  padding: 8px;
  font-weight: 700;
  border: 1px solid #ddd;
}
div.gj-grid-wrapper div.gj-grid-bootstrap-4-toolbar {
  background-color: #f5f5f5;
  padding: 12px;
  font-weight: 700;
  border: 1px solid #ddd;
}
div.gj-grid-wrapper div.gj-grid-md-toolbar {
  font-weight: 700;
  font-size: 24px;
  font-family: Helvetica, Arial, sans-serif;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 0;
  border-collapse: collapse;
  padding: 0 18px;
  line-height: 56px;
}
.gj-checkbox-bootstrap,
.gj-checkbox-md {
  min-width: 0;
  font-weight: 400;
  text-align: center;
  position: relative;
}
table.gj-grid-scrollable tbody {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}
table.gj-grid-md.gj-grid-scrollable {
  border-bottom: 0;
}
table.gj-grid-md.gj-grid-scrollable tbody {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
table.gj-grid-md.gj-grid-scrollable tfoot {
  border-bottom: 1px solid #e0e0e0;
}
table.gj-grid-bootstrap.gj-grid-scrollable {
  border-bottom: 0;
}
table.gj-grid-bootstrap.gj-grid-scrollable tbody {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
table.gj-grid-bootstrap.gj-grid-scrollable
  tbody
  tr[data-role="row"]:first-child
  td {
  border-top: 0;
}
table.gj-grid-bootstrap.gj-grid-scrollable
  tbody
  tr[data-role="row"]
  td:first-child {
  border-left: 0;
}
table.gj-grid-bootstrap.gj-grid-scrollable
  tbody
  tr[data-role="row"]
  td:last-child {
  border-right: 0;
}
table.gj-grid-bootstrap.gj-grid-scrollable tfoot {
  border-bottom: 1px solid #ddd;
}
ul.gj-list li [data-role="spacer"] {
  display: table-cell;
}
ul.gj-list li [data-role="expander"] {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
[data-type="tree"] ul li [data-role="expander"].gj-tree-font-awesome-expander,
[data-type="tree"] ul li [data-role="expander"].gj-tree-glyphicons-expander,
[data-type="tree"]
  ul
  li
  [data-role="expander"].gj-tree-material-icons-expander {
  width: 24px;
}
[data-type="tree"]
  ul
  li
  [data-role="expander"].gj-tree-glyphicons-expander
  .glyphicon {
  top: 4px;
  height: 24px;
}
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li {
  border: 0;
  border-radius: 0;
  color: #333;
}
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.active {
  color: #fff;
}
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li.disabled {
  color: #777;
  background-color: #eee;
}
.gj-tree-bootstrap-4 ul.gj-list-bootstrap li {
  border: 0;
  border-radius: 0;
  color: #212529;
}
.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.active {
  color: #fff;
}
.gj-tree-bootstrap-4 ul.gj-list-bootstrap li.disabled {
  color: #868e96;
}
.gj-tree-bootstrap-4 ul.gj-list-bootstrap li ul.gj-list-bootstrap {
  width: 100%;
}
.gj-tree-bootstrap-border ul.gj-list-bootstrap li {
  border: 1px solid #ddd;
}
.gj-tree-bootstrap-border ul.gj-list-bootstrap li ul.gj-list-bootstrap li {
  border-left: 0;
  border-right: 0;
}
.gj-tree-bootstrap-border ul.gj-list-bootstrap li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.gj-tree-bootstrap-border ul.gj-list-bootstrap li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.gj-tree-bootstrap-border
  ul.gj-list-bootstrap
  li
  ul.gj-list-bootstrap
  li:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.gj-tree-bootstrap-border
  ul.gj-list-bootstrap
  li
  ul.gj-list-bootstrap
  li:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
ul.gj-list-bootstrap li [data-role="expander"].gj-tree-material-icons-expander {
  padding-top: 8px;
  padding-bottom: 4px;
}
.gj-tree-drag-el,
.gj-tree-drag-el li {
  padding: 0;
  margin: 0;
}
ul.gj-list-bootstrap
  li
  [data-role="expander"].gj-tree-material-icons-expander
  .gj-icon {
  width: 24px;
  height: 24px;
}
ul.gj-list-md li.disabled > [data-role="wrapper"] > [data-role="expander"] {
  color: #9e9e9e;
}
.gj-tree-md-border ul.gj-list-md li {
  border: 1px solid #616161;
  margin-bottom: -1px;
}
.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li {
  border-left: 0;
  border-right: 0;
}
.gj-tree-md-border ul.gj-list-md li ul.gj-list-md li:last-child {
  border-bottom: 0;
}
.gj-tree-drop-above {
  border-top: 1px solid #000;
}
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above,
.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-above {
  border-top: 2px solid #000;
}
.gj-tree-drop-below {
  border-bottom: 1px solid #000;
}
.gj-tree-bootstrap-3 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below,
.gj-tree-bootstrap-4 ul.gj-list-bootstrap li [data-role="wrapper"].drop-below {
  border-bottom: 2px solid #000;
}
.gj-tree-drag-el {
  z-index: 1203;
}
.gj-tree-drag-el [data-role="wrapper"] {
  cursor: move;
  display: table;
}
.gj-tree-drag-el [data-role="indicator"] {
  width: 14px;
  padding: 0 3px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.gj-tree-bootstrap-drag-el li.list-group-item {
  border: 0;
  background: unset;
}
.gj-tree-bootstrap-drag-el [data-role="indicator"],
.gj-tree-md-drag-el [data-role="indicator"] {
  width: 24px;
  height: 24px;
  padding: 0;
}
.gj-checkbox-bootstrap {
  font-size: 0;
  margin: 0;
  width: 18px;
  height: 18px;
  display: inline;
}
.gj-checkbox-bootstrap input[type="checkbox"] {
  display: none;
  margin-bottom: -12px;
}
.gj-checkbox-bootstrap span {
  background: #fff;
  display: block;
  content: " ";
  width: 18px;
  height: 18px;
  line-height: 11px;
  font-size: 11px;
  padding: 2px;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: box-shadow 0.2s linear, border-color 0.2s linear;
  cursor: pointer;
  margin: auto;
}
.gj-checkbox-bootstrap input[type="checkbox"]:focus + span:before {
  outline: 0;
  box-shadow: 0 0 0 0 #66afe9, 0 0 6px rgba(102, 175, 233, 0.6);
  border-color: #66afe9;
}
.gj-datepicker-bootstrap :active,
.gj-datepicker-bootstrap :focus,
.gj-timepicker-bootstrap :active,
.gj-timepicker-bootstrap :focus {
  box-shadow: none;
}
.gj-checkbox-bootstrap input[type="checkbox"][disabled] + span {
  opacity: 0.6;
  cursor: not-allowed;
}
.gj-checkbox-bootstrap.gj-checkbox-bootstrap-4 span {
  line-height: 16px;
  padding: 0;
}
.gj-checkbox-bootstrap-4.gj-checkbox-material-icons
  input[type="checkbox"]:checked
  + span:after,
.gj-checkbox-bootstrap-4.gj-checkbox-material-icons
  input[type="checkbox"]:indeterminate
  + span:after {
  font-size: 16px;
}
.gj-checkbox-md {
  font-size: 0;
  margin: 0;
  width: 16px;
  height: 16px;
}
.gj-checkbox-md input[type="checkbox"] {
  display: none;
  margin-bottom: -12px;
}
.gj-checkbox-md span {
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid #616161;
  border-radius: 2px;
  z-index: 2;
}
.gj-checkbox-md input[type="checkbox"]:checked + span {
  border: 2px solid #ff3b00;
}
.gj-checkbox-md input[type="checkbox"]:checked + span:after {
  color: #fff;
  background-color: #ff3b00;
  position: absolute;
  left: 1px;
  top: -15px;
}
.gj-checkbox-md input[type="checkbox"]:indeterminate + span {
  border: 2px solid #616161;
}
.gj-checkbox-md input[type="checkbox"]:indeterminate + span:after {
  color: #616161;
  position: absolute;
  left: 1px;
  top: -15px;
}
.gj-checkbox-md input[type="checkbox"][disabled] + span {
  border: 2px solid #9e9e9e;
}
.gj-editor-md,
.gj-editor-md [role="body"] {
  border: 1px solid rgba(158, 158, 158, 0.2);
}
.gj-checkbox-md input[type="checkbox"][disabled] + span:after {
  background-color: #9e9e9e;
}
.gj-checkbox-md input[type="checkbox"][disabled]:indeterminate + span:after {
  color: #fff;
}
.gj-checkbox-material-icons input[type="checkbox"]:checked + span:after {
  content: "\e91f";
  font-size: 14px;
  font-weight: 700;
  white-space: pre;
}
.gj-checkbox-material-icons input[type="checkbox"]:indeterminate + span:after {
  content: "\e921";
  font-size: 14px;
  font-weight: 700;
  white-space: pre;
}
.gj-checkbox-glyphicons input[type="checkbox"]:checked + span:after {
  display: inline-block;
  font-family: "Glyphicons Halflings";
  content: "\e013 ";
}
.gj-checkbox-glyphicons input[type="checkbox"]:indeterminate + span:after {
  display: inline-block;
  font-family: "Glyphicons Halflings";
  content: "\2212 ";
  padding-right: 1px;
}
.gj-checkbox-fontawesome .fa {
  font-size: 14px;
}
.gj-checkbox-bootstrap.gj-checkbox-fontawesome .fa {
  line-height: 18px;
}
.gj-checkbox-fontawesome input[type="checkbox"]:checked + span:before {
  content: "\f00c ";
}
.gj-checkbox-fontawesome input[type="checkbox"]:indeterminate + span:before {
  content: "\f068 ";
}
.gj-editor [role="body"] {
  overflow: auto;
  outline: transparent solid 0;
  box-sizing: border-box;
}
.gj-editor-md {
  padding: 7px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}
.gj-editor-md [role="toolbar"] {
  margin-bottom: 7px;
}
.gj-editor-md [role="toolbar"] .gj-button-md {
  min-width: 54px;
  margin-right: 5px;
}
.gj-editor-md [role="toolbar"] .gj-button-md .gj-icon {
  width: 24px;
  height: 24px;
}
.gj-editor-bootstrap,
.gj-editor-bootstrap [role="body"] {
  border: 1px solid #eceeef;
}
.gj-editor-md p {
  margin: 0;
  padding: 0;
}
.gj-editor-md blockquote {
  font-size: 14px;
}
.gj-editor-bootstrap {
  padding: 7px;
}
.gj-editor-bootstrap [role="toolbar"] {
  margin-bottom: 7px;
}
.gj-editor-bootstrap [role="toolbar"] .btn-group {
  margin-right: 10px;
}
.gj-editor-bootstrap [role="toolbar"] button {
  height: 36px;
}
.gj-editor-bootstrap p {
  margin: 0;
  padding: 0;
}
.gj-editor-bootstrap blockquote {
  font-size: 14px;
}
.gj-datepicker-md,
.gj-dropdown-md [role="presenter"] {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.04em;
}
.gj-dropdown {
  position: relative;
  border-collapse: separate;
}
.gj-dropdown [role="presenter"] {
  display: table;
  cursor: pointer;
  outline: 0;
  position: relative;
}
.gj-dropdown [role="presenter"] [role="display"] {
  display: table-cell;
  text-align: left;
  width: 100%;
}
.gj-dropdown [role="presenter"] [role="expander"] {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 24px;
  height: 24px;
}
.gj-dropdown-md [role="presenter"] {
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  padding: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background: 0 0;
}
.gj-dropdown-md [role="presenter"]:active,
.gj-dropdown-md [role="presenter"]:focus {
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}
.gj-dropdown-md [role="presenter"] [role="display"] {
  padding: 4px 0;
  line-height: 18px;
}
.gj-dropdown-md [role="presenter"] [role="display"] .placeholder {
  color: #7e7172;;
}
.gj-dropdown-list-md {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  color: #000;
  margin: 0;
  z-index: 1203;
}
.gj-dropdown-list-md li.active,
.gj-dropdown-list-md li:hover {
  background-color: #eee;
}
.gj-dropdown-bootstrap [role="presenter"] [role="display"] {
  padding-right: 5px;
}
.gj-dropdown-bootstrap [role="presenter"] [role="expander"] {
  padding-left: 5px;
}
.gj-dropdown-bootstrap
  [role="presenter"]
  [role="expander"].gj-dropdown-expander-mi {
  width: 24px;
}
.gj-dropdown-bootstrap-3 [role="presenter"] [role="display"] {
  line-height: 20px;
}
.gj-dropdown-bootstrap-3 [role="presenter"] [role="display"] .placeholder {
  color: #9999b3;
}
.gj-dropdown-bootstrap-3 [role="presenter"] [role="expander"] {
  width: 20px;
  height: 20px;
}
.gj-dropdown-bootstrap-3
  [role="presenter"]
  [role="expander"].gj-dropdown-expander-mi
  .gj-icon,
.gj-dropdown-bootstrap-3
  [role="presenter"]
  [role="expander"].gj-dropdown-expander-mi
  .material-icons {
  top: 5px;
  right: 10px;
  position: absolute;
}
.gj-dropdown-bootstrap-4 [role="presenter"] {
  border: 1px solid #ced4da;
}
.gj-dropdown-bootstrap-4 [role="presenter"] [role="display"] {
  line-height: 24px;
}
.gj-dropdown-bootstrap-4
  [role="presenter"]
  [role="expander"].gj-dropdown-expander-mi
  .gj-icon,
.gj-dropdown-bootstrap-4
  [role="presenter"]
  [role="expander"].gj-dropdown-expander-mi
  .material-icons {
  top: 7px;
  right: 10px;
  position: absolute;
}
.gj-dropdown-list-bootstrap {
  position: absolute;
  top: 32px;
  left: 0;
  margin: 0;
  z-index: 1203;
}
.gj-datepicker [role="input"]::-ms-clear {
  display: none;
}
.gj-datepicker [role="right-icon"] {
  cursor: pointer;
}
.gj-picker div[role="navigator"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gj-picker div[role="navigator"] div {
  cursor: pointer;
  position: relative;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.gj-datepicker-md {
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
}
.gj-datepicker-md [role="right-icon"] {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
}
.gj-datepicker-md.small .gj-textbox-md {
  font-size: 14px;
}
.gj-datepicker-md.small .gj-icon {
  font-size: 22px;
}
.gj-datepicker-md.large .gj-textbox-md {
  font-size: 18px;
}
.gj-datepicker-md.large .gj-icon {
  font-size: 28px;
}
.gj-picker-md.datepicker [role="header"] {
  padding: 20px;
  display: block;
}
.gj-picker-md.datepicker [role="header"] [role="year"] {
  font-size: 17px;
  padding-bottom: 5px;
  cursor: pointer;
}
.gj-picker-md.datepicker [role="header"] [role="date"] {
  font-size: 36px;
  cursor: pointer;
}
.gj-picker-md div[role="navigator"] {
  height: 42px;
  line-height: 42px;
  text-align: center;
}
.gj-picker div[role="navigator"] div[role="period"] {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
}
.gj-picker-md div[role="navigator"] div:first-child,
.gj-picker-md div[role="navigator"] div:last-child {
  max-width: 42px;
}
.gj-picker-md div[role="navigator"] div i.gj-icon,
.gj-picker-md div[role="navigator"] div i.material-icons {
  position: absolute;
  top: 8px;
}
.gj-picker-md div[role="navigator"] div:first-child i.gj-icon,
.gj-picker-md div[role="navigator"] div:first-child i.material-icons {
  left: 10px;
}
.gj-picker-md div[role="navigator"] div:last-child i.gj-icon,
.gj-picker-md div[role="navigator"] div:last-child i.material-icons {
  right: 11px;
}
.gj-picker-md table thead {
  color: #9e9e9e;
}
.gj-picker-md table tr td div,
.gj-picker-md table tr th div {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
}
[type="year"].gj-picker-md table tr td div,
[type="decade"].gj-picker-md table tr td div,
[type="century"].gj-picker-md table tr td div {
  width: 73px;
  height: 73px;
  line-height: 73px;
  cursor: pointer;
}
.gj-picker-md table tr td.gj-cursor-pointer div:hover {
  background: #eee;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
}
.gj-picker-md table tr td.disabled div,
.gj-picker-md table tr td.other-month div {
  color: #bdbdbd;
}
.gj-picker-md table tr td.focused div {
  background: #e0e0e0;
  border-radius: 50%;
}
.gj-picker-md table tr td.today div {
  color: #ff3b00;
}
.gj-picker-md table tr td.selected.gj-cursor-pointer div {
  color: #000 !important;
  background: transparent !important;
  border-radius: 50%;
}
.gj-picker-md table tr td.calendar-week div {
  font-weight: 700;
}
.gj-picker-bootstrap {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
}
.gj-modal .gj-picker-bootstrap {
  padding: 0;
}
.gj-picker-bootstrap.datepicker [role="header"] {
  padding: 10px 20px;
  display: block;
}
.gj-picker-bootstrap.datepicker [role="header"] [role="year"] {
  font-size: 15px;
  cursor: pointer;
}
.gj-picker-bootstrap [role="header"] [role="date"] {
  font-size: 24px;
  cursor: pointer;
}
.gj-modal .gj-picker-bootstrap.datepicker [role="body"] {
  padding: 15px;
}
.gj-picker-bootstrap div[role="navigator"] {
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.gj-picker-bootstrap div[role="navigator"] div:first-child,
.gj-picker-bootstrap div[role="navigator"] div:last-child {
  max-width: 30px;
}
.gj-picker-bootstrap table tr td div,
.gj-picker-bootstrap table tr th div {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
}
[type="year"].gj-picker-bootstrap table tr td div,
[type="decade"].gj-picker-bootstrap table tr td div,
[type="century"].gj-picker-bootstrap table tr td div {
  width: 53px;
  height: 53px;
  line-height: 53px;
  cursor: pointer;
}
.gj-picker-bootstrap table tr th div i,
.gj-picker-bootstrap table tr th div span {
  line-height: 30px;
}
.gj-picker-bootstrap div[role="navigator"] .gj-icon,
.gj-picker-bootstrap div[role="navigator"] .material-icons {
  margin: 3px;
}
.gj-picker-bootstrap table tr td.focused div,
.gj-picker-bootstrap table tr td.gj-cursor-pointer div:hover {
  background: #eee;
  border-radius: 4px;
  color: #212529;
}
.gj-picker-bootstrap table tr td.today div {
  color: #204d74;
  font-weight: 700;
}
.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
  border-radius: 4px;
}
.gj-picker-bootstrap table tr td.disabled div,
.gj-picker-bootstrap table tr td.other-month div {
  color: #777;
}
.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon {
  border-left: 0;
  position: relative;
  border-radius: 0 4px 4px 0;
}
.gj-datepicker-bootstrap span[role="right-icon"].input-group-addon .gj-icon,
.gj-datepicker-bootstrap
  span[role="right-icon"].input-group-addon
  .material-icons {
  position: absolute;
  top: 7px;
  left: 7px;
}
.gj-datepicker-bootstrap [role="right-icon"] button {
  width: 38px;
  position: relative;
  border: 1px solid #ced4da;
}
.gj-datepicker-bootstrap [role="right-icon"] button:hover {
  color: #6c757d;
  background-color: transparent;
}
.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button {
  width: 30px;
}
.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button {
  width: 48px;
}
.gj-datepicker-bootstrap [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap [role="right-icon"] button .material-icons {
  position: absolute;
  font-size: 21px;
  top: 9px;
  left: 9px;
}
.gj-datepicker-bootstrap.input-group-sm [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap.input-group-sm
  [role="right-icon"]
  button
  .material-icons {
  top: 6px;
  left: 6px;
  font-size: 19px;
}
.gj-datepicker-bootstrap.input-group-lg [role="right-icon"] button .gj-icon,
.gj-datepicker-bootstrap.input-group-lg
  [role="right-icon"]
  button
  .material-icons {
  font-size: 27px;
  top: 10px;
  left: 10px;
}
.gj-timepicker [role="input"]::-ms-clear {
  display: none;
}
.gj-timepicker [role="right-icon"] {
  cursor: pointer;
}
.gj-picker.timepicker [role="header"] {
  font-size: 58px;
  padding: 20px 0;
  line-height: 58px;
  display: flex;
  align-items: baseline;
  user-select: none;
  justify-content: center;
}
.gj-picker.timepicker [role="header"] div {
  cursor: pointer;
  width: 66px;
  text-align: right;
}
.gj-picker [role="header"] [role="mode"] {
  position: relative;
  width: 0;
}
.gj-picker [role="header"] [role="mode"] span {
  position: absolute;
  left: 7px;
  line-height: 18px;
  font-size: 18px;
}
.gj-picker [role="header"] [role="mode"] span[role="am"] {
  top: 7px;
}
.gj-picker [role="header"] [role="mode"] span[role="pm"] {
  bottom: 7px;
}
.gj-picker [role="body"] [role="dial"] {
  width: 256px;
  color: rgba(0, 0, 0, 0.87);
  height: 256px;
  position: relative;
  background: #eee;
  border-radius: 50%;
  margin: 10px;
}
.gj-picker-md .arrow-begin,
.gj-picker-md [role="body"] [role="arrow"] {
  background-color: #ff3b00;
}
.gj-picker [role="body"] [role="hour"] {
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  user-select: none;
  pointer-events: none;
}
.gj-colorpicker-md,
.gj-timepicker-md {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1;
}
.gj-picker [role="body"] [role="hour"].selected {
  color: rgba(255, 255, 255, 1);
}
.gj-picker [role="body"] [role="arrow"] {
  top: calc(50% - 1px);
  left: 50%;
  height: 2px;
  position: absolute;
  pointer-events: none;
  transform-origin: left center;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: calc(50% - 52px);
}
.gj-picker .arrow-begin {
  top: -3px;
  left: -4px;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
}
.gj-picker .arrow-end {
  top: -15px;
  right: -16px;
  width: 0;
  height: 0;
  position: absolute;
  box-sizing: content-box;
  border-width: 16px;
  border-radius: 50%;
}
.gj-timepicker-md {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
}
.gj-timepicker-md.small .gj-textbox-md {
  font-size: 14px;
}
.gj-timepicker-md.small .gj-icon {
  font-size: 22px;
}
.gj-timepicker-md.large .gj-textbox-md {
  font-size: 18px;
}
.gj-timepicker-md.large .gj-icon {
  font-size: 28px;
}
.gj-timepicker-md [role="right-icon"] {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
}
.gj-picker-md .arrow-end {
  border: 16px solid #ff3b00;
}
.gj-picker-bootstrap .arrow-begin,
.gj-picker-bootstrap [role="body"] [role="arrow"] {
  background-color: #888;
}
.gj-picker-bootstrap .arrow-end {
  border: 16px solid #888;
}
.gj-timepicker-bootstrap .input-group-addon {
  border-left: 0;
  position: relative;
  width: 38px;
  border-radius: 0 4px 4px 0;
}
.gj-timepicker-bootstrap.input-group-sm .input-group-addon {
  width: 30px;
}
.gj-timepicker-bootstrap.input-group-lg .input-group-addon {
  width: 46px;
}
.gj-timepicker-bootstrap .input-group-addon .gj-icon,
.gj-timepicker-bootstrap .input-group-addon .material-icons {
  position: absolute;
  font-size: 21px;
  top: 6px;
  left: 8px;
}
.gj-timepicker-bootstrap.input-group-sm .input-group-addon .gj-icon,
.gj-timepicker-bootstrap.input-group-sm .input-group-addon .material-icons {
  font-size: 19px;
  top: 5px;
  left: 5px;
}
.gj-timepicker-bootstrap.input-group-lg .input-group-addon .gj-icon,
.gj-timepicker-bootstrap.input-group-lg .input-group-addon .material-icons {
  font-size: 27px;
  top: 10px;
  left: 10px;
}
.gj-timepicker-bootstrap [role="right-icon"] button {
  width: 38px;
  position: relative;
}
.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button {
  width: 30px;
}
.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button {
  width: 48px;
}
.gj-timepicker-bootstrap [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap [role="right-icon"] button .material-icons {
  position: absolute;
  font-size: 21px;
  top: 7px;
  left: 9px;
}
.gj-timepicker-bootstrap.input-group-sm [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap.input-group-sm
  [role="right-icon"]
  button
  .material-icons {
  top: 4px;
  left: 6px;
  font-size: 19px;
}
.gj-timepicker-bootstrap.input-group-lg [role="right-icon"] button .gj-icon,
.gj-timepicker-bootstrap.input-group-lg
  [role="right-icon"]
  button
  .material-icons {
  font-size: 27px;
  top: 8px;
  left: 10px;
}
.gj-picker.datetimepicker [role="header"] [role="date"] {
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer;
}
.gj-picker [role="switch"] {
  align-items: baseline;
  user-select: none;
  position: relative;
}
.gj-picker [role="switch"] [role="calendarMode"] {
  position: absolute;
  bottom: 2px;
  left: 0;
  cursor: pointer;
}
.gj-picker [role="switch"] [role="time"] {
  width: 100%;
  text-align: center;
}
.gj-picker [role="switch"] [role="time"] div {
  display: inline;
  cursor: pointer;
}
.gj-picker [role="switch"] [role="clockMode"] {
  position: absolute;
  right: 0;
  bottom: 3px;
  cursor: pointer;
}
.gj-picker-md.datetimepicker [role="header"] {
  font-size: 36px;
  padding: 10px 20px;
  display: block;
}
.gj-picker-md [role="switch"] {
  color: rgba(255, 255, 255, 0.54);
  background: #ff3b00;
  font-size: 32px;
}
.gj-picker-bootstrap.datetimepicker [role="header"] {
  font-size: 36px;
  padding: 10px 20px;
  display: block;
}
.gj-picker-bootstrap.datetimepicker [role="header"] [role="time"] {
  font-size: 22px;
}
.gj-slider {
  position: relative;
  padding: 8px 6px;
}
.gj-slider [role="track"] {
  width: 100%;
}
.gj-slider [role="progress"] {
  position: absolute;
  z-index: 1203;
}
.gj-slider [role="handle"] {
  position: absolute;
}
.gj-slider-md [role="track"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #e9ecef;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.26);
}
.gj-slider-md [role="progress"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  height: 2px;
  background-color: #ff3b00;
  top: 8px;
  left: 6px;
}
.gj-slider-md [role="handle"] {
  top: 3px;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #ff3b00;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  filter: none;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  border: 0 solid transparent;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1204;
}
.gj-slider-bootstrap [role="track"] {
  border-radius: 4px;
  height: 10px;
}
.gj-slider-bootstrap [role="progress"] {
  height: 10px;
  border-radius: 4px;
  top: 8px;
  left: 6px;
  transition: none;
}
.gj-slider-bootstrap [role="handle"] {
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  filter: none;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  border: 0 solid transparent;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1204;
}
.gj-slider-bootstrap-3 [role="handle"] {
  background-color: #337ab7;
  background-image: -webkit-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
  background-repeat: repeat-x;
}
.gj-slider-bootstrap-4 [role="handle"] {
  background-color: #007bff;
  background-image: -webkit-linear-gradient(top, #007bff 0, #2e6da4 100%);
  background-image: -o-linear-gradient(top, #007bff 0, #2e6da4 100%);
  background-image: linear-gradient(to bottom, #007bff 0, #2e6da4 100%);
  background-repeat: repeat-x;
}
.gj-colorpicker [role="right-icon"] {
  cursor: pointer;
}
.gj-colorpicker-md {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
}
.gj-colorpicker-md [role="right-icon"] {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
}


.gj-picker-md table tr td.selected.gj-cursor-pointer div {
  color: #fff;
  background: #ff3b00;
  border-radius: 50%;
}