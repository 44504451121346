.dashboard_part{
    .notification_tab{
        padding: 27px 25px;
        background-color: $section_bg_1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        @include transform_time(.5s);
        p{
            font-size: 16px;
            color: $heading_color_1;
            a{
                color: $base_color;
            }
        }
    }
    .hide_icon{
        cursor: pointer;
        i{
            color: $base_color;
            font-size: 10px;
        }
    }
}
.white_box{
    padding: 30px;
    background-color: rgba(247, 250, 255, .70);
    @include border-radius(7px);
    &.box_border{
        border: 1px solid rgba(202, 206, 213 , .70);
    }
    @media #{$small_mobile} {
        padding: 30px 25px;
    }
    .white_box_tittle{
        h4{
           font-size: 16px;
           font-weight: 500; 
           margin-bottom: 0;
           @media #{$tab}{
            margin-bottom: 15px;
        }
        }
    }
}
.white_box2{
    padding: 40px 40px 25px 40px;
    background-color: $white;
    @include border-radius(5px);
    @media #{$small_mobile} {
        padding: 30px 25px;
    }
    .white_box_tittle{
        h4{
           font-size: 16px;
           font-weight: 500; 
           margin-bottom: 0;
           @media #{$tab}{
            margin-bottom: 15px;
        }
        }
    }
}
.chart_box{
    .white_box_tittle{
        margin-bottom: 20px;
        h4{
            margin-bottom: 2px;
            @media #{$tab}{
                margin-bottom: 15px;
            }
        }
    }
    p{
        margin-bottom: 29px;
        color: $text_color_3;
    }
}
.chart_box{
    //troggle box
    label{
        margin-bottom: 0;
    }
    .b {
        display: block !important;
    }
    .toggle {
        position: relative;
        width: 44px;
        height: 22px;
        border-radius: 50px;
        background-color: $text_color_2;
        border: 1px solid $text_color_2;
        overflow: hidden;
    }
    .check {
        position: absolute;
        display: block;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 6;
    }
    .check:checked ~ .switch {
        right: 5px;
        left: 57.5%;
        transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition-property: left, right;
        transition-delay: 0.08s, 0s;
    }
    .switch {
        position: absolute;
        left: 5px;
        top: 3px;
        bottom: 2px;
        right: 57.5%;
        background-color: $white;
        border-radius: 50%;
        z-index: 1;
        transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition-property: left, right;
        transition-delay: 0s, 0.08s;
        height: 13px;
        width: 13px;
        padding: 6px !important;
        margin-right: 0;
        &:after{
            display: none;
        }
    }
    .hide {
        display: none !important;
    }
}

//QA section content
.QA_section{

    .QA_table{
        .table{
            background: #FFFFFF;
            box-shadow: 0px 10px 15px rgba(235, 215, 241, 0.22);
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 35px;
            padding-top: 20px;
            padding-bottom: 40px;
        }
        th, td{
            font-size: 13px;
            font-weight: 400;
            line-height: 1.9;
            color: $heading_color_1;
            padding: 16px 30px;
            vertical-align: middle ;
        }
        .question_content{
            color: $text_color;
            font-size: 13px;
            &:hover{
                color: $base_color;
            }
        }
        thead{
           
            th{
                white-space: nowrap;
                border-bottom: 1px solid rgba(130, 139, 178,.3) !important;
            }
        }
        tbody{
            th{
                font-size: 14px;
                color: #415094;
                font-weight: 400 !important;
                // padding-left: 0;
            }
            th, td{
                color: $text_color;
                font-size: 14px;
                color: #828BB2;
                font-weight: 400;
                border-bottom: 1px solid rgba(130, 139, 178,.15);
                a{
                    font-size: 14px;
                    font-weight: 400;
                    color: #828BB2;
                    &:hover{
                        color:#7C32FF;
                    }
                }
                &.pending{
                    color: #E09079;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 500;

                }
                &.priority_hight{
                    font-size: 14px;
                    color: #D7598F;
                    font-weight: 400;
                }
            }
            th{
                // white-space: nowrap;
            }
            tr{
                @include transition(.3s);
                // &:first-child{
                //     th, td{
                //         border: 0px solid transparent;
                //     }
                // }
                &:hover{
                    background: #fff;
                }
            }
        }
        
        .view_btn{
            color: $base_color;
            &:hover{
                text-decoration: underline;
            }
        }
        .table {
            margin-bottom: 0 !important ;
            thead th{
                border-bottom: 0 solid transparent;
                background-color: $white;
                padding: 17px 30px;
                line-height: 16px;
                border: 0px solid transparent;
                font-size: 12px;
                font-weight: 400;
                color: $base_color;
                white-space: nowrap;
                text-transform: uppercase;
            }
        }
    }
}



.chart_box_1{
    .box_header{
        @media #{$large_mobile} {
            flex-direction: column;
        }
        .box_header_left{
            flex-basis: 50%;
            @media #{$tablet_device} {
                flex-basis: 55%;
            }
            @media #{$medium_device} {
                flex-basis: 55%;
            }
            @media #{$large_mobile}  {
                flex-basis: 100%;
                width: 100%;
                margin-bottom: 20px;
            }
            ul{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                li{
                    display: inline-block;
                    text-align: center;
                    @media #{$small_mobile} {
                        flex: 50% 0 0;
                        margin-bottom: 20px;
                    }
                    h4{
                        font-weight: 500;
                        font-size: 18px;
                        margin-bottom: 0px;
                    }
                    p{
                        font-weight: 500;
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .box_header_right{
            .legend_circle{
                ul{
                    li{
                        color: $text_color_5;
                        font-size: 12px;
                        font-weight: 400;
                        margin-right: 30px;
                        display: inline-block;
                        @media #{$small_mobile} {
                            margin-right: 14px;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        span{
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}