.email-sidebar{
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
    position: relative;
    z-index: 6;
    height: calc(100% - 3.9375rem);
    h4{
        font-weight: 600;
        font-size: .875rem;
        color: #333;
        padding: 2.5rem 0 3.125rem 1.5625rem;
    }
    ul{
        margin-bottom: 3.125rem;
        li{
            display: block;
            &.active{
                background: #3B76EF;
                box-shadow: 0 .625rem 1.875rem 0 rgba(59, 118, 239,.30);
                color: #fff !important;
                a{
                    color: #fff;
                }
            }
            a{
                display: block;
                padding: .625rem .625rem .625rem 1.5rem;
                transition: all .5s ease-in-out;
                font-weight: 600;
                font-size: .875rem;
                color: #333;
                &:hover{
                    background: #3B76EF;
                    box-shadow: 0 .625rem 1.875rem 0 rgba(59, 118, 239,.30);
                    color: #fff !important;
                }
                i{
                    margin-right: .7rem;
                }
            }
        }
    }
}


/* line 1, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    margin: 0;
    flex: 18px 0 0;
    line-height: 18px;
  }
  
  /* line 9, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
  .primary_checkbox .checkmark {
    position: relative;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    display: block;
    cursor: pointer;
    line-height: 18px;
    flex: 18px 0 0;
    border-radius: 50%;
  }
  
  /* line 26, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
  .primary_checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }
  
  /* line 35, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
  .primary_checkbox input:checked ~ .checkmark:after {
    width: 100%;
    height: 100%;
    border: 0;
    transition: .3s;
    transform: scale(0);
  }
  
  /* line 44, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
  .primary_checkbox input:checked ~ .checkmark {
    background: #3B76EF !important;
    box-shadow: 0px 5px 10px rgba(59, 118, 239,.30) !important;
    transition: .3s;
  }
  
  /* line 52, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
  .primary_checkbox input:checked ~ .checkmark::before {
    content: "\e64c";
    font-family: "themify";
    position: absolute;
    display: block;
    top: 0px;
    left: 3px;
    text-indent: 1px;
    color: #828BB2;
    background-color: transparent;
    border: 0px;
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    -o-transform: rotate(8deg);
    transform: rotate(8deg);
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
    z-index: 99;
    color: #fff;
    transition: .3s;
  }
  
  /* line 76, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
  .primary_checkbox .checkmark:after {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    content: "";
    width: 18px;
    height: 18px;
    background: transparent;
    border-radius: 50px;
    border: 1px solid #828BB2;
    transition: .3s;
    transform: scale(1);
  }
  