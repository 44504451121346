/************** footer css ****************/
.footer_part{
    text-align: center;
    // padding: 18px 0;
    padding-bottom: 30px;
    padding-top: 6px;
    background:  transparent; 
    // update 
    padding-left: 270px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    @media #{'only screen and (min-width: 320px) and (max-width: 991px)'} {
        // position: relative;
        padding-left: 0 !important;
    }
    @media #{'only screen and (min-width: 992px) and (max-width: 1200px)'} {
        // position: relative;
        padding-left: 20%;
    }
    p{
        font-size: 14px;
        font-weight: 300;
        color: $text_color_5;
        span{
            margin: 0 10px;
        }
        a{
            color: #3B76EF;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .footer_iner{
        padding: 0 15px;
        @media #{$small_mobile} {
            text-align: center;
        }
    }
}
.footer_links{
    padding: 0 15px;
 ul{
     @media #{$small_mobile} {
         justify-content: center !important;
         margin-top: 15px;
     }
    li{
        a{
            color: #3B76EF;
            font-size: 14px;
            font-weight: 400;
            margin-left: 30px;
            text-transform: capitalize;
        }
        &:first-child{
            a{
                margin-left: 0;
            }
        }
    }
 }
}
/************** footer css end ****************/