/************ sidebar css here ************/
.sidebar{
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
    padding-bottom: 50px;
    position: fixed;
    width: 270px;
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
    transition: (.5s);
    &.hide_vertical_menu{
        @media #{$medium_device} {
            left: -20%;
        }
        @media #{$min_1200px} {
            left: -270px;
        }
    }
    @media #{$tab}{
        left: -280px;
        @include transform_time(.5s);
        top: 0;
        width: 280px;
        background: $white ;
    }
    @media #{$medium_device}{
        width: 20%;
    }
    .logo{ 
        margin: 30px;
        background: #fff;
        padding: 33px 30px;
        border-radius: 7px;
        img{
           width: 100%;
        }
        @media #{$tab}{
            padding: 30px;
            
        }
    }
    
    .single_sidebar_tittle{
        i{
            font-size: 18px;
            margin-right: 12px;
            color: #f8dbdd;
            @include transform_time(.5s);
        }
        a{
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: $text_color_2;
            padding: 10px 25px 10px 40px;
            width: 100%;
            display: flex;
            align-items: center;
            transition: .3s;
            position: relative;
            z-index: 0;
            border-top: 1px solid transparent;
            background : transparent ;
            &::before{
                position: absolute;
                right: 0;
                background: $base_color;
                width: 0px;
                top: 0;
                bottom: 0;
                content: '';
                opacity: 0;
                transition: .3s;
                right: 0px;
                visibility: hidden;
            }
            &.current_active{
                i{
                    color: $base_color;
                }
                &::before{
                    opacity: 1;
                    width: 3px;
                    visibility: visible;
                }
            }
            &:hover::before{
                opacity: 1;
                right: 0;
                visibility: visible;
                width: 3px;
            }
            &.normal_title{
                padding: 28px 25px 18px 40px;
                background: #fff7f8;
                border-top: 1px solid #eee1e2 ;
                &:after{
                    position: absolute;
                    right: 40px;
                    top: 55%;
                    @include transform(translateY(-50%));
                    content: "\f0d8";
                    font-family: "Font Awesome 5 Free";
                    color: #cec1c2;
                    font-weight: 600;
                    font-size: 14px;
                }
                &.collapsed{
                    padding: 10px 25px 10px 40px;
                    background: #fff;
                    border-top: 1px solid transparent ;
                    &:after {
                        position: absolute;
                        right: 40px;
                        top: 50%;
                        transform: translateY(-50%);
                        content: "\f0d7";
                        font-family: "Font Awesome 5 Free";
                        color: #cec1c2;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
            }
        }
        &:hover{
            background-color: $heading_color_3;
            i{
                color: $base_color;
            }
        }
    }
    .single_menu_item {
        padding-left: 36px;
        margin: 6px 0;
        line-height: 21px;
        &:hover{
            padding-left: 55px !important;
        }
    }
    .sidebar_iner{
        ul{
            li{
                list-style: none;
                .submenu{
                    background: #fff7f8;
                    padding: 0px 0px 0px 70px !important;
                    border-bottom: 1px solid #eee1e2;
                    li{
                        a{
                            font-size: 13px;
                            line-height: 36px;
                            color: #7e7172;
                            font-weight: 400;
                        }
                        &:last-child {
                            padding-bottom: 18px;
                        }
                    }

                }
            }
        }
    }


}
@media #{$tab}{
    .active_sidebar{
        left: 0;
        box-shadow: 0 5px 20px rgba(0, 0, 0, .2);
    }
}
.sidebar_close_icon i {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    position: absolute;
    right: 30px;
    font-size: 25px;
    top: 29px;
    cursor: pointer;
    color: $text_color_2;
}

.sidebar_widget{
    .white_box{
        padding: 30px;
        @media #{$tab}{
            padding: 25px 15px;
        }
        @media #{$big_screen}{
            padding: 15px;
        }
        @media #{$extra_big_screen}{
            padding: 25px 15px;
        }
        .btn_2{
            margin-top: 25px;
            width: 100%;
            text-align: center;
            padding: 16px 25px;
            @media #{$medium_device}{
                padding: 12px 7px;
            }
        }
        .btn_1{
            @media #{$big_screen}{
                padding: 8px 10px;
                font-size: 11px;
            }
        }
    }
    .date_range{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 6px 0 18px;
        .btn_3{
            border-radius: 5px;
            font-size: 13px;
            font-weight: 300;
        }
        i{
            display: none;
        }
        .start_date, .end_date{
            width: 115px;
            input{
                width: 100%;
                border: 1px solid $border_color_3;
                color: $text_color;
                font-size: 13px;
                padding: 11px 20px;
                border-radius: 5px;
                font-weight: 300;
            }
            ::placeholder{
                color: $text_color;
            }
        }
    }
}
/************ sidebar css end ************/

// new sidebar 
.sidebar{
    #sidebar_menu{
        li{
            a{
                font-size: 15px;
                font-weight: 400;
                color: #676B84;
                padding: 15px 25px 15px 30px;
                background: #fff;
                transition: .3s;
                position: relative;
                z-index: 0;
                display: grid;
                grid-template-columns: 26px auto;
                grid-gap: 15px;
                align-items: center;
                position: relative;
                background: transparent;
                @media #{$medium_device} {
                    padding: 18px 25px 18px 30px;
                }
                // &::before{
                //     position: absolute;
                //     left: 0;
                //     background: $base_color;
                //     width: 0px;
                //     top: 0;
                //     bottom: 0;
                //     content: '';
                //     opacity: 0;
                //     transition: .3s;
                //     right: 0px;
                //     visibility: hidden;
                // }
                i{
                    font-size: 15px;
                    margin-right: 12px;
                    color: #A5ADC6;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                }
                &:hover{
                    // background: $base_color;
                    // color:$white;
                    i{
                        // color: $white;
                    }
                    &::before{
                        width: 6px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &.mm-active{
                // background: $base_color;
                >a{
                    color: #1F253A;
                }
                &.metis_dropmenu{
                    >a{
                        padding-top: 29px;
                        padding-bottom: 30px;
                        color: #A5ADC6;
                    }
                }
               >a{
                    i{
                        color: #A5ADC6;
                    }
                    &::before{
                        opacity: 1;
                        width: 6px;
                        visibility: visible;
                    }
                }
            }
            ul{
                padding: 0px 0px 0px 70px;
                @media #{$medium_device} {
                    padding: 0px 0px 0px 55px;
                }
                li{
                    // margin: 0;
                    &:last-child{
                        a{
                           padding-bottom: 30px;
                        }
                    }
                    a{
                        font-size: 13px;
                        color: #676B84;
                        font-weight: 400;
                        padding: 0;
                        white-space: nowrap;
                        padding: 0;
                        padding: 10px 0 !important;
                        &::before{
                            display: none;
                        }
                        &.active{
                            color: #1F253A;
                        }
                    }
                    ul{
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

// admin_profile_Wrap
.admin_profile_Wrap {
  // margin-top: 10px;
  margin-bottom: 30px;

  ul {
    li {
      background: #fff7f8;
      border-top: 1px solid #eee1e2;
      border-bottom: 1px solid #eee1e2;
      padding: 30px 0 30px 30px;

      @media #{$medium_device} {
        padding: 30px 0 30px 20px;
      }

      &.mm-active {
        // padding: 30px 0 27px 30px;
      }

      a {
        display: block;

        .admin_profile_inner {

          .thumb {
            img {
              width: 50px;
              height: 50px;
              @include border-radius(50%);
            }
          }

          .welcome_rext {
            margin-left: 15px;

            h5 {
              font-size: 14px;
              font-weight: 500;
              color: #222222;
              margin-bottom: 1px;
              white-space: nowrap;

              @media #{$medium_device} {
                font-size: 12px;
              }
            }

            span {
              display: block;
              font-size: 12px;
              color: #7e7172;
              font-weight: 300;
              white-space: nowrap;

              @media #{$medium_device} {
                font-size: 11px;
              }
            }
          }
        }
      }

      ul {
        padding-left: 70px;
        // padding-top: 30px;
        li {
          padding: 0;
          border: 0;

          a {
            font-size: 13px;
            color: #7e7172;
            font-weight: 400;
            padding-bottom: 17px;
          }

          &:last-child {
            a {
              padding-bottom: 0;
            }
          }

          &:first-child {
            a {
              margin-top: 29px;
            }
          }
        }
      }
    }
  }
      .metismenu .has-arrow::after{
        top: 38%;
    }

}


.sidebar,body{
    &::-webkit-scrollbar-track
    {
        background-color: #ddd;
    }
    
    &::-webkit-scrollbar
    {
        width: 6px;
        background-color: #ddd;
    }
    &::-webkit-scrollbar-thumb
    {
        background-color: #f8dbdd;
    }
}
body{
    &::-webkit-scrollbar
    {
        width: 8px;
    }
}


.metismenu .has-arrow::after {
    @media #{$medium_device} {
        right: 25px;
    }
}
