.body_content{
    display: flex;
}

.main-title{
    h3{
   font-family: $font_style_1;
    font-weight: 700;
    font-size: 20px;
    color: #4D4F5C;
    line-height: 1.2;
    @media #{'only screen and (min-width: 1200px) and (max-width: 1480px)'} {
        font-size: 13px;
    }
    }
    span{
        font-size: 12px;
        font-weight: 400;
        color: #A3A0FB;
        display: block;
        margin-top: 6px;
    }
}
.box_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
    &.box_header_block{
        @media #{$small_mobile} {
            flex-direction: column;
            align-items: flex-start !important;
        }
       .main-title{
        @media #{$small_mobile} {
            margin-bottom: 15px;
        }
       }
    }
    .title_info{
        p{
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 0;
            color: rgba(163, 160, 251, 100);
        }
        
    }
}
.legend_style{
    li{
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        text-transform: capitalize;
        margin-right: 10px;
        span{
            display: inline-block;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #4400eb;
            margin-right: 4px;
        }
        &.inactive{
            opacity: .29;
        }
    }
    &.legend_style_grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
        li{
            margin: 0 !important;
        }
    }
}
.title_btn{
    margin-bottom: 20px;
    ul{
        li{
            display: inline-block;
            a{
                display: inline-block;
                padding: 4px;
                background: #fff;
                font-size: 11px;
                text-transform: capitalize;
                color: #706F9A;
                border-radius: 5px;
                line-height: 25px;
                padding: 0 10px;
                transition: .3s;
                &:hover{
                background: #A66DD4;
                color: #fff;
                }
                &.active{
                background: #A66DD4;
                color: #fff;
                }
            }
        }
    }
}
.radial_footer{
    .radial_footer_inner{
        .left_footer{
            padding-left: 10px;
            margin-bottom: 20px;
            h5{
                font-size: 13px;
                color: #43425D;
                font-weight: 500;
                margin-bottom: 0px;
                position: relative;
                span{
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background: #A4A1FB;
                    display: inline-block;
                    position: absolute;
                    left: -15px;
                    top: 6px;
                }
            }
            p{
                font-size: 12px;
                color: #4D4F5C;
                opacity: .5;
                font-weight: 400;
                margin-bottom: 0;
            }
        }
    }
    .radial_bottom{
        border-top: 1px solid #EFEFEF;
        text-align: center;
        padding-top: 15px;
        margin-top: 4px;
        p{
            a{
                font-size: 12px;
                color:#3B86FF;
                font-weight: 600;
            }
        }
    }
}