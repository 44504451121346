/******** base color css ********/
.btn_1{
    background-color: #3B76EF;;
    border: 1px solid #3B76EF;;
    color: $white;
    display: inline-block;
    padding: 11px 23px;
    text-transform: capitalize;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    white-space: nowrap;
    @include transform_time(.5s);
    i{
        font-size: 15px;
        padding-right: 7px;
    }
    &:hover{
        background-color: $white;
        border: 1px solid #3B76EF;
        color: #3B76EF;
    }
    &.light{
        background-color: rgba($color: $base_color, $alpha: .1);
        border: 1px solid transparent;
        color: $base_color;
    }
    &.green{
        background-color: rgba($color: #2EC9B8, $alpha: .1);
        border: 1px solid transparent;
        color: #2EC9B8;
    }
    &.sm{
        font-size: 12px;
        padding: 6px 15px;
    }
    &.big_btn{
        padding: 11px 36px;
    }
    &.form_big_btn_1{
        padding: 16px 62px !important;
        @media #{$small_mobile} {
            padding: 16px 30px !important;
        }
    }
    &.big_btn2{
        padding: 11px 37px;
    }
}
.btn_2{
    color: $base_color;
    border: 1px solid $base_color;
    display: inline-block;
    padding: 11px 23px;
    text-transform: capitalize;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    background-color: $white;
    white-space: nowrap;
    @include transform_time(.5s);
    &:hover{
        background-color: $base_color;
        border: 1px solid $base_color;
        color: $white;
    }
}
.btn_3{
    color: $heading_color_1;
    border: 1px solid $border_color_3;
    display: inline-block;
    padding: 11px 27px;
    text-transform: capitalize;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    background-color: $white;
    white-space: nowrap;
    @include transform_time(.5s);
    &.border_color_1{
        border: 1px solid #eee1e2;
        
    }
    &:hover{
        background-color: $base_color;
        border: 1px solid $base_color;
        color: $white;
    }
}
.btn_4{
    display: inline-block;
    border: 1px solid $border_color_3;
    border-radius: 5px;
    color: $text_color;
    text-align: center;
    padding: 9px 15px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 300;
    @include transform_time(.5s);
    &:hover{
        background-color: $base_color;
        border: 1px solid $base_color;
        color: $white;
    }
}

.btn_5{
    border: 1px solid #fff;
    display: inline-block;
    padding: 16px 26px !important;
    text-transform: capitalize;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    background-color: $white;
    white-space: nowrap;
    @include transform_time(.5s);
    background: #fff !important;
    text-shadow: none !important;
    opacity: 1;
    color: #222222;
    &:hover{
        background-color: $base_color !important;
        border: 1px solid $base_color !important;
        color: $white;
        opacity: 1 !important;
    }
    &.small_btn{
        padding: 11px 26px !important;
        @media #{$small_mobile} {
            padding: 11px 19px !important;
        }
        @media #{$large_mobile} {
            padding: 11px 19px !important;
        }
    }
}
.btn_6{
    color: $heading_color_1;
    border: 1px solid $border_color_4;
    display: inline-block;
    padding: 11px 25px;
    text-transform: capitalize;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    background-color: $white;
    white-space: nowrap;
    @include transform_time(.5s);
    &:hover{
        background-color: $base_color;
        border: 1px solid $base_color;
        color: $white;
    }
}
.sm_btn {
    line-height: 15px;
    background-color: #fff;
    height: 34px;
    padding: 9px 20px;
    color: #212e40;
    width: 150px;
    text-transform: capitalize;
    border: 1px solid $border_color_4;
}
.view_archive_btn{
    border: 1px solid $border_color_3;
    color: $text_color;
    padding: 16px 30px;
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 16px;
    background-color: $white;
    @include transform_time(.5s);
    i{
        margin-right: 15px;
    }
    &:hover{
        color: $base_color;
        border: 1px solid $base_color;
    }
}

.white_btn{
    display: inline-block;
    color: #7e7172;
    font-size: 12px;
    font-weight: 400;
    background: #fff;
    transition: .3s;
    padding: 6px 20px;
    border-radius: 3px;
    &:hover{
        background: #3B76EF;
        color: #fff;
    }
}

.red_btn{
    display: inline-block;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    background: #3B76EF;
    transition: .3s;
    padding: 6px 20px;
    border-radius: 3px;
    &:hover{
        background: #fff;
        color: #7e7172;
    }
}
.close.white_btn2{
    border: 1px solid #fff;
    display: inline-block;
    padding: 16px 26px !important;
    text-transform: capitalize;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    background-color: $white;
    white-space: nowrap;
    @include transform_time(.5s);
    background: #fff !important;
    text-shadow: none !important;
    opacity: 1;
    color: #222222;
    margin-right: 10px;
    &:hover{
        background-color: $base_color !important;
        border: 1px solid $base_color !important;
        color: $white;
        opacity: 1 !important;
    }
}
.white_btn3{
    border: 1px solid #fff;
    display: inline-block;
    padding: 11px 30px !important;
    text-transform: capitalize;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    background-color: $white;
    white-space: nowrap;
    @include transform_time(.5s);
    background: #fff !important;
    text-shadow: none !important;
    opacity: 1;
    color: #222222;
    margin-right: 10px;
    &:hover{
        background-color: $base_color !important;
        border: 1px solid $base_color !important;
        color: $white;
        opacity: 1 !important;
    }
}

.status_btn{
    display: inline-block;
    padding: 2px 15px;
    font-size: 11px;
    font-weight: 300;
    color: #fff !important;
    background: #05d34e;
    @include border-radius(30px);
    text-transform: capitalize;
    white-space: nowrap;
    min-width: 70px;
    text-align: center;
    &.yellow_btn{
        background: #ffba00;
    }
    &:hover{
        color: #fff;
    }
}
.dropdown{
    .dropdown-toggle.lms_drop_1 {
        background: #fff;
        padding: 4px 15px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 300;
        text-transform: capitalize;
        text-align: left;
        color: #7e7172;
        display: inline-block;
        width: 102px;
        border: 0;
        border: 1px solid #eee1e2;
        position: relative;
        transition: .3s;
        &::after{
            content: "\f0d7";
            font-family: "Font Awesome 5 Free";
            position: absolute;
            right: 0;
            top: 12px;
            color: #cec1c2;
            font-size: 12px;
            font-weight: 900;
            margin: 0;
            padding: 0;
            border: 0;
            border: 0;
            top: 50%;
            @include transform(translateY(-50%));
            right: 14px;
        }
        &.lms_drop_2{
            min-width: 140px;
        }
    }
    &.show{
        .dropdown-toggle.lms_drop_1 {
            background: #3B76EF;
            padding: 4px 15px;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 300;
            text-transform: capitalize;
            text-align: left;
            color: #7e7172;
            display: inline-block;
            width: 102px;
            border: 0;
            color: #fff;
            border: 1px solid #3B76EF;
            &::after{
                content: "\f0d8";
            }
        }
    }
    .dropdown-menu{
        border: 0;
        border: 1px solid #eee1e2;
        padding: 12px 0 20px 0;
        margin: 0;
        border-radius: 0 !important;
        .dropdown-item{
            font-size: 12px;
            font-weight: 300;
            padding: 4px 20px;
            color: #7f7778;
            &:hover{
                color: #3B76EF;
                background: transparent ;
            }
        }
        &.dropdown-menu-w140{
            min-width: 140px;
        }
    }
}
.white_btn_line {
    white-space: nowrap;
    height: 50px;
    background: transparent;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    border: 0;
    padding: 0 19px;
    border-radius: 3px;
    border: 1px solid #eee1e2;
    color: #7e7172;
    transition: .3s;
    i{
        font-size: 13px;
        color: #7e7172;
        margin-right: 6px;
    }
    &:hover{
        background: #3B76EF;
        color: #fff;
        border: 1px solid #3B76EF;
        i{
            color: #fff;
            transition: .3s;
        }
    }
}
.white_btn_line2 {
    white-space: nowrap;
    height: 30px;
    background: transparent;
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
    text-transform: capitalize;
    border: 0;
    border: 1px solid #eee1e2;
    color: #7e7172;
    transition: .3s;
    border-radius: 30px;
    line-height: 28px;
    padding: 0 18px;
    &:hover{
        background: #3B76EF;
        color: #fff !important;
        border: 1px solid #3B76EF;
        i{
            color: #fff;
            transition: .3s;
        }
    }
}


table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before, 
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before {
    // background-color:#222;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    content: "\e61a";
    font-family: 'themify';
    font-size: 8px;
    line-height: 14px;
    font-weight: 600;
    background-color: #fef1f2;
    box-shadow: 6px 6px 12px rgba(0,0,0,.08),
         -6px -6px 12px rgba(255,255,255,1);

}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before, 
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {

    content: "\e622";
    // background: #3B76EF;
    box-shadow: inset 6px 6px 12px rgba(0,0,0,.08),
    inset -6px -6px 12px rgba(255,255,255,1);

}



// CRM_dropdown 
.CRM_dropdown.dropdown{
    .dropdown-toggle{
        background: transparent;
        color: #415094;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid #7c32ff;
        border-radius: 32px;
        padding: 5px 20px;
        text-transform: uppercase;
        overflow: hidden;
        transition: .3s;
        &:after{
            content: "\e62a";
            font-family: "themify";
            border: none;
            border-top-color: currentcolor;
            border-top-style: none;
            border-top-width: medium;
            border-top: 0px;
            font-size: 10px;
            position: relative;
            top: 3px;
            left: 0;
            font-weight: 600;
            transition: .3s;
        }
        &:hover , &:focus{
            color: #fff;
            @extend %gradient_1;
            border: 1px solid transparent ;
            box-shadow: none;
        }
    }
    &.show{
        .dropdown-toggle{

            &::after{
                
            }
        }
    }
    .dropdown-menu{
        border-radius: 5px 5px 10px 10px;
        border: 0px;
        padding: 15px 0px;
        box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3);
        .dropdown-item{
            color: #828bb2;
            text-align: right;
            font-size: 12px;
            padding: 4px 1.5rem;
            text-transform: uppercase;
            cursor: pointer;
            &:hover{
                color: $base_color;
            }
        }
    }
}

.dropdown-menu.option_width_8 {
    min-width: 150px;
  }