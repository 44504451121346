@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/summernote.eot");
  src: url("fonts/summernote.eot")
      format("embedded-opentype"),
    url("fonts/summernote.woff")
      format("woff"),
    url("fonts/summernote.ttf")
      format("truetype");
}
[class^="note-icon-"]:before,
[class*=" note-icon-"]:before {
  display: inline-block;
  font: normal normal normal 14px summernote;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  speak: none;
  -moz-osx-font-smoothing: grayscale;
}
.note-icon-align-center:before,
.note-icon-align-indent:before,
.note-icon-align-justify:before,
.note-icon-align-left:before,
.note-icon-align-outdent:before,
.note-icon-align-right:before,
.note-icon-align:before,
.note-icon-arrow-circle-down:before,
.note-icon-arrow-circle-left:before,
.note-icon-arrow-circle-right:before,
.note-icon-arrow-circle-up:before,
.note-icon-arrows-alt:before,
.note-icon-arrows-h:before,
.note-icon-arrows-v:before,
.note-icon-bold:before,
.note-icon-caret:before,
.note-icon-chain-broken:before,
.note-icon-circle:before,
.note-icon-close:before,
.note-icon-code:before,
.note-icon-col-after:before,
.note-icon-col-before:before,
.note-icon-col-remove:before,
.note-icon-eraser:before,
.note-icon-float-left:before,
.note-icon-float-none:before,
.note-icon-float-right:before,
.note-icon-font:before,
.note-icon-frame:before,
.note-icon-italic:before,
.note-icon-link:before,
.note-icon-magic:before,
.note-icon-menu-check:before,
.note-icon-minus:before,
.note-icon-orderedlist:before,
.note-icon-pencil:before,
.note-icon-picture:before,
.note-icon-question:before,
.note-icon-redo:before,
.note-icon-rollback:before,
.note-icon-row-above:before,
.note-icon-row-below:before,
.note-icon-row-remove:before,
.note-icon-special-character:before,
.note-icon-square:before,
.note-icon-strikethrough:before,
.note-icon-subscript:before,
.note-icon-summernote:before,
.note-icon-superscript:before,
.note-icon-table:before,
.note-icon-text-height:before,
.note-icon-trash:before,
.note-icon-underline:before,
.note-icon-undo:before,
.note-icon-unorderedlist:before,
.note-icon-video:before {
  display: inline-block;
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
.note-icon-align-center:before {
  content: "\f101";
}
.note-icon-align-indent:before {
  content: "\f102";
}
.note-icon-align-justify:before {
  content: "\f103";
}
.note-icon-align-left:before {
  content: "\f104";
}
.note-icon-align-outdent:before {
  content: "\f105";
}
.note-icon-align-right:before {
  content: "\f106";
}
.note-icon-align:before {
  content: "\f107";
}
.note-icon-arrow-circle-down:before {
  content: "\f108";
}
.note-icon-arrow-circle-left:before {
  content: "\f109";
}
.note-icon-arrow-circle-right:before {
  content: "\f10a";
}
.note-icon-arrow-circle-up:before {
  content: "\f10b";
}
.note-icon-arrows-alt:before {
  content: "\f10c";
}
.note-icon-arrows-h:before {
  content: "\f10d";
}
.note-icon-arrows-v:before {
  content: "\f10e";
}
.note-icon-bold:before {
  content: "\f10f";
}
.note-icon-caret:before {
  content: "\f110";
}
.note-icon-chain-broken:before {
  content: "\f111";
}
.note-icon-circle:before {
  content: "\f112";
}
.note-icon-close:before {
  content: "\f113";
}
.note-icon-code:before {
  content: "\f114";
}
.note-icon-col-after:before {
  content: "\f115";
}
.note-icon-col-before:before {
  content: "\f116";
}
.note-icon-col-remove:before {
  content: "\f117";
}
.note-icon-eraser:before {
  content: "\f118";
}
.note-icon-float-left:before {
  content: "\f119";
}
.note-icon-float-none:before {
  content: "\f11a";
}
.note-icon-float-right:before {
  content: "\f11b";
}
.note-icon-font:before {
  content: "\f11c";
}
.note-icon-frame:before {
  content: "\f11d";
}
.note-icon-italic:before {
  content: "\f11e";
}
.note-icon-link:before {
  content: "\f11f";
}
.note-icon-magic:before {
  content: "\f120";
}
.note-icon-menu-check:before {
  content: "\f121";
}
.note-icon-minus:before {
  content: "\f122";
}
.note-icon-orderedlist:before {
  content: "\f123";
}
.note-icon-pencil:before {
  content: "\f124";
}
.note-icon-picture:before {
  content: "\f125";
}
.note-icon-question:before {
  content: "\f126";
}
.note-icon-redo:before {
  content: "\f127";
}
.note-icon-rollback:before {
  content: "\f128";
}
.note-icon-row-above:before {
  content: "\f129";
}
.note-icon-row-below:before {
  content: "\f12a";
}
.note-icon-row-remove:before {
  content: "\f12b";
}
.note-icon-special-character:before {
  content: "\f12c";
}
.note-icon-square:before {
  content: "\f12d";
}
.note-icon-strikethrough:before {
  content: "\f12e";
}
.note-icon-subscript:before {
  content: "\f12f";
}
.note-icon-summernote:before {
  content: "\f130";
}
.note-icon-superscript:before {
  content: "\f131";
}
.note-icon-table:before {
  content: "\f132";
}
.note-icon-text-height:before {
  content: "\f133";
}
.note-icon-trash:before {
  content: "\f134";
}
.note-icon-underline:before {
  content: "\f135";
}
.note-icon-undo:before {
  content: "\f136";
}
.note-icon-unorderedlist:before {
  content: "\f137";
}
.note-icon-video:before {
  content: "\f138";
}
.note-frame * {
  color: #79838b;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.note-toolbar {
  padding: 10px 5px;
  border-bottom: 1px solid transparent;
}
.note-btn-group {
  position: relative;
  display: inline-block;
  margin-right: 8px;
}
.note-btn-group > .note-btn-group {
  margin-right: 0;
}
.note-btn-group > .note-btn,
.note-btn-group > .note-btn-group {
  margin-left: -4px;
  border-radius: 0;
}
.note-btn-group > .note-btn.focus,
.note-btn-group > .note-btn-group.focus,
.note-btn-group > .note-btn.active,
.note-btn-group > .note-btn-group.active {
  border-radius: 0;
}
.note-btn-group > .note-btn:first-child,
.note-btn-group > .note-btn-group:first-child {
  margin-left: 0;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
}
.note-btn-group > .note-btn:first-child.focus,
.note-btn-group > .note-btn-group:first-child.focus,
.note-btn-group > .note-btn:first-child.active,
.note-btn-group > .note-btn-group:first-child.active {
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
}
.note-btn-group > .note-btn:last-child:not(.note-dropdown),
.note-btn-group > .note-btn-group:last-child:not(.note-dropdown) {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}
.note-btn-group > .note-btn:last-child:not(.note-dropdown).focus,
.note-btn-group > .note-btn-group:last-child:not(.note-dropdown).focus,
.note-btn-group > .note-btn:last-child:not(.note-dropdown).active,
.note-btn-group > .note-btn-group:last-child:not(.note-dropdown).active {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}
.note-btn-group.open > .note-dropdown {
  display: block;
}
.note-btn {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #333;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-color: #fff;
  background-image: none;
  border: 1px solid #fff;
  border-color: #fff;
  border-radius: 1px;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}
.note-btn:focus,
.note-btn.focus {
  color: #333;
  background-color: #ebebeb;
  border-color: #fff;
}
.note-btn:hover {
  color: #333;
  background-color: #ebebeb;
  border-color: #fff;
}
.note-btn.disabled:focus,
.note-btn[disabled]:focus,
fieldset[disabled] .note-btn:focus,
.note-btn.disabled.focus,
.note-btn[disabled].focus,
fieldset[disabled] .note-btn.focus {
  background-color: #fff;
  border-color: #fff;
}
.note-btn:hover,
.note-btn:focus,
.note-btn.focus {
  color: #333;
  text-decoration: none;
  background-color: #ebebeb;
  border: 1px solid #fff;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  outline: 0;
}
.note-btn:active,
.note-btn.active {
  background-image: none;
  outline: 0;
}
.note-btn.disabled,
.note-btn[disabled],
fieldset[disabled] .note-btn {
  cursor: not-allowed;
  -webkit-opacity: 0.65;
  -khtml-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  -ms-filter: alpha(opacity=65);
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.note-btn-primary {
  color: #fff;
  background: #fa6362;
}
.note-btn-primary:hover,
.note-btn-primary:focus,
.note-btn-primary.focus {
  color: #fff;
  text-decoration: none;
  background-color: #fa6362;
  border: 1px solid #fff;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.note-btn-block {
  display: block;
  width: 100%;
}
.note-btn-block + .note-btn-block {
  margin-top: 5px;
}
input[type="submit"].note-btn-block,
input[type="reset"].note-btn-block,
input[type="button"].note-btn-block {
  width: 100%;
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.close {
  float: right;
  font-size: 21px;
  line-height: 1;
  color: #000;
  opacity: 0.2;
}
.close:hover {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
}
.note-dropdown {
  position: relative;
}
.note-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100px;
  padding: 5px;
  text-align: left;
  background: #fff;
  border: 1px solid #e2e2e2;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  background-clip: padding-box;
}
.note-btn-group.open .note-dropdown-menu {
  display: block;
}
.note-dropdown-item {
  display: block;
}
.note-dropdown-item:hover {
  background-color: #ebebeb;
}
a.note-dropdown-item,
a.note-dropdown-item:hover {
  margin: 2px 0;
  color: #000;
  text-decoration: none;
}
.note-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
}
.note-modal.open {
  display: block;
}
.note-modal-content {
  position: relative;
  width: auto;
  margin: 30px 20px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  background-clip: border-box;
}
.note-modal-header {
  padding: 30px 20px 20px 20px;
  border: 1px solid #ededef;
}
.note-modal-header .close {
  margin-top: -10px;
}
.note-modal-body {
  position: relative;
  padding: 20px 30px;
}
.note-modal-body kbd {
  padding: 3px 5px;
  font-weight: 700;
  color: #fff;
  background-color: #000;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.note-modal-footer {
  height: 40px;
  padding: 10px 10px 35px;
  text-align: center;
}
.note-modal-footer a {
  color: #337ab7;
  text-decoration: none;
}
.note-modal-footer a:hover,
.note-modal-footer a:focus {
  color: #23527c;
  text-decoration: underline;
}
.note-modal-title {
  margin: 0;
  font-size: 26px;
  line-height: 1.4;
  color: #42515f;
}
.note-modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: none;
  background: #000;
  -webkit-opacity: 0.5;
  -khtml-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: alpha(opacity=50);
  filter: alpha(opacity=50);
}
.note-modal-backdrop.open {
  display: block;
}
@media (min-width: 768px) {
  .note-modal-content {
    width: 600px;
    margin: 30px auto;
  }
}
@media (min-width: 992px) {
  .note-modal-content-large {
    width: 900px;
  }
}
.note-form-group {
  padding-bottom: 20px;
}
.note-form-group:last-child {
  padding-bottom: 0;
}
.note-form-label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #42515f;
}
.note-input {
  display: block;
  width: 100%;
  padding: 6px 4px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #ededef;
  outline: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.note-input::-webkit-input-placeholder {
  color: #eee;
}
.note-input:-moz-placeholder {
  color: #eee;
}
.note-input::-moz-placeholder {
  color: #eee;
}
.note-input:-ms-input-placeholder {
  color: #eee;
}
.note-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 13px;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: alpha(opacity=0);
  filter: alpha(opacity=0);
}
.note-tooltip.in {
  -webkit-opacity: 0.9;
  -khtml-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: alpha(opacity=90);
  filter: alpha(opacity=90);
}
.note-tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.note-tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}
.note-tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}
.note-tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}
.note-tooltip.bottom .note-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #000;
  border-width: 0 5px 5px;
}
.note-tooltip.top .note-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-top-color: #000;
  border-width: 5px 5px 0;
}
.note-tooltip.right .note-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-right-color: #000;
  border-width: 5px 5px 5px 0;
}
.note-tooltip.left .note-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-left-color: #000;
  border-width: 5px 0 5px 5px;
}
.note-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.note-tooltip-content {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
}
.note-popover {
  position: absolute;
  z-index: 1060;
  display: block;
  display: none;
  font-size: 13px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
}
.note-popover.in {
  display: block;
}
.note-popover.top {
  padding: 5 0;
  margin-top: -3px;
}
.note-popover.right {
  padding: 0 5;
  margin-left: 3px;
}
.note-popover.bottom {
  padding: 5 0;
  margin-top: 3px;
}
.note-popover.left {
  padding: 0 5;
  margin-left: -3px;
}
.note-popover.bottom .note-popover-arrow {
  top: -11px;
  left: 50%;
  margin-left: -10px;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-top-width: 0;
}
.note-popover.bottom .note-popover-arrow::after {
  top: 1px;
  margin-left: -10px;
  border-bottom-color: #fff;
  border-top-width: 0;
  content: " ";
}
.note-popover.top .note-popover-arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -10px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}
.note-popover.top .note-popover-arrow::after {
  bottom: 1px;
  margin-left: -10px;
  border-top-color: #fff;
  border-bottom-width: 0;
  content: " ";
}
.note-popover.right .note-popover-arrow {
  top: 50%;
  left: -11px;
  margin-top: -10px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}
.note-popover.right .note-popover-arrow::after {
  left: 1px;
  margin-top: -10px;
  border-right-color: #fff;
  border-left-width: 0;
  content: " ";
}
.note-popover.left .note-popover-arrow {
  top: 50%;
  right: -11px;
  margin-top: -10px;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
  border-right-width: 0;
}
.note-popover.left .note-popover-arrow::after {
  right: 1px;
  margin-top: -10px;
  border-left-color: #fff;
  border-right-width: 0;
  content: " ";
}
.note-popover-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 11px solid transparent;
}
.note-popover-arrow::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  content: " ";
}
.note-popover-content {
  min-width: 100px;
  min-height: 30px;
  padding: 3px 8px;
  color: #000;
  text-align: center;
  background-color: #fff;
}
.note-editor {
  position: relative;
}
.note-editor .note-dropzone {
  position: absolute;
  z-index: 100;
  display: none;
  color: #87cefa;
  pointer-events: none;
  background-color: #fff;
  opacity: 0.95;
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
}
.note-editor .note-dropzone.hover {
  color: #098ddf;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-editing-area {
  position: relative;
}
.note-editor .note-editing-area p {
  margin: 0 0 10px;
}
.note-editor .note-editing-area .note-editable {
  outline: 0;
}
.note-editor .note-editing-area .note-editable table {
  width: 100%;
  border-collapse: collapse;
}
.note-editor .note-editing-area .note-editable table td,
.note-editor .note-editing-area .note-editable table th {
  padding: 5px 3px;
  border: 1px solid #79838b;
}
.note-editor .note-editing-area .note-editable sup {
  vertical-align: super;
}
.note-editor .note-editing-area .note-editable sub {
  vertical-align: sub;
}
.note-editor .note-editing-area .note-editable a {
  font-family: inherit;
  font-weight: inherit;
  color: #337ab7;
  text-decoration: inherit;
  background-color: inherit;
}
.note-editor .note-editing-area .note-editable a:hover,
.note-editor .note-editing-area .note-editable a:focus {
  color: #23527c;
  text-decoration: underline;
  outline: 0;
}
.note-editor .note-editing-area .note-editable figure {
  margin: 0;
}
.note-editor.note-frame {
  border: 1px solid #e4e8ec;
}
.note-editor.note-frame.codeview .note-editing-area .note-editable {
  display: none;
}
.note-editor.note-frame.codeview .note-editing-area .note-codable {
  display: block;
}
.note-editor.note-frame .note-editing-area {
  overflow: hidden;
}
.note-editor.note-frame .note-editing-area .note-editable {
  padding: 16px 20px 0 25px;
  overflow: auto;
  color: #9C9C9C;
  word-wrap: break-word;
  background-color: #F6F8FA;
}
.note-editor.note-frame
  .note-editing-area
  .note-editable[contenteditable="false"] {
  background-color: #e5e5e5;
}
.note-editor.note-frame .note-editing-area .note-codable {
  display: none;
  width: 100%;
  padding: 10px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  font-size: 14px;
  color: #ccc;
  background-color: #222;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
}
.note-editor.note-frame.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100% !important;
}
.note-editor.note-frame.fullscreen .note-editable {
  background-color: #fff;
}
.note-editor.note-frame.fullscreen .note-resizebar {
  display: none;
}
.note-editor.note-frame .note-status-output {
  display: block;
  width: 100%;
  height: 20px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  border: 0;
  border-top: 1px solid #79838b;
}
.note-editor.note-frame .note-status-output:empty {
  height: 0;
  border-top: 0 solid transparent;
}
.note-editor.note-frame .note-status-output .pull-right {
  float: right !important;
}
.note-editor.note-frame .note-status-output .text-muted {
  color: #777;
}
.note-editor.note-frame .note-status-output .text-primary {
  color: #286090;
}
.note-editor.note-frame .note-status-output .text-success {
  color: #3c763d;
}
.note-editor.note-frame .note-status-output .text-info {
  color: #31708f;
}
.note-editor.note-frame .note-status-output .text-warning {
  color: #8a6d3b;
}
.note-editor.note-frame .note-status-output .text-danger {
  color: #a94442;
}
.note-editor.note-frame .note-status-output .alert {
  padding: 7px 10px 2px 10px;
  margin: -7px 0 0 0;
  color: #000;
  background-color: #f5f5f5;
  border-radius: 0;
}
.note-editor.note-frame .note-status-output .alert .note-icon {
  margin-right: 5px;
}
.note-editor.note-frame .note-status-output .alert-success {
  color: #3c763d !important;
  background-color: #dff0d8 !important;
}
.note-editor.note-frame .note-status-output .alert-info {
  color: #31708f !important;
  background-color: #d9edf7 !important;
}
.note-editor.note-frame .note-status-output .alert-warning {
  color: #8a6d3b !important;
  background-color: #fcf8e3 !important;
}
.note-editor.note-frame .note-status-output .alert-danger {
  color: #a94442 !important;
  background-color: #f2dede !important;
}
.note-editor.note-frame .note-statusbar {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.note-editor.note-frame .note-statusbar .note-resizebar {
  width: 100%;
  height: 9px;
  padding-top: 1px;
  cursor: ns-resize;
}
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #a9a9a9;
}
.note-editor.note-frame .note-statusbar.locked .note-resizebar {
  cursor: default;
}
.note-editor.note-frame .note-statusbar.locked .note-resizebar .note-icon-bar {
  display: none;
}
.note-editor.note-frame .note-placeholder {
    padding: 18px 25px 0 25px;
}
.note-popover {
  max-width: none;
}
.note-popover .note-popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.note-popover .note-popover-arrow {
  left: 20px !important;
}
.note-popover .note-popover-content,
.note-toolbar {
  padding: 4px 15px 8px 15px;
  margin: 0;
  border-radius: 5px 5px 0 0;
  background-color: #fff;
}
.note-popover .note-popover-content > .note-btn-group,
.note-toolbar > .note-btn-group {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 0;
}
.note-popover .note-popover-content .note-btn-group .note-table,
.note-toolbar .note-btn-group .note-table {
  min-width: 0;
  padding: 5px;
}
.note-popover
  .note-popover-content
  .note-btn-group
  .note-table
  .note-dimension-picker,
.note-toolbar .note-btn-group .note-table .note-dimension-picker {
  font-size: 18px;
}
.note-popover
  .note-popover-content
  .note-btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-mousecatcher,
.note-toolbar
  .note-btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}
.note-popover
  .note-popover-content
  .note-btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-unhighlighted,
.note-toolbar
  .note-btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}
.note-popover
  .note-popover-content
  .note-btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-highlighted,
.note-toolbar
  .note-btn-group
  .note-table
  .note-dimension-picker
  .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC")
    repeat;
}
.note-popover .note-popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .note-popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .note-popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .note-popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .note-popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .note-popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .note-popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-popover .note-popover-content .note-color-all .note-dropdown-menu,
.note-toolbar .note-color-all .note-dropdown-menu {
  min-width: 346px;
}
.note-popover .note-popover-content .note-color .dropdown-toggle,
.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px;
}
.note-popover .note-popover-content .note-color .note-dropdown-menu,
.note-toolbar .note-color .note-dropdown-menu {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette,
.note-toolbar .note-color .note-dropdown-menu .note-palette {
  display: inline-block;
  width: 160px;
  margin: 0;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette:first-child,
.note-toolbar .note-color .note-dropdown-menu .note-palette:first-child {
  margin: 0 5px;
  margin-right: 15px;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-palette-title,
.note-toolbar
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-palette-title {
  margin: 2px 7px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-color-reset,
.note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-reset,
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-color-select,
.note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select {
  width: 100%;
  padding: 2px 3px;
  margin: 3px;
  cursor: pointer;
  background-color: #fff;
  border: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-color-row,
.note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-row {
  height: 20px;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-color-reset:hover,
.note-toolbar
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-color-reset:hover {
  background: #eee;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-color-select-btn,
.note-toolbar
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-color-select-btn {
  display: none;
}
.note-popover
  .note-popover-content
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-holder-custom
  .note-color-btn,
.note-toolbar
  .note-color
  .note-dropdown-menu
  .note-palette
  .note-holder-custom
  .note-color-btn {
  border: 1px solid #eee;
}
.note-popover .note-popover-content .note-style .note-dropdown-menu,
.note-toolbar .note-style .note-dropdown-menu {
  min-width: 216px;
  padding: 5px;
}
.note-popover
  .note-popover-content
  .note-style
  .note-dropdown-menu
  > div:first-child,
.note-toolbar .note-style .note-dropdown-menu > div:first-child {
  margin-right: 5px;
}
.note-popover .note-popover-content .note-btn-fontname .note-dropdown-menu,
.note-toolbar .note-btn-fontname .note-dropdown-menu {
  min-width: 200px;
}
.note-popover .note-popover-content .note-dropdown-menu,
.note-toolbar .note-dropdown-menu {
  min-width: 160px;
}
.note-popover .note-popover-content .note-dropdown-menu.right,
.note-toolbar .note-dropdown-menu.right {
  right: 0;
  left: auto;
}
.note-popover .note-popover-content .note-dropdown-menu.right::before,
.note-toolbar .note-dropdown-menu.right::before {
  right: 9px;
  left: auto !important;
}
.note-popover .note-popover-content .note-dropdown-menu.right::after,
.note-toolbar .note-dropdown-menu.right::after {
  right: 10px;
  left: auto !important;
}
.note-popover
  .note-popover-content
  .note-dropdown-menu.note-check
  .note-dropdown-item
  i,
.note-toolbar .note-dropdown-menu.note-check .note-dropdown-item i {
  color: deepskyblue;
  visibility: hidden;
}
.note-popover
  .note-popover-content
  .note-dropdown-menu.note-check
  .note-dropdown-item.checked
  i,
.note-toolbar .note-dropdown-menu.note-check .note-dropdown-item.checked i {
  visibility: visible;
}
.note-popover .note-popover-content .note-dropdown-menu .note-dropdown-item > *,
.note-toolbar .note-dropdown-menu .note-dropdown-item > * {
  margin: 0;
}
.note-popover .note-popover-content .note-fontsize-10,
.note-toolbar .note-fontsize-10 {
  font-size: 10px;
}
.note-popover .note-popover-content .note-color-palette,
.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-popover .note-popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: 1px solid #fff;
}
.note-popover
  .note-popover-content
  .note-color-palette
  div
  .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #000;
}
.note-modal .note-modal-body label {
  display: inline-block;
  padding: 2px 5px;
  margin-bottom: 2px;
}
.note-modal .note-modal-body .help-list-item:hover {
  background-color: #e0e0e0;
}
@-moz-document url-prefix() {
  .note-image-input {
    height: auto;
  }
}
.note-placeholder {
  position: absolute;
  display: none;
  color: gray;
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid black;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: alpha(opacity=30);
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle {
  width: 7px;
  height: 7px;
  border: 1px solid #000;
}
.note-handle .note-control-selection .note-control-holder {
  width: 7px;
  height: 7px;
  border: 1px solid #000;
}
.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  background-color: #fff;
  border: 1px solid #000;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: none;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}
.note-handle .note-control-selection .note-control-se.note-control-holder {
  cursor: default;
  border-top: 0;
  border-left: none;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  color: #fff;
  background-color: #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-opacity: 0.7;
  -khtml-opacity: 0.7;
  -moz-opacity: 0.7;
  opacity: 0.7;
  -ms-filter: alpha(opacity=70);
  filter: alpha(opacity=70);
}
.note-hint-popover {
  min-width: 100px;
  padding: 2px;
}
.note-hint-popover .note-popover-content {
  max-height: 150px;
  padding: 3px;
  overflow: auto;
}
.note-hint-popover .note-popover-content .note-hint-group .note-hint-item {
  display: block !important;
  padding: 3px;
}
.note-hint-popover
  .note-popover-content
  .note-hint-group
  .note-hint-item.active,
.note-hint-popover
  .note-popover-content
  .note-hint-group
  .note-hint-item:hover {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: #428bca;
  outline: 0;
}
.help-list-item label {
  display: inline-block;
  margin-bottom: 5px;
}
