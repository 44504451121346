
  $coverImageHeight: 300px;
  
  .profile_box_1 {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
    
    .profile-picture {
      position: absolute;
      top: $coverImageHeight - 60px;
      left: 50%;
      margin-left: -55px;
      
      img {
        border: 5px solid #fff;
        border-radius: 100%;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        width: 100px;
        height: 100px;
      }
    }
    .profile-cover-image {
        height: $coverImageHeight;
        overflow: hidden;
        
        img {
          width: 100%;
        }
      }
      
      .profile-content {
        padding: 40px 20px;
        text-align: center;
        
        h1 {
          font-weight: normal;
          margin-bottom: 0.2em;
        }
        
        p {
          font-size: 1.1em;
          line-height: 1.6;
          color: #d2d2d2;
        }
        
        .socials {
          a {
            color: #d2d2d2;
            margin: 0 15px;
            font-size: 1.6em;
            transform: translateY(4px);
            
            &:hover {
              color: #aaa;
              transform: translateY(0);
            }
          }
        }
      }
  }
  

  $bg-start: #9d9181;
$bg-end: #9e866c;
$card-bg: #e6e5e1;
$dribbble-color: #ea4b89;
$twitter-color: #68aade;
$facebook-color: #3b5999;


.card-profile {
  background-color: $card-bg;
  border-radius: 0;
  border: 0;
  box-shadow: 1em 1em 2em rgba(0,0,0,.2);
  
  .card-img-top {
    border-radius: 0;
  }

  .card-img-profile {
    max-width: 100%;
    border-radius: 50%;
    margin-top: -95px;
    margin-bottom: 35px;
    border: 5px solid $card-bg;
  }

  .card-title {
    margin-bottom: 50px;

    small {
      display: block;
      font-size: .6em;
      margin-top: .2em;
    }
  }
  
  .card-links {
    margin-bottom: 25px;

    .fa {
      margin: 0 1em;
      font-size: 1.6em;
      
      &:focus,
      &:hover {
        text-decoration: none;
      }
      
      &.fa-dribbble {
        color: $dribbble-color;
        font-weight: bold;
        
        &:hover {
          color: darken($dribbble-color, 10%);
        }
      }

      &.fa-twitter {
        color: $twitter-color;

        &:hover {
          color: darken($twitter-color, 10%);
        }
      }
      
      &.fa-facebook {
        color: $facebook-color;

        &:hover {
          color: darken($facebook-color, 10%);
        }
      }
    }
  }
}

.profile-card-4{
            position:relative;
            overflow: hidden;
            margin-bottom:10px;
            box-shadow:0px 2px 3px #222;
        }
        .profile-card-4:hover .profile-img img
        {
            transform: scale(1.2);
        }
        .profile-card-4 .profile-img img{
            width:100%;
            height:auto;
            transition: transform 1s;
        }
        .profile-card-4 .profile-content::before{
            content: "";
            position:absolute;
            top:0;
            left:0;
            width:50%;
            height:40%;
            border:3px solid #333;
            border-right:3px solid transparent;
            border-bottom:3px solid transparent;
            transform: scale(1,1);
            transition: all 0.50s linear;
        }
        .profile-card-4 .profile-content::after{
            content: "";
            position:absolute;
            bottom:0;
            right:0;
            width:50%;
            height:40%;
            border:3px solid #222;
            border-left:3px solid transparent;
            border-top:3px solid transparent;
            transition: all 0.50s linear;
            
        }
        .profile-card-4:hover .profile-content:before{
            top:20px;
            left:20px;
        }
        .profile-card-4:hover .profile-content:after{
            bottom:20px;
            right:20px;
        }
       
        .profile-card-4 .profile-content{
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            padding:50% 4%;
            text-align: center;
           
        }
        .profile-card-4 .profile-content:hover{
            background-color: rgba(255, 43, 131, 0.5);
        }
        .profile-card-4 .profile-content .title{
            font-size:24px;
            color:#333;
            opacity:0;
            letter-spacing:2px;
            text-transform:uppercase;
            transform: translateY(-100px);
            transition:all 900ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.20s;
        }
        .profile-card-4:hover .title{
            opacity:1;
            transform: translateY(0);
        }
        .profile-card-4:hover .title::after{
            content:"";
            display:block;
            width:50%;
            margin:10px auto;
            border-bottom:2px solid #222;
        }
        .profile-card-4 .profile-content .title > span{
            display:block;
            margin:5px 0;
            font-size: 14px;
            text-transform: capitalize;
        }
        .profile-card-4 .profile-content .social-link{
            margin:20px 0;
            padding:0;
            opacity:0;
            transform: translateY(100px);
            transition:all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.20s;
        }
        .profile-card-4:hover .social-link{
            opacity:1;
            transform: translateY(0);
        }
        .profile-card-4 .profile-content .social-link li{
            display: inline-block;
            list-style: none;
            margin:0 4px;
        }
        .profile-card-4 .profile-content .social-link li a{
            color:#333;
            width:30px;
            height:25px;
            line-height:24px;
            text-align: center;
            border-radius:5px;
            background-color: #fff;
        }
        




.pCard_card {
    height: 615px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    background-color: #f6fcfe;
    -webkit-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
    -ms-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
    -o-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
    position: relative;
    overflow: hidden;
  }
  
  /****************
  Back
  ****************/
  
  .pCard_card .pCard_back {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -139px;
    font-weight: 600;
    z-index: 1;
  }
  .pCard_card .pCard_back a {
    text-decoration: none;
  }
  
  /****************
  UP
  ****************/
  
  .pCard_card .pCard_up {
    position: absolute;
    width: 100%;
    height: 437px;
    background-image: url(../img/card2.jpg);
    background-position: 50%;
    background-size: cover;
    z-index: 3;
    text-align: center;
    -webkit-border-top-left-radius: 30px;
    -moz-border-top-left-radius: 30px;
    -ms-border-top-left-radius: 30px;
    -o-border-top-left-radius: 30px;
    border-top-left-radius: 30px;
    -webkit-border-top-right-radius: 30px;
    -moz-border-top-right-radius: 30px;
    -ms-border-top-right-radius: 30px;
    -o-border-top-right-radius: 30px;
    border-top-right-radius: 30px;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  
  .pCard_on .pCard_up {
    height: 100px;
    box-shadow: 0 0 30px #cfd8dc;
  }
  
  .pCard_card .pCard_up .pCard_text {
    position: absolute;
    top: 319px;
    left: 0;
    right: 0;
    color: #f1f7f9;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  
  .pCard_on .pCard_up .pCard_text {
    top: 20px;
  }
  
  .pCard_card .pCard_up .pCard_text h2 {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
  }
  
  .pCard_card .pCard_up .pCard_text p {
    margin: 0;
    font-size: 16px;
    color: #e3f1f5;
  }
  
  .pCard_card .pCard_up .pCard_add {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #ed145b;
    -webkit-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
    -ms-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
    -o-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
    box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
    position: absolute;
    top: 392px;
    left: 0;
    right: 0;
    margin: auto;
    width: 88px;
    height: 88px;
    cursor: pointer;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  
  .pCard_on .pCard_up .pCard_add {
    -webkit-transform: rotate(360deg) scale(0.5);
    -moz-transform: rotate(360deg) scale(0.5);
    -ms-transform: rotate(360deg) scale(0.5);
    -o-transform: rotate(360deg) scale(0.5);
    transform: rotate(360deg) scale(0.5);
    top: 470px;
  }
  
  /****************
  Down
  ****************/
  
  .pCard_card .pCard_down {
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 178px;
    z-index: 2;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-bottom-left-radius: 30px;
    -ms-border-bottom-left-radius: 30px;
    -o-border-bottom-left-radius: 30px;
    border-bottom-left-radius: 30px;
    -webkit-border-bottom-right-radius: 30px;
    -moz-border-bottom-right-radius: 30px;
    -ms-border-bottom-right-radius: 30px;
    -o-border-bottom-right-radius: 30px;
    border-bottom-right-radius: 30px;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  
  .pCard_on .pCard_down {
    height: 100px;
    -webkit-box-shadow: 0 0 30px #cfd8dc;
    -moz-box-shadow: 0 0 30px #cfd8dc;
    -ms-box-shadow: 0 0 30px #cfd8dc;
    -o-box-shadow: 0 0 30px #cfd8dc;
    box-shadow: 0 0 30px #cfd8dc;
  }
  
  .pCard_card .pCard_down div {
    width: 33.333%;
    float: left;
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  
  .pCard_on .pCard_down div {
    margin-top: 10px;
  }
  
  .pCard_card .pCard_down div p:first-of-type {
    color: #68818c;
    margin-bottom: 5px;
  }
  
  .pCard_card .pCard_down div p:last-of-type {
    color: #334750;
    font-weight: 700;
    margin-top: 0;
  }
  .pCard_card .pCard_back a i {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 36px;
    margin: 10px;
    display: inline-block;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    font-size: 15px;
  }
  
  .pCard_card .pCard_back a i:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  
  .pCard_card .pCard_back a:nth-of-type(1) i {
    color: #3b5998;
    border: 2px solid #3b5998;
  }
  
  .pCard_card .pCard_back a:nth-of-type(2) i {
    color: #0077b5;
    border: 2px solid #0077b5;
  }
  
  .pCard_card .pCard_back a:nth-of-type(3) i {
    color: #1769ff;
    border: 2px solid #1769ff;
  }
  
  .pCard_card .pCard_back a:nth-of-type(4) i {
    color: #000000;
    border: 2px solid #000000;
  }
  
  .pCard_card .pCard_back a:nth-of-type(5) i {
    color: #eb5e95;
    border: 2px solid #eb5e95;
  }
  
  .pCard_card .pCard_back a:nth-of-type(6) i {
    color: #3f729b;
    border: 2px solid #3f729b;
  }
  
  .pCard_card .pCard_up .pCard_add i {
    color: white;
    font-size: 38px;
    line-height: 88px;
  }


  
  .profile-cart_2 {
    min-height: 500px;
    box-shadow: 0 0 5px 1px rgba(52, 52, 52, 0.224);
    position: relative;
    border-radius: 20px;
    transition: 2s;
    &:hover {
        box-shadow: 0 0 15px 1px rgba(52, 52, 52, 0.25)
      }
      &::after {
        content: " ";
        background-image: linear-gradient(135deg, #3B76EF 10%, #3B76EF 100%);
        clip-path: circle(50% at 50% 1%);
        display: block;
        min-height: 500px;
        transition: 2s;
        border-radius: 20px;
      }
      &:hover::after {
        content: " ";
        clip-path: circle(100% at 50% 170%);
        transition: 2s;
        display: block;
        min-height: 500px;
        transition: 2s;
        border-radius: 20px;
      
      }
      img {
        width: 100px !important;
        height: 100px !important;
        position: absolute;
        top: 78px;
        left: 37%;
        z-index: 99999999;
        transition: 2s;
        border-radius: 100%;
      }
      
      :hover img {
      
        transform: scale(1.3) translateY(20px);
        transition: 2s;
      
      }
      
      h1 {
        z-index: 999;
        position: absolute;
        justify-content: center;
        display: flex;
        width: 100%;
        font-family: "poppins bold";
        color: rgba(30, 29, 29, 0.85);
        letter-spacing: 2px;
      }
      
      p {
        position: absolute;
        z-index: 999;
        display: flex;
        align-items: center;
        text-align: center;
        top: 50%;
        font-family: "poppins";
        font-weight: 500;
        margin: 0 20px;
        color: rgba(30, 29, 29, 0.85);
      }
      
      .social-area {
        position: absolute;
        bottom: 30px;
        z-index: 999;
        justify-content: center;
        display: flex;
        width: 100%;
      }
      
      .social-area a {
        color: rgba(30, 29, 29, 0.85);
      }
      
      .social-area a {
        margin: 15px 15px 0 15px;
        font-size: 20px;
        transition: 4s;
      }
      
      :hover .social-area svg {
        color: white;
        transition: 1s;
      }
      
       .social-area svg:hover {
        transform: scale(1.5);
      }
  }
  

  
  
.profile_card_5{
      .cover-photo{
        background: url(https://images.unsplash.com/photo-1540228232483-1b64a7024923?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80);
        height: 160px;
        width: 100%;
        border-radius: 5px 5px 0 0;
      }
      .profile{
        height: 100px;
        width: 100px;
        border-radius: 50%;
        position: relative;
        border: 1px solid #1f1a32;
        padding: 7px;
        background: #292343;
        margin-top: 80px;
        margin-left: 10px;
      }
      .profile-name{
        font-size: 25px;
        font-weight: bold;
        margin: 27px 0 0 120px;
      }
      .about{
        margin-top: 35px;
        line-height: 21px;
      }
      button{
        margin: 10px 0 40px 0;
      }
      .msg-btn, .follow-btn{
        background: #03bfbc;
        border: 1px solid #03bfbc;
        padding: 10px 25px;
        color: #231e39;
        border-radius: 3px;
        font-family: Montserrat, sans-serif;
        cursor: pointer;
      }
      .follow-btn{
        margin-left: 10px;
        background: transparent;
        color: #02899c;
      }
      .follow-btn:hover{
        color: #231e39;
        background: #03bfbc;
        transition: .5s;
      }
       i{
        padding-left: 20px;
        font-size: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        transition: .5s;
      }
       i:hover{
        color: #03bfbc;
      }
}
