/* Normal desktop :1200px. */
/* Normal desktop :992px. */
/* Tablet desktop :768px. */
/* small mobile :320px. */
@import url("https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.CRM_dropdown.dropdown .dropdown-toggle:hover, .CRM_dropdown.dropdown .dropdown-toggle:focus {
  background: -webkit-gradient(linear, left top, right top, from(#7C32FF), color-stop(70%, #A235EC), to(#C738D8));
  background: -o-linear-gradient(left, #7C32FF 0%, #A235EC 70%, #C738D8 100%);
  background: linear-gradient(90deg, #7C32FF 0%, #A235EC 70%, #C738D8 100%);
}

/**************** common css start ****************/
body {
  font-family: "Muli", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px;
  background: #F7FAFF;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1310px;
  }
}
.body_white_bg {
  background: #fff;
}

.br_5 {
  border-radius: 5px;
}

.message_submit_form:focus {
  outline: none;
}

input:hover, input:focus {
  outline: none !important;
  box-shadow: 0px 0px 0px 0px transparent !important;
}

:focus {
  outline: -webkit-focus-ring-color auto 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: transparent;
  box-shadow: none;
}

.form-control {
  border-color: #BBC1C9;
}

.form-control:focus {
  box-shadow: 0 0 0 0rem transparent !important;
  border-color: #BBC1C9;
}

.dropdown-menu {
  margin: 0;
  padding: 0;
}

.section_padding {
  padding: 120px 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section_padding {
    padding: 80px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section_padding {
    padding: 70px 0px;
  }
}
@media (max-width: 575.98px) {
  .section_padding {
    padding: 70px 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section_padding {
    padding: 70px 0px;
  }
}

.padding_top {
  padding-top: 120px;
}
@media (max-width: 991px) {
  .padding_top {
    padding-top: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .padding_top {
    padding-top: 80px;
  }
}

.padding_bottom {
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .padding_bottom {
    padding-bottom: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .padding_bottom {
    padding-bottom: 80px;
  }
}

a {
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #222222;
  font-family: "Muli", sans-serif;
  font-weight: 600;
  line-height: 1.2;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
}

li {
  list-style: none;
}

ul, ol {
  margin: 0;
  padding: 0;
}

p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0px;
  color: #828BB2;
  font-family: "Muli", sans-serif;
  font-weight: 300;
}

a:focus, .button:focus, button:focus, .btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  -webkit-transition: 1s;
  transition: 1s;
}

.p_30 {
  padding: 30px;
}
@media (max-width: 991px) {
  .p_30 {
    padding: 20px;
  }
}

.p_20 {
  padding: 20px;
}

.p_15 {
  padding: 20px 20px 15px;
}

.pb_20 {
  padding-bottom: 20px;
}

.mt_60 {
  margin-top: 60px;
}
@media (max-width: 991px) {
  .mt_60 {
    margin-top: 30px;
  }
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}
@media (max-width: 991px) {
  .mt_30 {
    margin-top: 15px;
  }
}

.mt_10 {
  margin-top: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_15_imp {
  margin-bottom: 13px !important;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_20_imp {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 20px;
}

.mb_50 {
  margin-bottom: 50px;
}
@media (max-width: 575.98px) {
  .mb_50 {
    margin-bottom: 30px;
  }
}

.mt_50 {
  margin-top: 50px;
}
@media (max-width: 575.98px) {
  .mt_50 {
    margin-top: 25px;
  }
}

.mt_20 {
  margin-top: 20px;
}

.mb_10 {
  margin-bottom: 10px !important;
}

th:focus, td:focus {
  outline: -webkit-focus-ring-color auto 0;
}

table.dataTable tbody th, table.dataTable tbody td {
  vertical-align: middle;
}

.mt_40 {
  margin-top: 40px;
}

.section_bg {
  background-color: #fef1f2;
}

.cs_checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cs_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.cs_checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #e4e8ec;
}

.section_tittle {
  text-align: center;
}
.section_tittle h2 {
  font-size: 36px;
  margin-bottom: 25px;
  line-height: 33px;
}
@media (max-width: 991px) {
  .section_tittle h2 {
    margin-bottom: 15px;
    font-size: 25px;
  }
}
@media (max-width: 991px) {
  .section_tittle h2 {
    font-size: 30px;
  }
}
.section_tittle p {
  margin-bottom: 72px;
}
@media (max-width: 991px) {
  .section_tittle p {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section_tittle p {
    margin-bottom: 50px;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.cs_checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cs_checkbox input:checked ~ .checkmark:after {
  display: block;
  left: 6px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #7e7172;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: "";
}

.cs_checkbox span {
  padding: 0 !important;
}

.custom_checkbox tr th:first-child, .custom_checkbox tr td:first-child {
  padding: 16px 0 16px 0 !important;
}

.border_1px {
  border-right: 1px solid rgba(130, 139, 178, 0.3);
  height: 40px;
  margin-right: 20px;
  margin-left: 8px;
}

.nice_Select {
  border: 0;
  border-radius: 0px;
  padding-left: 0;
  padding-right: 30px;
  color: #415094;
}
.nice_Select .nice-select-search-box {
  width: 200px !important;
}
.nice_Select.bgLess {
  background: transparent;
  border: 0 !important;
}
.nice_Select:after {
  content: "\e62a";
  font-family: "themify";
  border: 0;
  transform: rotate(0deg);
  margin-top: -16px;
  font-size: 12px;
  font-weight: 500;
  right: 18px;
  transform-origin: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-top: -22px;
}
.nice_Select.open::after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 15px;
  margin-top: 12px;
  right: 12px;
}
.nice_Select:focus {
  box-shadow: none;
  border: 0 !important;
}
.nice_Select:hover {
  border: 0;
}
.nice_Select.open .list {
  min-width: 200px;
  left: 0;
  overflow-y: auto;
}
.nice_Select.open .list li:first-child {
  color: #000;
}
.nice_Select .current {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.nice_Select .list {
  width: 100%;
  left: auto;
  right: 0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 1px;
  z-index: 9999 !important;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3);
}
.nice_Select .list li {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.nice_Select2 {
  border: 0;
  border-radius: 0px;
  padding-left: 0;
  padding-right: 30px;
  color: #415094;
  background: transparent;
  border: 1px solid #EAF0F4;
  padding-left: 25px;
  padding-right: 47px;
  border-radius: 4px;
  height: 32px;
  line-height: 31px;
  font-size: 12px;
  text-transform: capitalize;
}
.nice_Select2 .nice-select-search-box {
  width: 100% !important;
  margin-top: 5px;
}
.nice_Select2:after {
  content: "\e62a";
  font-family: "themify";
  border: 0;
  transform: rotate(0deg);
  margin-top: -16px;
  font-size: 12px;
  font-weight: 500;
  right: 18px;
  transform-origin: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-top: -16px;
  right: 30px;
}
.nice_Select2:active, .nice_Select2.open, .nice_Select2:focus {
  border-color: #EAF0F4;
}
.nice_Select2.open::after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 15px;
  margin-top: 8px;
  right: 24px;
}
.nice_Select2:focus {
  box-shadow: none;
  border: 1px solid #EAF0F4 !important;
}
.nice_Select2:hover {
  border: 1px solid #EAF0F4 !important;
}
.nice_Select2.open .list {
  min-width: 100%;
  margin-top: 5px;
  left: 0;
  overflow-y: auto;
}
.nice_Select2.open .list li:first-child {
  color: #000;
}
.nice_Select2 .current {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.nice_Select2 .list {
  width: 100%;
  left: auto;
  right: 0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 1px;
  z-index: 9999 !important;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3);
}
.nice_Select2 .list li {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.white_box {
  background-color: #fff;
  padding: 20px;
}
@media (max-width: 991px) {
  .white_box {
    padding: 20px 20px;
  }
}
@media (max-width: 575.98px) {
  .white_box {
    padding: 25px 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .white_box {
    padding: 30px 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .white_box {
    padding: 30px 20px;
  }
}
.white_box h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 22px;
  font-family: "Muli", sans-serif;
}
@media (max-width: 991px) {
  .white_box h3 {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .white_box h3 {
    margin-bottom: 10px;
  }
}
.white_box h3 span {
  font-weight: 300;
  color: #7e7172;
}

.progress-bar {
  text-align: right;
  padding-right: 8px;
}

.white_box_p_30 {
  padding: 30px;
  background-color: #fff;
}
@media (max-width: 991px) {
  .white_box_p_30 {
    padding: 25px 15px;
  }
}

.cu_dropdown .btn {
  border: 1px solid #f1f2f3;
  background-color: #fef1f2;
  color: #7e7172;
  font-size: 12px;
  font-weight: 300;
  padding: 5px 15px;
}
.cu_dropdown .btn_1 {
  padding: 11px 20px;
}
.cu_dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 10px;
  vertical-align: 0;
  content: "\e64b";
  border-top: none;
  font-family: "themify";
  font-size: 10px;
}
.cu_dropdown .dropdown-menu {
  min-width: auto;
  background-color: #222222;
  min-width: 102px;
  left: 0 !important;
  border-radius: 0 0 5px 5px;
  top: -2px !important;
  padding: 17px 0;
}
.cu_dropdown .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  padding: 7px 20px;
  padding: 7px 20px;
  color: #fff;
}
.cu_dropdown .left_arrow:hover {
  background-color: transparent;
  padding-left: 38px;
}
.cu_dropdown .left_arrow:after {
  left: 18px;
  top: 11px;
}

.switch {
  -webkit-appearance: none;
  background-color: gray;
  border-radius: 43px;
  position: relative;
  cursor: pointer;
  padding: 14px 25px !important;
  margin-right: 20px;
  height: 30px;
}
.switch::after {
  top: 6px;
  left: 6px;
  content: "";
  width: 15px;
  height: 15px;
  background-color: #4D4F5C;
  position: absolute;
  border-radius: 100%;
  transition: 1s;
}
.switch:checked {
  background-color: #4D4F5C !important;
}
.switch:checked::after {
  background-color: #fff !important;
}
.switch:checked::after {
  transform: translateX(25px);
}
.switch:focus {
  outline-color: transparent;
}

.menu_bropdown {
  display: inline-block;
}
.menu_bropdown .dropdown-menu {
  min-width: 168px !important;
  right: 0;
}

@media (max-width: 991px) {
  .sidebar_widget {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar_widget {
    margin-top: 0;
  }
}

.text_underline {
  color: #222222;
  text-decoration: underline;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.text_underline:hover {
  color: #222222;
  text-decoration: underline;
}

.check_box_item {
  margin-top: 11px;
}
.check_box_item .single_check_box {
  padding: 15px 0;
  border-bottom: 1px solid #e4e8ec;
}
.check_box_item .single_check_box .switch {
  -webkit-appearance: none;
  background-color: transparent;
  padding: 14px 27px !important;
  margin-right: 0;
  border: 1px solid #e4e8ec;
}
.check_box_item .single_check_box .switch:after {
  top: 4px;
  width: 20px;
  height: 20px;
}
.check_box_item .single_check_box .switch:checked::after {
  background-color: #7e7172 !important;
  left: 4px;
}
.check_box_item .single_check_box .switch:checked {
  background-color: transparent !important;
}

.list_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}
@media (max-width: 575.98px) {
  .list_header {
    display: block;
  }
}
.list_header p {
  color: #222222;
  font-weight: 500;
}
.list_header .nice_Select {
  margin-left: 15px;
}
@media (max-width: 575.98px) {
  .list_header .nice_Select {
    margin-left: 10px;
  }
}
.list_header .list_header_filter {
  display: flex;
  align-items: center;
}
@media (max-width: 575.98px) {
  .list_header .list_header_filter {
    margin-top: 15px;
  }
}
.list_header .list_header_filter img {
  width: 17px;
  margin-right: 8px;
}

.cs_modal .modal-content {
  background-color: #fef1f2;
  padding: 0 30px;
}
@media (max-width: 575.98px) {
  .cs_modal .modal-content {
    padding: 0 20px;
  }
}
.cs_modal .modal-header {
  padding: 34px 0 30px 0px;
  align-items: center;
  border-bottom: 0px solid transparent;
}
.cs_modal .modal-header h5 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}
.cs_modal .modal-header .close {
  padding: 0;
  margin: 0;
  opacity: 1;
}
.cs_modal .modal-header .close i {
  font-size: 14px;
  color: #707070;
}
.cs_modal .modal-body {
  padding: 35px 30px !important;
  background: #fff;
  border-radius: 5px;
}
@media (max-width: 575.98px) {
  .cs_modal .modal-body {
    padding: 25px 20px !important;
  }
}
.cs_modal .modal-body .chose_thumb_title {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}
.cs_modal .modal-body .input_wrap label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}
.cs_modal .modal-body .input_wrap textarea {
  height: 100px;
  padding: 0;
  line-height: 28px;
  padding: 13px 25px;
}
.cs_modal .modal-body .nice_Select, .cs_modal .modal-body input, .cs_modal .modal-body textarea {
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  border: 1px solid #eee1e2;
  padding: 10px 25px;
  color: #7e7172;
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  display: block;
  margin-bottom: 21px;
  font-weight: 300;
  border-radius: 3px;
}
.cs_modal .modal-body .nice_Select::placeholder, .cs_modal .modal-body input::placeholder, .cs_modal .modal-body textarea::placeholder {
  color: #7e7172;
  font-weight: 300;
  opacity: 1;
}
.cs_modal .modal-body .nice_Select {
  line-height: 30px;
}
.cs_modal .modal-body .nice_Select :after {
  left: 22px;
}
.cs_modal .modal-body ::placeholder {
  color: #222222;
}
.cs_modal .modal_btn {
  display: flex;
  justify-content: start;
  align-items: center;
}
.cs_modal .modal_btn .btn_1 {
  padding: 16px 26px !important;
  margin: 0 5px;
  text-align: center;
}
.cs_modal .modal-footer {
  border-top: 0px solid transparent;
  padding: 30px 0 40px 0;
}

.form_box .from_group {
  display: block;
  width: 100%;
  margin-bottom: 23px;
}
.form_box .from_group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #707070;
}
.form_box .from_group .nice_Select, .form_box .from_group input, .form_box .from_group textarea {
  width: 100%;
  display: block;
  background-color: #fef1f2;
  border: 1px solid #f1f2f3;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  padding: 10px 20px;
}
.form_box .from_group textarea {
  height: 115px;
  line-height: 27px;
}
.form_box .from_group .bootstrap-tagsinput input {
  border: none !important;
  width: auto !important;
  display: inline-block !important;
  background-color: transparent !important;
}
.form_box .from_group .bootstrap-tagsinput .badge [data-role=remove]:after {
  background-color: transparent !important;
}
.form_box .from_group .bootstrap-tagsinput .badge {
  margin: 0 5px;
  padding: 9px 8px;
  line-height: 12px;
  font-weight: 500;
  font-size: 13px;
}
.form_box .from_group .badge-info {
  color: #fff;
  background-color: #2EC9B8;
}
.form_box .from_group .nice_Select {
  line-height: 30px;
}

.note-editable {
  background-color: #fef1f2;
}

.note-btn-group.note-style {
  display: none;
}

.plr_30 {
  padding: 0 30px !important;
}
@media (max-width: 767.98px) {
  .plr_30 {
    padding: 0 15px !important;
  }
}

.mb_8 {
  margin-bottom: 8px !important;
}

.mb_7 {
  margin-bottom: 7px !important;
}

.ml-10 {
  margin-left: 10px;
}

.mb_30 {
  margin-bottom: 30px !important;
}

@media (max-width: 575.98px) {
  .lms_block {
    display: block !important;
  }
}

@media (max-width: 575.98px) {
  .add_button {
    margin-top: 15px;
    margin-left: 0;
  }
}

.lms_pagination_wrap {
  margin-top: 30px;
}
.lms_pagination_wrap ul li {
  display: inline-block;
  margin: 0 0.8px;
}
.lms_pagination_wrap ul li a {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #fff;
  line-height: 40px;
  color: #cec1c2;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
}
.lms_pagination_wrap ul li a.active {
  background: #fe1724;
  color: #fff;
}
.lms_pagination_wrap ul li a:hover {
  background: #fe1724;
  color: #fff;
}

@media (min-width: 768px) {
  .modal-dialog.custom-modal-dialog {
    max-width: 650px;
  }
}
@media (min-width: 992px) {
  .modal-dialog.custom-modal-dialog {
    max-width: 950px;
  }
}
@media (min-width: 1200px) {
  .modal-dialog.custom-modal-dialog {
    max-width: 1050px;
  }
}
.file_upload {
  border: 1px solid #eee1e2 !important;
  padding: 9px 24px;
  border-radius: 3px;
  /* input file style */
}
.file_upload label {
  margin-bottom: 0 !important;
}
.file_upload .form-group {
  padding: 0;
  margin-bottom: 0;
}
.file_upload .btn_file_upload {
  color: #fff;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  display: inline-block;
}
.file_upload .btn_file_upload:hover, .file_upload .btn_file_upload:focus {
  color: #888888;
  border-color: #888888;
}
.file_upload .input-file, .file_upload .input-file2 {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file_upload .input-file + .js-labelFile, .file_upload .input-file + .js-labelFile2, .file_upload .input-file2 + .js-labelFile, .file_upload .input-file2 + .js-labelFile2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.file_upload .btn {
  padding: 0px 16px !important;
  border-radius: 5px;
  background-color: #fe1724;
  display: inline-block !important;
  font-size: 14px;
  color: #fff !important;
  border-radius: 30px;
  bottom: 0;
  margin-bottom: 0;
}
.file_upload .btn:hover {
  background-color: #4D4F5C;
  color: #fff;
  border: 0;
}
.file_upload .form-group {
  background-color: #fff;
  width: 100%;
  padding: 0px;
  border-radius: 5px;
}

td:focus, th:focus {
  outline: none !important;
}

.mb_20 {
  margin-bottom: 20px;
}

.mr_10 {
  margin-right: 10px !important;
}

.checkbox_wrap p {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  margin-bottom: 0;
}

.lms_checkbox_1 {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 50px;
  margin-bottom: 0;
  margin-right: 15px;
}
.lms_checkbox_1 input {
  display: none !important;
}
.lms_checkbox_1 .slider-check {
  background-color: #7e7172;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.lms_checkbox_1 .slider-check.round {
  border-radius: 34px;
}
.lms_checkbox_1 .slider-check::before {
  background-color: #222222;
  bottom: 5px;
  content: "";
  height: 20px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 20px;
}
.lms_checkbox_1 .slider-check.round:before {
  border-radius: 50%;
}
.lms_checkbox_1 input:checked + .slider-check {
  background-color: #fef1f2;
  color: #fff;
  content: "oui";
}
.lms_checkbox_1 input:checked + .slider-check:before {
  transform: translateX(20px);
  background: #fe1724;
}

.input_wrap label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}

@media (min-width: 991px) {
  .medium_modal_width .modal-dialog {
    max-width: 780px;
  }
}

.list_header_btn_wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
  margin-right: 60px;
}
@media (max-width: 575.98px) {
  .list_header_btn_wrapper {
    display: block;
    margin-bottom: 15px;
    margin-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .list_header_btn_wrapper {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .list_header_btn_wrapper {
    margin-right: 0;
    display: block;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .list_header_btn_wrapper {
    margin-right: 0;
    display: block;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .list_header_btn_wrapper {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .list_header_block {
    display: block;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .list_header_block .success_faild_btn {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .list_header_block .serach_field_2 {
    width: 300px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .list_header_block .serach_field_2 {
    width: 380%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .list_header_block .box_right {
    justify-content: space-between;
  }
}

.gj-datepicker .fa-calendar-alt {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
  color: #cec1c2;
  right: 25px;
}

.white_box_border {
  border: 1px solid rgba(202, 206, 213, 0.7);
}

.nowrap {
  white-space: nowrap;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.mr_5 {
  margin-right: 5px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mt_10px {
  margin-top: 10px;
}

.pt_30 {
  padding-top: 30px !important;
}
.wrapper .progress {
  margin-bottom: 15px;
}

.apexcharts-datalabels-group {
  position: relative;
  top: -13px !important;
}

.cs_modal {
  background-color: #fef1f2;
}
.cs_modal .modal-header {
  background-color: #F7FAFF;
  padding: 23px 30px;
  border-bottom: 0px solid transparent;
}
.cs_modal .modal-header h5 {
  font-size: 22px;
  font-weight: 600;
}
.cs_modal .modal-header span {
  font-size: 14px;
  color: #707070;
  opacity: 1;
}
.cs_modal .modal-body {
  padding: 35px 30px;
}
.cs_modal .modal-body input, .cs_modal .modal-body .nice_Select {
  height: 50px;
  line-height: 50px;
  padding: 10px 20px;
  border: 1px solid #F1F3F5;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  width: 100%;
}
.cs_modal .modal-body .nice_Select {
  line-height: 29px;
}
.cs_modal .modal-body .nice_Select:after {
  right: 22px;
}
.cs_modal .modal-body .nice_Select .list {
  width: 100%;
}
.cs_modal .modal-body .form-group {
  margin-bottom: 12px;
}
.cs_modal .modal-body textarea {
  height: 168px;
  padding: 15px 20px;
  border: 1px solid #F1F3F5;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
}
.cs_modal .modal-body p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  color: #707070;
  text-align: center;
}
.cs_modal .modal-body p a {
  color: #4D4F5C;
}
.cs_modal .modal-body .btn_1 {
  width: 100%;
  display: block;
  margin-top: 20px;
}
.cs_modal .modal-body .social_login_btn .btn_1 {
  color: #fff;
  background-color: #3B5998;
  border: 1px solid #3B5998;
  margin-top: 0;
}
.cs_modal .modal-body .social_login_btn .btn_1:hover {
  color: #fff !important;
}
.cs_modal .modal-body .social_login_btn .btn_1 i {
  margin-right: 10px;
}
.cs_modal .modal-body .social_login_btn .form-group:last-child .btn_1 {
  background-color: #4285F4;
  border: 1px solid #4285F4;
}
.cs_modal .modal-body .pass_forget_btn {
  color: #707070;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}
.cs_modal .modal-body .pass_forget_btn:hover {
  color: #4D4F5C;
}

.border_style {
  width: 100%;
  display: block;
  text-align: center;
  padding: 13px 0 17px;
  position: relative;
  z-index: 1;
}
.border_style span {
  padding: 0 25px;
  text-align: center;
  display: inline-block;
  background-color: #fff;
}
.border_style:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #BBC1C9;
  top: 24px;
  left: 0;
  z-index: -1;
}

p a {
  color: #7e7172;
}
p a:hover {
  color: #4D4F5C;
}

.cs_check_box {
  display: flex;
  margin-top: 20px;
}
.cs_check_box input, .cs_check_box label {
  line-height: 20px !important;
  height: auto !important;
  width: auto !important;
  max-width: auto;
}
.cs_check_box .common_checkbox {
  display: none;
}
.cs_check_box label {
  position: relative;
  padding-left: 34px;
  margin-bottom: 0;
}
.cs_check_box .common_checkbox + label {
  display: block;
  cursor: pointer;
  color: #7e7172;
}
.cs_check_box .common_checkbox + label:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #7e7172;
  border-radius: 5px;
  content: "";
}
.cs_check_box .common_checkbox:checked + label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 2px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.cs_check_box .common_checkbox {
  display: none !important;
}

/******** base color css ********/
.btn_1 {
  background-color: #3B76EF;
  border: 1px solid #3B76EF;
  color: #fff;
  display: inline-block;
  padding: 11px 23px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.btn_1 i {
  font-size: 15px;
  padding-right: 7px;
}
.btn_1:hover {
  background-color: #fff;
  border: 1px solid #3B76EF;
  color: #3B76EF;
}
.btn_1.light {
  background-color: rgba(77, 79, 92, 0.1);
  border: 1px solid transparent;
  color: #4D4F5C;
}
.btn_1.green {
  background-color: rgba(46, 201, 184, 0.1);
  border: 1px solid transparent;
  color: #2EC9B8;
}
.btn_1.sm {
  font-size: 12px;
  padding: 6px 15px;
}
.btn_1.big_btn {
  padding: 11px 36px;
}
.btn_1.form_big_btn_1 {
  padding: 16px 62px !important;
}
@media (max-width: 575.98px) {
  .btn_1.form_big_btn_1 {
    padding: 16px 30px !important;
  }
}
.btn_1.big_btn2 {
  padding: 11px 37px;
}

.btn_2 {
  color: #4D4F5C;
  border: 1px solid #4D4F5C;
  display: inline-block;
  padding: 11px 23px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.btn_2:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

.btn_3 {
  color: #222222;
  border: 1px solid #e4e8ec;
  display: inline-block;
  padding: 11px 27px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.btn_3.border_color_1 {
  border: 1px solid #eee1e2;
}
.btn_3:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

.btn_4 {
  display: inline-block;
  border: 1px solid #e4e8ec;
  border-radius: 5px;
  color: #7e7172;
  text-align: center;
  padding: 9px 15px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 300;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.btn_4:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

.btn_5 {
  border: 1px solid #fff;
  display: inline-block;
  padding: 16px 26px !important;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #fff !important;
  text-shadow: none !important;
  opacity: 1;
  color: #222222;
}
.btn_5:hover {
  background-color: #4D4F5C !important;
  border: 1px solid #4D4F5C !important;
  color: #fff;
  opacity: 1 !important;
}
.btn_5.small_btn {
  padding: 11px 26px !important;
}
@media (max-width: 575.98px) {
  .btn_5.small_btn {
    padding: 11px 19px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn_5.small_btn {
    padding: 11px 19px !important;
  }
}

.btn_6 {
  color: #222222;
  border: 1px solid #eee1e2;
  display: inline-block;
  padding: 11px 25px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.btn_6:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

.sm_btn {
  line-height: 15px;
  background-color: #fff;
  height: 34px;
  padding: 9px 20px;
  color: #212e40;
  width: 150px;
  text-transform: capitalize;
  border: 1px solid #eee1e2;
}

.view_archive_btn {
  border: 1px solid #e4e8ec;
  color: #7e7172;
  padding: 16px 30px;
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.view_archive_btn i {
  margin-right: 15px;
}
.view_archive_btn:hover {
  color: #4D4F5C;
  border: 1px solid #4D4F5C;
}

.white_btn {
  display: inline-block;
  color: #7e7172;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  transition: 0.3s;
  padding: 6px 20px;
  border-radius: 3px;
}
.white_btn:hover {
  background: #3B76EF;
  color: #fff;
}

.red_btn {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  background: #3B76EF;
  transition: 0.3s;
  padding: 6px 20px;
  border-radius: 3px;
}
.red_btn:hover {
  background: #fff;
  color: #7e7172;
}

.close.white_btn2 {
  border: 1px solid #fff;
  display: inline-block;
  padding: 16px 26px !important;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #fff !important;
  text-shadow: none !important;
  opacity: 1;
  color: #222222;
  margin-right: 10px;
}
.close.white_btn2:hover {
  background-color: #4D4F5C !important;
  border: 1px solid #4D4F5C !important;
  color: #fff;
  opacity: 1 !important;
}

.white_btn3 {
  border: 1px solid #fff;
  display: inline-block;
  padding: 11px 30px !important;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #fff !important;
  text-shadow: none !important;
  opacity: 1;
  color: #222222;
  margin-right: 10px;
}
.white_btn3:hover {
  background-color: #4D4F5C !important;
  border: 1px solid #4D4F5C !important;
  color: #fff;
  opacity: 1 !important;
}

.status_btn {
  display: inline-block;
  padding: 2px 15px;
  font-size: 11px;
  font-weight: 300;
  color: #fff !important;
  background: #05d34e;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  text-transform: capitalize;
  white-space: nowrap;
  min-width: 70px;
  text-align: center;
}
.status_btn.yellow_btn {
  background: #ffba00;
}
.status_btn:hover {
  color: #fff;
}

.dropdown .dropdown-toggle.lms_drop_1 {
  background: #fff;
  padding: 4px 15px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-align: left;
  color: #7e7172;
  display: inline-block;
  width: 102px;
  border: 0;
  border: 1px solid #eee1e2;
  position: relative;
  transition: 0.3s;
}
.dropdown .dropdown-toggle.lms_drop_1::after {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  top: 12px;
  color: #cec1c2;
  font-size: 12px;
  font-weight: 900;
  margin: 0;
  padding: 0;
  border: 0;
  border: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 14px;
}
.dropdown .dropdown-toggle.lms_drop_1.lms_drop_2 {
  min-width: 140px;
}
.dropdown.show .dropdown-toggle.lms_drop_1 {
  background: #3B76EF;
  padding: 4px 15px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-align: left;
  color: #7e7172;
  display: inline-block;
  width: 102px;
  border: 0;
  color: #fff;
  border: 1px solid #3B76EF;
}
.dropdown.show .dropdown-toggle.lms_drop_1::after {
  content: "\f0d8";
}
.dropdown .dropdown-menu {
  border: 0;
  border: 1px solid #eee1e2;
  padding: 12px 0 20px 0;
  margin: 0;
  border-radius: 0 !important;
}
.dropdown .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: 300;
  padding: 4px 20px;
  color: #7f7778;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  color: #3B76EF;
  background: transparent;
}
.dropdown .dropdown-menu.dropdown-menu-w140 {
  min-width: 140px;
}

.white_btn_line {
  white-space: nowrap;
  height: 50px;
  background: transparent;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  border: 0;
  padding: 0 19px;
  border-radius: 3px;
  border: 1px solid #eee1e2;
  color: #7e7172;
  transition: 0.3s;
}
.white_btn_line i {
  font-size: 13px;
  color: #7e7172;
  margin-right: 6px;
}
.white_btn_line:hover {
  background: #3B76EF;
  color: #fff;
  border: 1px solid #3B76EF;
}
.white_btn_line:hover i {
  color: #fff;
  transition: 0.3s;
}

.white_btn_line2 {
  white-space: nowrap;
  height: 30px;
  background: transparent;
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
  text-transform: capitalize;
  border: 0;
  border: 1px solid #eee1e2;
  color: #7e7172;
  transition: 0.3s;
  border-radius: 30px;
  line-height: 28px;
  padding: 0 18px;
}
.white_btn_line2:hover {
  background: #3B76EF;
  color: #fff !important;
  border: 1px solid #3B76EF;
}
.white_btn_line2:hover i {
  color: #fff;
  transition: 0.3s;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child::before {
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  content: "\e61a";
  font-family: "themify";
  font-size: 8px;
  line-height: 14px;
  font-weight: 600;
  background-color: #fef1f2;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.08), -6px -6px 12px rgb(255, 255, 255);
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {
  content: "\e622";
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.08), inset -6px -6px 12px rgb(255, 255, 255);
}

.CRM_dropdown.dropdown .dropdown-toggle {
  background: transparent;
  color: #415094;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #7c32ff;
  border-radius: 32px;
  padding: 5px 20px;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.3s;
}
.CRM_dropdown.dropdown .dropdown-toggle:after {
  content: "\e62a";
  font-family: "themify";
  border: none;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: medium;
  border-top: 0px;
  font-size: 10px;
  position: relative;
  top: 3px;
  left: 0;
  font-weight: 600;
  transition: 0.3s;
}
.CRM_dropdown.dropdown .dropdown-toggle:hover, .CRM_dropdown.dropdown .dropdown-toggle:focus {
  color: #fff;
  border: 1px solid transparent;
  box-shadow: none;
}
.CRM_dropdown.dropdown .dropdown-menu {
  border-radius: 5px 5px 10px 10px;
  border: 0px;
  padding: 15px 0px;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3);
}
.CRM_dropdown.dropdown .dropdown-menu .dropdown-item {
  color: #828bb2;
  text-align: right;
  font-size: 12px;
  padding: 4px 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
}
.CRM_dropdown.dropdown .dropdown-menu .dropdown-item:hover {
  color: #4D4F5C;
}

.dropdown-menu.option_width_8 {
  min-width: 150px;
}

/******** header css here *********/
.header_iner {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  padding: 30px;
  position: relative;
  margin: 27px 30px;
  border-radius: 7px;
}
@media (max-width: 575.98px) {
  .header_iner {
    justify-content: flex-end !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header_iner {
    justify-content: flex-end !important;
  }
}
@media (max-width: 991px) {
  .header_iner {
    padding: 20px;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_iner {
    margin: 0 10px;
  }
}
@media (max-width: 575.98px) {
  .header_iner {
    margin: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header_iner {
    margin: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .header_iner {
    margin-bottom: 20px;
  }
}
.header_iner .page_tittle h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: #222222;
  margin-bottom: 2px;
}
@media (max-width: 991px) {
  .header_iner .page_tittle h3 {
    margin-right: 15px;
  }
}
.header_iner .page_tittle .page_subtitle {
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  margin-bottom: 0;
}
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr {
  color: #7e7172;
}
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr.active_subcat {
  color: #fe1724;
  white-space: nowrap;
}
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr i {
  margin-left: 10px;
  margin-right: 7px;
  font-size: 12px;
}
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr svg {
  position: relative;
  margin: 0 7px 0 10px;
  top: -1px;
}
@media (max-width: 575.98px) {
  .header_iner .page_tittle .page_subtitle .page_subtitle_inenr svg {
    margin: 0 2px 0 2px;
  }
}
.header_iner .btn_1 {
  margin: 0 30px;
}
@media (max-width: 991px) {
  .header_iner .notification {
    margin: 0 10px;
  }
}
.header_iner .notification i {
  color: #707070;
  position: relative;
  font-size: 20px;
}
.header_iner .notification i:after {
  position: absolute;
  content: "";
  right: -1px;
  top: 0;
  height: 9px;
  width: 9px;
  background-color: #4D4F5C;
  border-radius: 50%;
}
@media (max-width: 767.98px) {
  .header_iner .serach_field-area {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_iner .serach_field-area {
    width: auto;
    left: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_iner .serach_field-area {
    width: auto;
    margin-right: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header_iner .serach_field-area {
    width: auto;
    margin-right: 70px;
  }
}
.header_iner .header_right .header_notification_warp {
  margin-right: 50px;
  margin-left: 40px;
}
@media (max-width: 575.98px) {
  .header_iner .header_right .header_notification_warp {
    margin-right: 25px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_iner .header_right .header_notification_warp {
    margin-right: 35px;
    margin-left: 25px;
  }
}
.header_iner .header_right .header_notification_warp li {
  display: inline-block;
}
.header_iner .header_right .header_notification_warp li:last-child a {
  margin-right: 0;
}
.header_iner .header_right .header_notification_warp li a {
  font-size: 20px;
  color: #929BB5;
  position: relative;
  margin-right: 29px;
  display: inline-block;
}
@media (max-width: 575.98px) {
  .header_iner .header_right .select_style {
    display: none !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header_iner .header_right .select_style {
    display: none !important;
  }
}
.header_iner .nice_Select {
  color: #929BB5;
}

.profile_info {
  position: relative;
}
.profile_info img {
  max-width: 77px;
  max-height: 77px;
  border-radius: 50%;
  border: 2px solid #A5ADC6;
  cursor: pointer;
}
@media (max-width: 991px) {
  .profile_info img {
    max-width: 30px;
  }
}
.profile_info .profile_info_iner {
  position: absolute;
  right: 0;
  background-color: #4D4F5C;
  text-align: right;
  width: 215px;
  padding: 30px 30px 20px;
  opacity: 0;
  visibility: hidden;
  top: 77px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.profile_info .profile_info_iner::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  top: -14px;
  right: 10px;
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent #4D4F5C transparent;
}
.profile_info .profile_info_iner a {
  display: block;
}
.profile_info .profile_info_iner p {
  font-size: 12px;
  font-weight: 300;
}
.profile_info .profile_info_iner h5 {
  font-size: 12px;
  color: #fff;
}
.profile_info:hover .profile_info_iner {
  opacity: 1;
  visibility: visible;
  top: 77px;
}
.profile_info .profile_info_details {
  margin-top: 20px;
  border-top: 1px solid #79838b;
  padding-top: 10px;
}
.profile_info .profile_info_details a {
  color: #fff;
  font-size: 13px;
  display: block;
  padding: 10px 0;
}
.profile_info .profile_info_details a:hover {
  color: #fff;
}
.profile_info .profile_info_details a:hover i {
  color: #fff;
}
.profile_info .profile_info_details i {
  color: #cccccc;
  font-size: 12px;
  margin-left: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sidebar_icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: auto;
  left: 20px;
}
@media (max-width: 575.98px) {
  .sidebar_icon {
    right: auto;
    left: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar_icon {
    right: auto;
    left: 20px;
  }
}
.sidebar_icon i {
  font-size: 20px;
}

/******** header css end *********/
.serach_field-area {
  width: 480px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .serach_field-area {
    width: 400px;
  }
}
.serach_field-area .search_inner input {
  color: #000;
  font-size: 17px;
  height: 60px;
  width: 100%;
  padding-left: 82px;
  border: 0;
  padding-right: 15px;
  border-bottom: 1px solid #F4F7FC;
  background: #F7FAFF;
  border-radius: 30px;
}
.serach_field-area .search_inner input::placeholder {
  font-size: 17px;
  font-weight: 500;
  color: #818E94;
  font-family: "Muli", sans-serif;
}
.serach_field-area .search_inner input:focus {
  outline: none;
}
.serach_field-area .search_inner button {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: transparent;
  font-size: 12px;
  border: 0;
  padding-left: 40px;
  padding-right: 11px;
}
.serach_field-area .search_inner button i {
  font-size: 14px;
  color: #818E94;
}

.serach_field_2 {
  width: 430px;
  position: relative;
  margin-right: 0px;
}
@media (max-width: 575.98px) {
  .serach_field_2 {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .serach_field_2 {
    width: 190px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .serach_field_2 {
    width: 230px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .serach_field_2 {
    width: 300px;
  }
}
.serach_field_2 .search_inner input {
  color: #000;
  font-size: 13px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 55px;
  border: 1px solid #144BF6;
  padding-right: 15px;
}
.serach_field_2 .search_inner input::placeholder {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
}
.serach_field_2 .search_inner input:focus {
  outline: none;
}
.serach_field_2 .search_inner button {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: transparent;
  font-size: 12px;
  border: 0;
  padding-left: 19px;
  padding-right: 11px;
}
.serach_field_2 .search_inner button i {
  font-size: 12px;
  color: #144BF6;
}
.serach_field_2 .search_inner button::before {
  position: absolute;
  width: 1px;
  height: 24px;
  content: "";
  background: #144BF6;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

table.dataTable {
  width: 100% !important;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0 !important;
}

/************ sidebar css here ************/
.sidebar {
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  padding-bottom: 50px;
  position: fixed;
  width: 270px;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  transition: 0.5s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar.hide_vertical_menu {
    left: -20%;
  }
}
@media (min-width: 1201px) {
  .sidebar.hide_vertical_menu {
    left: -270px;
  }
}
@media (max-width: 991px) {
  .sidebar {
    left: -280px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    top: 0;
    width: 280px;
    background: #fff;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar {
    width: 20%;
  }
}
.sidebar .logo {
  margin: 30px;
  background: #fff;
  padding: 33px 30px;
  border-radius: 7px;
}
.sidebar .logo img {
  width: 100%;
}
@media (max-width: 991px) {
  .sidebar .logo {
    padding: 30px;
  }
}
.sidebar .single_sidebar_tittle i {
  font-size: 18px;
  margin-right: 12px;
  color: #f8dbdd;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sidebar .single_sidebar_tittle a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  padding: 10px 25px 10px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.3s;
  position: relative;
  z-index: 0;
  border-top: 1px solid transparent;
  background: transparent;
}
.sidebar .single_sidebar_tittle a::before {
  position: absolute;
  right: 0;
  background: #4D4F5C;
  width: 0px;
  top: 0;
  bottom: 0;
  content: "";
  opacity: 0;
  transition: 0.3s;
  right: 0px;
  visibility: hidden;
}
.sidebar .single_sidebar_tittle a.current_active i {
  color: #4D4F5C;
}
.sidebar .single_sidebar_tittle a.current_active::before {
  opacity: 1;
  width: 3px;
  visibility: visible;
}
.sidebar .single_sidebar_tittle a:hover::before {
  opacity: 1;
  right: 0;
  visibility: visible;
  width: 3px;
}
.sidebar .single_sidebar_tittle a.normal_title {
  padding: 28px 25px 18px 40px;
  background: #fff7f8;
  border-top: 1px solid #eee1e2;
}
.sidebar .single_sidebar_tittle a.normal_title:after {
  position: absolute;
  right: 40px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f0d8";
  font-family: "Font Awesome 5 Free";
  color: #cec1c2;
  font-weight: 600;
  font-size: 14px;
}
.sidebar .single_sidebar_tittle a.normal_title.collapsed {
  padding: 10px 25px 10px 40px;
  background: #fff;
  border-top: 1px solid transparent;
}
.sidebar .single_sidebar_tittle a.normal_title.collapsed:after {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  color: #cec1c2;
  font-weight: 600;
  font-size: 14px;
}
.sidebar .single_sidebar_tittle:hover {
  background-color: #192434;
}
.sidebar .single_sidebar_tittle:hover i {
  color: #4D4F5C;
}
.sidebar .single_menu_item {
  padding-left: 36px;
  margin: 6px 0;
  line-height: 21px;
}
.sidebar .single_menu_item:hover {
  padding-left: 55px !important;
}
.sidebar .sidebar_iner ul li {
  list-style: none;
}
.sidebar .sidebar_iner ul li .submenu {
  background: #fff7f8;
  padding: 0px 0px 0px 70px !important;
  border-bottom: 1px solid #eee1e2;
}
.sidebar .sidebar_iner ul li .submenu li a {
  font-size: 13px;
  line-height: 36px;
  color: #7e7172;
  font-weight: 400;
}
.sidebar .sidebar_iner ul li .submenu li:last-child {
  padding-bottom: 18px;
}

@media (max-width: 991px) {
  .active_sidebar {
    left: 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }
}
.sidebar_close_icon i {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  position: absolute;
  right: 30px;
  font-size: 25px;
  top: 29px;
  cursor: pointer;
  color: #222222;
}

.sidebar_widget .white_box {
  padding: 30px;
}
@media (max-width: 991px) {
  .sidebar_widget .white_box {
    padding: 25px 15px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .sidebar_widget .white_box {
    padding: 15px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  .sidebar_widget .white_box {
    padding: 25px 15px;
  }
}
.sidebar_widget .white_box .btn_2 {
  margin-top: 25px;
  width: 100%;
  text-align: center;
  padding: 16px 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar_widget .white_box .btn_2 {
    padding: 12px 7px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .sidebar_widget .white_box .btn_1 {
    padding: 8px 10px;
    font-size: 11px;
  }
}
.sidebar_widget .date_range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0 18px;
}
.sidebar_widget .date_range .btn_3 {
  border-radius: 5px;
  font-size: 13px;
  font-weight: 300;
}
.sidebar_widget .date_range i {
  display: none;
}
.sidebar_widget .date_range .start_date, .sidebar_widget .date_range .end_date {
  width: 115px;
}
.sidebar_widget .date_range .start_date input, .sidebar_widget .date_range .end_date input {
  width: 100%;
  border: 1px solid #e4e8ec;
  color: #7e7172;
  font-size: 13px;
  padding: 11px 20px;
  border-radius: 5px;
  font-weight: 300;
}
.sidebar_widget .date_range .start_date ::placeholder, .sidebar_widget .date_range .end_date ::placeholder {
  color: #7e7172;
}

/************ sidebar css end ************/
.sidebar #sidebar_menu li a {
  font-size: 15px;
  font-weight: 400;
  color: #676B84;
  padding: 15px 25px 15px 30px;
  background: #fff;
  transition: 0.3s;
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 26px auto;
  grid-gap: 15px;
  align-items: center;
  position: relative;
  background: transparent;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar #sidebar_menu li a {
    padding: 18px 25px 18px 30px;
  }
}
.sidebar #sidebar_menu li a i {
  font-size: 15px;
  margin-right: 12px;
  color: #A5ADC6;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.sidebar #sidebar_menu li a:hover::before {
  width: 6px;
  opacity: 1;
  visibility: visible;
}
.sidebar #sidebar_menu li.mm-active > a {
  color: #1F253A;
}
.sidebar #sidebar_menu li.mm-active.metis_dropmenu > a {
  padding-top: 29px;
  padding-bottom: 30px;
  color: #A5ADC6;
}
.sidebar #sidebar_menu li.mm-active > a i {
  color: #A5ADC6;
}
.sidebar #sidebar_menu li.mm-active > a::before {
  opacity: 1;
  width: 6px;
  visibility: visible;
}
.sidebar #sidebar_menu li ul {
  padding: 0px 0px 0px 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar #sidebar_menu li ul {
    padding: 0px 0px 0px 55px;
  }
}
.sidebar #sidebar_menu li ul li:last-child a {
  padding-bottom: 30px;
}
.sidebar #sidebar_menu li ul li a {
  font-size: 13px;
  color: #676B84;
  font-weight: 400;
  padding: 0;
  white-space: nowrap;
  padding: 0;
  padding: 10px 0 !important;
}
.sidebar #sidebar_menu li ul li a::before {
  display: none;
}
.sidebar #sidebar_menu li ul li a.active {
  color: #1F253A;
}
.sidebar #sidebar_menu li ul li ul {
  padding-left: 5px;
}

.admin_profile_Wrap {
  margin-bottom: 30px;
}
.admin_profile_Wrap ul li {
  background: #fff7f8;
  border-top: 1px solid #eee1e2;
  border-bottom: 1px solid #eee1e2;
  padding: 30px 0 30px 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .admin_profile_Wrap ul li {
    padding: 30px 0 30px 20px;
  }
}
.admin_profile_Wrap ul li a {
  display: block;
}
.admin_profile_Wrap ul li a .admin_profile_inner .thumb img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext {
  margin-left: 15px;
}
.admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext h5 {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 1px;
  white-space: nowrap;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext h5 {
    font-size: 12px;
  }
}
.admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext span {
  display: block;
  font-size: 12px;
  color: #7e7172;
  font-weight: 300;
  white-space: nowrap;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext span {
    font-size: 11px;
  }
}
.admin_profile_Wrap ul li ul {
  padding-left: 70px;
}
.admin_profile_Wrap ul li ul li {
  padding: 0;
  border: 0;
}
.admin_profile_Wrap ul li ul li a {
  font-size: 13px;
  color: #7e7172;
  font-weight: 400;
  padding-bottom: 17px;
}
.admin_profile_Wrap ul li ul li:last-child a {
  padding-bottom: 0;
}
.admin_profile_Wrap ul li ul li:first-child a {
  margin-top: 29px;
}
.admin_profile_Wrap .metismenu .has-arrow::after {
  top: 38%;
}

.sidebar::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background-color: #ddd;
}
.sidebar::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 6px;
  background-color: #ddd;
}
.sidebar::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: #f8dbdd;
}

body::-webkit-scrollbar {
  width: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .metismenu .has-arrow::after {
    right: 25px;
  }
}

.body_content {
  display: flex;
}

.main-title h3 {
  font-family: "Muli", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #4D4F5C;
  line-height: 1.2;
}
@media only screen and (min-width: 1200px) and (max-width: 1480px) {
  .main-title h3 {
    font-size: 13px;
  }
}
.main-title span {
  font-size: 12px;
  font-weight: 400;
  color: #A3A0FB;
  display: block;
  margin-top: 6px;
}

.box_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}
@media (max-width: 575.98px) {
  .box_header.box_header_block {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
@media (max-width: 575.98px) {
  .box_header.box_header_block .main-title {
    margin-bottom: 15px;
  }
}
.box_header .title_info p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: rgb(163, 160, 251);
}

.legend_style li {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  margin-right: 10px;
}
.legend_style li span {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #4400eb;
  margin-right: 4px;
}
.legend_style li.inactive {
  opacity: 0.29;
}
.legend_style.legend_style_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}
.legend_style.legend_style_grid li {
  margin: 0 !important;
}

.title_btn {
  margin-bottom: 20px;
}
.title_btn ul li {
  display: inline-block;
}
.title_btn ul li a {
  display: inline-block;
  padding: 4px;
  background: #fff;
  font-size: 11px;
  text-transform: capitalize;
  color: #706F9A;
  border-radius: 5px;
  line-height: 25px;
  padding: 0 10px;
  transition: 0.3s;
}
.title_btn ul li a:hover {
  background: #A66DD4;
  color: #fff;
}
.title_btn ul li a.active {
  background: #A66DD4;
  color: #fff;
}

.radial_footer .radial_footer_inner .left_footer {
  padding-left: 10px;
  margin-bottom: 20px;
}
.radial_footer .radial_footer_inner .left_footer h5 {
  font-size: 13px;
  color: #43425D;
  font-weight: 500;
  margin-bottom: 0px;
  position: relative;
}
.radial_footer .radial_footer_inner .left_footer h5 span {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #A4A1FB;
  display: inline-block;
  position: absolute;
  left: -15px;
  top: 6px;
}
.radial_footer .radial_footer_inner .left_footer p {
  font-size: 12px;
  color: #4D4F5C;
  opacity: 0.5;
  font-weight: 400;
  margin-bottom: 0;
}
.radial_footer .radial_bottom {
  border-top: 1px solid #EFEFEF;
  text-align: center;
  padding-top: 15px;
  margin-top: 4px;
}
.radial_footer .radial_bottom p a {
  font-size: 12px;
  color: #3B86FF;
  font-weight: 600;
}

/******** main content css here *********/
.main_content {
  padding-left: 270px;
  width: 100%;
  padding-top: 0px !important;
  transition: 0.5s;
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;
  overflow: hidden;
}
.main_content.main_content_padding_hide {
  padding-left: 0px;
  padding-top: 100px !important;
}
@media (max-width: 991px) {
  .main_content {
    padding: 90px 0 100px 0;
  }
}
@media (max-width: 575.98px) {
  .main_content {
    padding: 120px 0 100px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main_content {
    padding: 120px 0 100px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main_content {
    padding: 120px 0 100px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .main_content {
    padding-left: 20%;
  }
}
.main_content .main_content_iner {
  min-height: 68vh;
  transition: 0.5s;
  margin: 30px;
}
@media (max-width: 991px) {
  .main_content .main_content_iner {
    margin: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .main_content .main_content_iner.main_content_iner_padding {
    padding: 0 30px 0 30px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  .main_content .main_content_iner.main_content_iner_padding {
    padding: 0 60px 0 60px;
  }
}
@media (min-width: 1680px) {
  .main_content .main_content_iner.main_content_iner_padding {
    padding: 0 135px 0 135px;
  }
}
@media (max-width: 575.98px) {
  .main_content .main_content_iner {
    margin-bottom: 20px;
  }
}
.address_book_hint p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 8px;
}

.single_element h4 {
  margin-bottom: 30px;
}
.single_element .quick_activity_wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 50px;
}
@media (max-width: 575.98px) {
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
.single_element .single_quick_activity {
  background-color: #fff;
  border-radius: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 41px 30px;
  position: relative;
  background: #3B76EF;
}
.single_element .single_quick_activity:nth-child(2) {
  background: #63C7FF;
}
.single_element .single_quick_activity:nth-child(3) {
  background: #A66DD4;
}
.single_element .single_quick_activity:nth-child(4) {
  background: #6DD4B1;
}
.single_element .single_quick_activity::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: cover;
}
@media (max-width: 575.98px) {
  .single_element .single_quick_activity {
    padding: 20px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_element .single_quick_activity {
    padding: 20px 15px;
  }
}
.single_element .single_quick_activity h4 {
  font-size: 17px;
  margin-bottom: 0;
  font-weight: 700;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #fff;
  margin-bottom: 12px;
}
.single_element .single_quick_activity h3 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
  font-weight: 900;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #fff;
  margin-bottom: 7px;
}
.single_element .single_quick_activity p {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}
.single_element .single_quick_activity:hover {
  background: #3B76EF;
}
.single_element .single_quick_activity:hover::before {
  background-image: url(../img/diamond.png);
}
.single_element .single_quick_activity:hover .quick_activity_icon {
  background-color: rgba(255, 247, 248, 0.2);
}
.single_element .single_quick_activity:hover .quick_activity_icon i {
  color: #ffffff;
}

/******** main content css end *********/
.dashboard_part .notification_tab {
  padding: 27px 25px;
  background-color: #F5ECEC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard_part .notification_tab p {
  font-size: 16px;
  color: #222222;
}
.dashboard_part .notification_tab p a {
  color: #4D4F5C;
}
.dashboard_part .hide_icon {
  cursor: pointer;
}
.dashboard_part .hide_icon i {
  color: #4D4F5C;
  font-size: 10px;
}

.white_box {
  padding: 30px;
  background-color: rgba(247, 250, 255, 0.7);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.white_box.box_border {
  border: 1px solid rgba(202, 206, 213, 0.7);
}
@media (max-width: 575.98px) {
  .white_box {
    padding: 30px 25px;
  }
}
.white_box .white_box_tittle h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .white_box .white_box_tittle h4 {
    margin-bottom: 15px;
  }
}

.white_box2 {
  padding: 40px 40px 25px 40px;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
@media (max-width: 575.98px) {
  .white_box2 {
    padding: 30px 25px;
  }
}
.white_box2 .white_box_tittle h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .white_box2 .white_box_tittle h4 {
    margin-bottom: 15px;
  }
}

.chart_box .white_box_tittle {
  margin-bottom: 20px;
}
.chart_box .white_box_tittle h4 {
  margin-bottom: 2px;
}
@media (max-width: 991px) {
  .chart_box .white_box_tittle h4 {
    margin-bottom: 15px;
  }
}
.chart_box p {
  margin-bottom: 29px;
  color: #707070;
}

.chart_box label {
  margin-bottom: 0;
}
.chart_box .b {
  display: block !important;
}
.chart_box .toggle {
  position: relative;
  width: 44px;
  height: 22px;
  border-radius: 50px;
  background-color: #222222;
  border: 1px solid #222222;
  overflow: hidden;
}
.chart_box .check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}
.chart_box .check:checked ~ .switch {
  right: 5px;
  left: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0.08s, 0s;
}
.chart_box .switch {
  position: absolute;
  left: 5px;
  top: 3px;
  bottom: 2px;
  right: 57.5%;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.08s;
  height: 13px;
  width: 13px;
  padding: 6px !important;
  margin-right: 0;
}
.chart_box .switch:after {
  display: none;
}
.chart_box .hide {
  display: none !important;
}

.QA_section .QA_table .table {
  background: #FFFFFF;
  box-shadow: 0px 10px 15px rgba(235, 215, 241, 0.22);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 35px;
  padding-top: 20px;
  padding-bottom: 40px;
}
.QA_section .QA_table th, .QA_section .QA_table td {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.9;
  color: #222222;
  padding: 16px 30px;
  vertical-align: middle;
}
.QA_section .QA_table .question_content {
  color: #7e7172;
  font-size: 13px;
}
.QA_section .QA_table .question_content:hover {
  color: #4D4F5C;
}
.QA_section .QA_table thead th {
  white-space: nowrap;
  border-bottom: 1px solid rgba(130, 139, 178, 0.3) !important;
}
.QA_section .QA_table tbody th {
  font-size: 14px;
  color: #415094;
  font-weight: 400 !important;
}
.QA_section .QA_table tbody th, .QA_section .QA_table tbody td {
  color: #7e7172;
  font-size: 14px;
  color: #828BB2;
  font-weight: 400;
  border-bottom: 1px solid rgba(130, 139, 178, 0.15);
}
.QA_section .QA_table tbody th a, .QA_section .QA_table tbody td a {
  font-size: 14px;
  font-weight: 400;
  color: #828BB2;
}
.QA_section .QA_table tbody th a:hover, .QA_section .QA_table tbody td a:hover {
  color: #7C32FF;
}
.QA_section .QA_table tbody th.pending, .QA_section .QA_table tbody td.pending {
  color: #E09079;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.QA_section .QA_table tbody th.priority_hight, .QA_section .QA_table tbody td.priority_hight {
  font-size: 14px;
  color: #D7598F;
  font-weight: 400;
}
.QA_section .QA_table tbody tr {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.QA_section .QA_table tbody tr:hover {
  background: #fff;
}
.QA_section .QA_table .view_btn {
  color: #4D4F5C;
}
.QA_section .QA_table .view_btn:hover {
  text-decoration: underline;
}
.QA_section .QA_table .table {
  margin-bottom: 0 !important;
}
.QA_section .QA_table .table thead th {
  border-bottom: 0 solid transparent;
  background-color: #fff;
  padding: 17px 30px;
  line-height: 16px;
  border: 0px solid transparent;
  font-size: 12px;
  font-weight: 400;
  color: #4D4F5C;
  white-space: nowrap;
  text-transform: uppercase;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .chart_box_1 .box_header {
    flex-direction: column;
  }
}
.chart_box_1 .box_header .box_header_left {
  flex-basis: 50%;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .chart_box_1 .box_header .box_header_left {
    flex-basis: 55%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .chart_box_1 .box_header .box_header_left {
    flex-basis: 55%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .chart_box_1 .box_header .box_header_left {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}
.chart_box_1 .box_header .box_header_left ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.chart_box_1 .box_header .box_header_left ul li {
  display: inline-block;
  text-align: center;
}
@media (max-width: 575.98px) {
  .chart_box_1 .box_header .box_header_left ul li {
    flex: 50% 0 0;
    margin-bottom: 20px;
  }
}
.chart_box_1 .box_header .box_header_left ul li h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
}
.chart_box_1 .box_header .box_header_left ul li p {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0;
}
.chart_box_1 .box_header .box_header_right .legend_circle ul li {
  color: #828BB2;
  font-size: 12px;
  font-weight: 400;
  margin-right: 30px;
  display: inline-block;
}
@media (max-width: 575.98px) {
  .chart_box_1 .box_header .box_header_right .legend_circle ul li {
    margin-right: 14px;
  }
}
.chart_box_1 .box_header .box_header_right .legend_circle ul li:last-child {
  margin-right: 0;
}
.chart_box_1 .box_header .box_header_right .legend_circle ul li span {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.form_style .form-group label, .form_style .form-group p {
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 13px;
  color: #7e7172;
}
.form_style .form-group input {
  border: 1px solid #e4e8ec;
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  padding: 15px 25px;
  background-color: #fef1f2;
  height: auto;
  line-height: 18px;
}

.revenue_part .btn_2 {
  padding: 8px 13px;
  margin-left: 30px;
}
.revenue_part .courses_price .btn_1 {
  padding: 13px 32px;
  line-height: 16px;
  height: auto;
  width: auto;
}
.revenue_part .courses_details span {
  font-size: 16px;
  font-weight: 600;
  color: #7e7172;
  margin-top: 3px;
}
.revenue_part .courses_duration {
  position: relative;
  padding-left: 25px;
}
.revenue_part .courses_duration h6 {
  font-size: 14px;
  margin-bottom: 8px;
}
.revenue_part .courses_duration h6 i {
  color: #4D4F5C;
  position: absolute;
  left: 0;
  top: 2px;
}

.payment_methord h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.payment_methord .payment_mathord_content {
  background-color: #fff;
  padding: 30px;
}
.payment_methord .payment_mathord_content h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 28px 0 7px;
}
.payment_methord .payment_mathord_content .btn_1 {
  margin-top: 28px;
}
.payment_methord .nav-tabs {
  border-bottom: 0 solid transparent;
}
.payment_methord .payment_methord_logo {
  display: flex;
  align-items: center;
}
.payment_methord .payment_methord_logo .single_logo_iner {
  width: 188px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #BBC1C9;
  border-left: 0px solid transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.payment_methord .payment_methord_logo .single_logo_iner:first-child {
  border-left: 1px solid #BBC1C9;
  border-radius: 5px 0 0 5px;
}
.payment_methord .payment_methord_logo .single_logo_iner:last-child {
  border-radius: 0 5px 5px 0;
}
.payment_methord .payment_methord_logo .single_logo_iner img {
  max-width: 100px;
}
.payment_methord .payment_methord_logo .single_logo_iner.active {
  border: 1px solid #4D4F5C !important;
}
.form_group {
  margin-top: 24px;
}
.form_group input {
  width: 100%;
  background-color: #fef1f2;
  border-radius: 5px;
  border: 1px solid #F1F3F5;
  padding: 12px 20px;
}
.form_group .single_form_item label {
  width: 100%;
  display: block;
}
.form_group .single_form_item input {
  width: auto;
  display: inline-block;
  padding: 12px 20px;
  max-width: 85px;
  color: #9C9C9C;
}
.form_group #CVV {
  max-width: 118px !important;
}
.form_group .nice_Select {
  background-color: #fef1f2;
  margin-right: 10px;
  color: #9C9C9C;
}

.checkbox label {
  margin-bottom: 0;
  margin-top: 25px;
  color: #9C9C9C;
  margin-left: 8px;
}

/************** footer css ****************/
.footer_part {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 6px;
  background: transparent;
  padding-left: 270px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .footer_part {
    padding-left: 0 !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer_part {
    padding-left: 20%;
  }
}
.footer_part p {
  font-size: 14px;
  font-weight: 300;
  color: #828BB2;
}
.footer_part p span {
  margin: 0 10px;
}
.footer_part p a {
  color: #3B76EF;
}
.footer_part p a:hover {
  text-decoration: none;
}
.footer_part .footer_iner {
  padding: 0 15px;
}
@media (max-width: 575.98px) {
  .footer_part .footer_iner {
    text-align: center;
  }
}

.footer_links {
  padding: 0 15px;
}
@media (max-width: 575.98px) {
  .footer_links ul {
    justify-content: center !important;
    margin-top: 15px;
  }
}
.footer_links ul li a {
  color: #3B76EF;
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
  text-transform: capitalize;
}
.footer_links ul li:first-child a {
  margin-left: 0;
}

/************** footer css end ****************/
/********* breadcrumd css here **********/
.breadcrumb_content {
  background-color: #fff;
  padding: 19px 30px;
  line-height: 22px;
}
@media (max-width: 991px) {
  .breadcrumb_content {
    padding: 25px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb_content {
    display: block !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .breadcrumb_content {
    display: block !important;
  }
}
.breadcrumb_content .active {
  color: #4D4F5C;
}
.breadcrumb_content h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .breadcrumb_content h2 {
    margin-bottom: 15px;
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb_content h2 {
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .breadcrumb_content h2 {
    margin-bottom: 15px;
  }
}
.breadcrumb_content .sa_breadcrumb_iner a {
  position: relative;
  color: #7e7172;
  padding-left: 30px;
}
.breadcrumb_content .sa_breadcrumb_iner a:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 3px;
  clip-path: polygon(0 0, 100% 55%, 0 100%);
  background-color: #7e7172;
  width: 10px;
  height: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.breadcrumb_content .sa_breadcrumb_iner a:first-child {
  padding-left: 0;
}
.breadcrumb_content .sa_breadcrumb_iner a:first-child:after {
  display: none;
}
.breadcrumb_content .sa_breadcrumb_iner a:hover {
  color: #4D4F5C;
}
.breadcrumb_content .sa_breadcrumb_iner a:hover:after {
  background-color: #4D4F5C;
}
.breadcrumb_content .sa_breadcrumb_iner a:last-child {
  color: #4D4F5C;
}
.breadcrumb_content .sa_breadcrumb_iner a:last-child:after {
  background-color: #4D4F5C;
}

/********* breadcrumd css end **********/
#morris_bar {
  height: 300px;
}

#morris_bar_bold {
  height: 300px;
}

tspan {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  text-transform: capitalize;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
  font-size: 13px;
  color: #fff !important;
  font-size: 12px;
  font-weight: 500;
  font-family: "Muli", sans-serif;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  line-height: 12px;
  font-family: "Muli", sans-serif;
}

.morris-hover.morris-default-style {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-align: center;
  background: #000 !important;
  border: 0;
  padding: 7px 22px 5px 22px !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
}
.morris-hover.morris-default-style span {
  font-weight: bold;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  line-height: 12px;
  font-family: "Muli", sans-serif;
}

canvas#highlights {
  height: 300px !important;
  overflow: hidden;
}

.lms_category_wrap {
  margin-bottom: 50px;
}
.lms_category_wrap .category_header {
  background: #fef1f2;
  border-radius: 5px;
  padding: 17px 30px;
}
.lms_category_wrap .category_header .category_left h4 {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  margin-bottom: 0;
}
.lms_category_wrap .category_header .category_left p {
  color: #7e7172;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .lms_category_wrap .category_header .category_right {
    margin-top: 15px;
  }
}
.lms_category_wrap ul li {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 15px 30px;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #eee1e2;
  transition: 0.3s;
}
@media (max-width: 575.98px) {
  .lms_category_wrap ul li {
    padding: 15px 20px;
  }
}
.lms_category_wrap ul li .category_info p {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
}
.lms_category_wrap ul li:hover {
  background: #fff7f8;
}

.note-editor.note-frame {
  border: 1px solid #eee1e2;
}
.note-editor.note-frame .note-popover .note-popover-content, .note-editor.note-frame .note-toolbar {
  padding: 4px 15px 8px 15px;
  margin: 0;
  border-radius: 5px 5px 0 0;
  background-color: #fff7f8;
}
.note-editor.note-frame .note-btn i, .note-editor.note-frame .note-current-fontname {
  color: #7e7172;
  font-size: 12px;
}
.note-editor.note-frame .note-btn {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  color: #7e7172;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}
.note-editor.note-frame .note-btn:hover {
  color: #fff;
  background: #fe1724;
}
.note-editor.note-frame .note-btn:hover i {
  color: #fff;
  background: #fe1724;
}
.note-editor.note-frame .note-btn .note-current-fontname:hover {
  color: #fff;
}
.note-editor.note-frame .show .dropdown-toggle::after {
  transform: rotate(180deg);
  color: red;
}
.note-editor.note-frame .note-icon-font.note-recent-color {
  background: transparent !important;
  color: #7e7172 !important;
}
.note-editor.note-frame .show .dropdown-toggle::after {
  color: #7e7172 !important;
}
.note-editor.note-frame .note-btn:hover span {
  color: #fff !important;
}
.note-editor.note-frame .note-btn:hover i {
  color: #fff !important;
}
.note-editor.note-frame .note-editing-area .note-editable {
  background-color: #fff;
}

.note-editor.note-frame .note-placeholder {
  padding: 18px 25px 0 25px;
  font-size: 14px;
  color: #7e7172;
  line-height: 28px;
  font-weight: 300;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .lms_sm_block {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .lms_xs_small_btn a {
    padding: 11px 20px;
  }
}

@media (max-width: 575.98px) {
  .mb_xs_20px {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mb_sm_20px {
    margin-bottom: 20px;
  }
}

.lms_supper_text {
  vertical-align: super !important;
}

.gj-picker-md {
  border: 1px solid #fef1f2;
  padding: 10px;
}

.gj-picker-md table tr td.today div {
  color: #fff;
  background: #3B76EF;
  border-radius: 50%;
}

.gj-picker-md table tr td.gj-cursor-pointer div:hover {
  border-radius: 50%;
  background: #3B76EF;
  color: #fff;
}

.gj-picker-md div[role=navigator] div:first-child, .gj-picker-md div[role=navigator] div:last-child {
  max-width: 42px;
  background: #fef1f2;
  color: #000;
  border-radius: 50%;
  font-size: 14px;
  line-height: 44px;
  height: 42px;
  font-weight: 900;
}
.gj-picker-md div[role=navigator] div:first-child i, .gj-picker-md div[role=navigator] div:last-child i {
  font-weight: 900;
  font-size: 14px;
}

.dropdown-menu.show .dropdown-toggle.lms_drop_1::after {
  color: #fff !important;
}
.dropdown-menu.option_width {
  min-width: 102px;
}

.dropdown.show .dropdown-toggle.lms_drop_1::after {
  color: #fff;
}

.lms_common_header .lms_common_title h4 {
  margin-bottom: 3px;
}
.lms_common_header .lms_common_title p {
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  margin-bottom: 0;
}

.common_line_separator {
  display: inline-block;
  width: 2px;
  height: 11px;
  background: #b9b3b3;
  margin: 0 7px 0 7px;
}

.h-120px {
  height: 120px !important;
}

.h-260px {
  height: 260px !important;
}
@media (max-width: 991px) {
  .h-260px {
    height: 160px !important;
  }
}

.h-230px {
  height: 230px !important;
}
@media (max-width: 991px) {
  .h-230px {
    height: 160px !important;
  }
}

.discription_list_wrap p {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  line-height: 26px;
  margin-bottom: 17px;
}
.discription_list_wrap h5 {
  font-size: 12px;
  font-weight: 400;
  color: #222222;
  margin-top: 19px;
  margin-bottom: 14px;
}

.common_list_style li {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  line-height: 26px;
  position: relative;
  padding-left: 15px;
}
.common_list_style li:before {
  position: absolute;
  left: 0;
  top: 10px;
  width: 3px;
  height: 3px;
  background: #7e7172;
  content: "";
  border-radius: 50%;
}

.result_modal_table_form .modal-body {
  margin: 30px 0;
  padding: 120px 100px 104px 100px !important;
}
@media (max-width: 991px) {
  .result_modal_table_form .modal-body {
    padding: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .result_modal_table_form .modal-body {
    padding: 50px 50px !important;
  }
}

.result_modal_table_form .table thead th {
  border-bottom: 0 solid transparent;
  background-color: #fef1f2;
  padding: 17px 30px;
  line-height: 16px;
  border: 0px solid transparent;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #222222;
  white-space: nowrap;
}
.result_modal_table_form .table tbody th, .result_modal_table_form .table tbody td {
  color: #7e7172;
  font-size: 13px !important;
  color: #7e7172;
  font-weight: 300;
  border-bottom: 1px solid #eee1e2;
  padding: 10px 25px !important;
}
.result_modal_table_form .result_header_modal {
  margin-bottom: 45px;
}
.result_modal_table_form .result_header_modal h2 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 15px;
}
@media (max-width: 575.98px) {
  .result_modal_table_form .result_header_modal h2 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .result_modal_table_form .result_header_modal h2 {
    font-size: 20px;
  }
}
.result_modal_table_form .result_header_modal p {
  font-size: 14px;
  font-weight: 300;
  color: #7e7172;
  line-height: 26px;
}
.result_modal_table_form .result_header_modal .passed_text {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-top: 29px;
  margin-bottom: 35px;
}
@media (max-width: 575.98px) {
  .result_modal_table_form .result_header_modal .passed_text {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .result_modal_table_form .result_header_modal .passed_text {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .result_modal_table_form .result_header_modal .passed_text {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.student_list_img img {
  width: 50px;
  height: 50px;
}

.common_date_picker input {
  width: 100%;
  border: 1px solid #eee1e2;
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #7e7172;
  padding: 0px 25px;
  font-weight: 300;
}

.website_settings_wrap .modal-body {
  padding: 0 !important;
}

.input_form_persent {
  position: relative;
}
.input_form_persent:before {
  content: "\f295";
  border-bottom: 0;
  border-right: 0;
  display: block;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: auto;
  right: 25px;
  border: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #cec1c2;
  transform: translateY(-50%);
  font-size: 13px;
}

.tagInput_field {
  margin-bottom: 21px;
}
.tagInput_field label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}
.tagInput_field .bootstrap-tagsinput {
  text-align: left;
  border: 1px solid #eee1e2;
  padding: 9px 25px 4px 25px;
  box-shadow: none;
  border-radius: 3px;
  min-height: 50px;
}
.tagInput_field .bootstrap-tagsinput input {
  border: none !important;
  width: auto !important;
  display: inline-block !important;
  background-color: transparent !important;
  height: 0;
  margin: 0;
  min-width: 20px;
  padding: 7px !important;
}
.tagInput_field .bootstrap-tagsinput .badge {
  margin: 0 5px 5px 0;
  height: 30px;
  line-height: 30px;
  padding: 0 30px 0 20px;
  font-size: 12px;
  color: #3B76EF;
  font-weight: 400;
  background: white;
  border: 1px solid #3B76EF;
  border-radius: 30px;
  position: relative;
}
.tagInput_field .bootstrap-tagsinput .badge [data-role=remove]::after {
  font-size: 10px;
  font-family: "themify";
  content: "\e646";
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 0px;
  background: transparent;
  margin-left: 5px;
  padding: 0;
  border: 1px solid transparent;
  padding: 2px;
}
.tagInput_field .bootstrap-tagsinput .badge [data-role=remove]:hover::after {
  background: #3B76EF;
  color: #fff;
  border-color: #3B76EF;
}
.tagInput_field span.badge.badge-info span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.custom_striped_progressbar .progress {
  height: 24px;
  background: transparent;
  border-radius: 30px;
  width: 256px;
}
.custom_striped_progressbar .progress-bar {
  position: relative;
  border-radius: 30px;
  text-align: left;
  font-size: 15px;
  padding-left: 15px;
  color: #fff;
  font-weight: 300;
  background: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /*        &.green_bar{
              background-image: url(../img/progress/progress-bg.png);
              background-size: cover;
          }
          &.yellow_bar{
              // background: #ffba00;
              background-image: url(../img/progress/design-progress-yellow.png);
              background-size: cover;
          }
          &.red_bar{
              background-image: url(../img/progress/design-progress-red.png);
              background-size: cover;
          }*/
}

.custom_lms_radio_2 label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  margin-left: 0;
  padding-bottom: 15px;
  margin-top: 0;
}
.custom_lms_radio_2 label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 1px solid #eee2e3;
  border-radius: 50%;
  vertical-align: -6px;
  margin-right: 15px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}
.custom_lms_radio_2 label span:before {
  content: "";
  width: 0px;
  height: 1px;
  border-radius: 2px;
  background: #3B76EF;
  position: absolute;
  transform: rotate(38deg);
  top: 13px;
  left: 9px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
  top: 9.69px;
  left: 4.99px;
  border-radius: 0;
}
.custom_lms_radio_2 label span:after {
  content: "";
  width: 0;
  height: 1px;
  border-radius: 2px;
  background: #3B76EF;
  position: absolute;
  transform: rotate(305deg);
  top: 12.8px;
  left: 7.48px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
  border-radius: 0;
}
.custom_lms_radio_2 label:hover span:before {
  width: 4px;
  transition: width 100ms ease;
}
.custom_lms_radio_2 label:hover span:after {
  width: 10px;
  transition: width 150ms ease 100ms;
}
.custom_lms_radio_2 input[type=radio] {
  display: none;
}
.custom_lms_radio_2 input[type=radio]:checked + label span {
  background-color: #3B76EF;
  transform: scale(1);
  border-color: #3B76EF;
}
.custom_lms_radio_2 input[type=radio]:checked + label span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.custom_lms_radio_2 input[type=radio]:checked + label span:before {
  width: 4px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.custom_lms_radio_2 input[type=radio]:checked + label:hover span {
  background-color: #3B76EF;
  transform: scale(1.25);
  border-color: #3B76EF;
}
.custom_lms_radio_2 input[type=radio]:checked + label:hover span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.custom_lms_radio_2 input[type=radio]:checked + label:hover span:before {
  width: 4px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.import_topic_wrapper.modal_btn {
  display: grid !important;
  grid-gap: 10px;
  grid-template-columns: 3fr 9fr;
  align-items: end;
}
.import_topic_wrapper.modal_btn .btn_1 {
  margin: 0 !important;
}
@media (max-width: 575.98px) {
  .import_topic_wrapper.modal_btn {
    grid-template-columns: repeat(1, 1fr);
  }
}

.indvisual_form .btn_1.big_btn {
  padding: 16px 36px;
}
.indvisual_form .chose_thumb_title {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}
.indvisual_form .input_wrap label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}
.indvisual_form .input_wrap textarea {
  height: 100px;
  padding: 0;
  line-height: 28px;
  padding: 13px 25px;
}
.indvisual_form .input_wrap textarea.max_textarea {
  height: 290px;
}
.indvisual_form .nice_Select, .indvisual_form input, .indvisual_form textarea {
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  border: 1px solid #eee1e2;
  padding: 10px 25px;
  color: #7e7172;
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  display: block;
  margin-bottom: 21px;
  font-weight: 300;
  border-radius: 3px;
}
.indvisual_form .nice_Select::placeholder, .indvisual_form input::placeholder, .indvisual_form textarea::placeholder {
  color: #7e7172;
  font-weight: 300;
  opacity: 1;
}
.indvisual_form .nice_Select {
  line-height: 30px;
}
.indvisual_form .nice_Select :after {
  left: 22px;
}
.indvisual_form .nice-select.nice_Select2 {
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #eee1e2;
  padding: 0 37px 0 20px;
  color: #7e7172;
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  display: block;
  margin-bottom: 21px;
  font-weight: 300;
  border-radius: 3px;
  margin: 0;
  width: auto;
}
.indvisual_form .nice-select.nice_Select2::after {
  top: 20%;
}

.assign_list li {
  display: inline-block;
  margin-right: 5px;
}
.assign_list li:last-child {
  margin-right: 0;
}
.assign_list li a img {
  width: 100%;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 23px;
  height: 23px;
  transform: translateY(0px);
}
.assign_list li a img:hover {
  transform: translateY(-2px);
}

.dataTables_paginate {
  margin-top: 0;
  margin-top: 30px;
}
.dataTables_paginate a {
  width: 32px;
  height: 32px;
  background: #FFFFFF !important;
  border-radius: 3px !important;
  text-align: center !important;
  line-height: 32px;
  padding: 0 !important;
  margin: 0 !important;
  margin: 0 5px !important;
}
.dataTables_paginate a.current {
  background: #3B76EF !important;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.3) !important;
  border: 0 !important;
  color: #fff !important;
}
.dataTables_paginate a:hover {
  background: #3B76EF !important;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.3);
  border: 0 !important;
  color: #fff !important;
}
.dataTables_paginate a:hover i {
  color: #fff !important;
}
.dataTables_paginate a i {
  line-height: 32px;
  font-size: 12px;
  color: #415094;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #fff !important;
  border: 0;
}

#DataTables_Table_0_info {
  font-size: 14px;
  color: #415094;
  font-weight: 400;
  margin-top: 35px;
  padding-top: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
  border: 1px solid #3B76EF;
  background-color: #3B76EF !important;
}

.min_400 {
  height: calc(100% - 30px);
}

.min_430 {
  height: calc(100% - 30px);
}

.mb-55 {
  margin-bottom: 55px;
}

.profile_box_1 {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
}
.profile_box_1 .profile-picture {
  position: absolute;
  top: 240px;
  left: 50%;
  margin-left: -55px;
}
.profile_box_1 .profile-picture img {
  border: 5px solid #fff;
  border-radius: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  width: 100px;
  height: 100px;
}
.profile_box_1 .profile-cover-image {
  height: 300px;
  overflow: hidden;
}
.profile_box_1 .profile-cover-image img {
  width: 100%;
}
.profile_box_1 .profile-content {
  padding: 40px 20px;
  text-align: center;
}
.profile_box_1 .profile-content h1 {
  font-weight: normal;
  margin-bottom: 0.2em;
}
.profile_box_1 .profile-content p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #d2d2d2;
}
.profile_box_1 .profile-content .socials a {
  color: #d2d2d2;
  margin: 0 15px;
  font-size: 1.6em;
  transform: translateY(4px);
}
.profile_box_1 .profile-content .socials a:hover {
  color: #aaa;
  transform: translateY(0);
}

.card-profile {
  background-color: #e6e5e1;
  border-radius: 0;
  border: 0;
  box-shadow: 1em 1em 2em rgba(0, 0, 0, 0.2);
}
.card-profile .card-img-top {
  border-radius: 0;
}
.card-profile .card-img-profile {
  max-width: 100%;
  border-radius: 50%;
  margin-top: -95px;
  margin-bottom: 35px;
  border: 5px solid #e6e5e1;
}
.card-profile .card-title {
  margin-bottom: 50px;
}
.card-profile .card-title small {
  display: block;
  font-size: 0.6em;
  margin-top: 0.2em;
}
.card-profile .card-links {
  margin-bottom: 25px;
}
.card-profile .card-links .fa {
  margin: 0 1em;
  font-size: 1.6em;
}
.card-profile .card-links .fa:focus, .card-profile .card-links .fa:hover {
  text-decoration: none;
}
.card-profile .card-links .fa.fa-dribbble {
  color: #ea4b89;
  font-weight: bold;
}
.card-profile .card-links .fa.fa-dribbble:hover {
  color: #e51d6b;
}
.card-profile .card-links .fa.fa-twitter {
  color: #68aade;
}
.card-profile .card-links .fa.fa-twitter:hover {
  color: #3e92d5;
}
.card-profile .card-links .fa.fa-facebook {
  color: #3b5999;
}
.card-profile .card-links .fa.fa-facebook:hover {
  color: #2d4474;
}

.profile-card-4 {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px #222;
}

.profile-card-4:hover .profile-img img {
  transform: scale(1.2);
}

.profile-card-4 .profile-img img {
  width: 100%;
  height: auto;
  transition: transform 1s;
}

.profile-card-4 .profile-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40%;
  border: 3px solid #333;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: scale(1, 1);
  transition: all 0.5s linear;
}

.profile-card-4 .profile-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 40%;
  border: 3px solid #222;
  border-left: 3px solid transparent;
  border-top: 3px solid transparent;
  transition: all 0.5s linear;
}

.profile-card-4:hover .profile-content:before {
  top: 20px;
  left: 20px;
}

.profile-card-4:hover .profile-content:after {
  bottom: 20px;
  right: 20px;
}

.profile-card-4 .profile-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50% 4%;
  text-align: center;
}

.profile-card-4 .profile-content:hover {
  background-color: rgba(255, 43, 131, 0.5);
}

.profile-card-4 .profile-content .title {
  font-size: 24px;
  color: #333;
  opacity: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  transform: translateY(-100px);
  transition: all 900ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}

.profile-card-4:hover .title {
  opacity: 1;
  transform: translateY(0);
}

.profile-card-4:hover .title::after {
  content: "";
  display: block;
  width: 50%;
  margin: 10px auto;
  border-bottom: 2px solid #222;
}

.profile-card-4 .profile-content .title > span {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  text-transform: capitalize;
}

.profile-card-4 .profile-content .social-link {
  margin: 20px 0;
  padding: 0;
  opacity: 0;
  transform: translateY(100px);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}

.profile-card-4:hover .social-link {
  opacity: 1;
  transform: translateY(0);
}

.profile-card-4 .profile-content .social-link li {
  display: inline-block;
  list-style: none;
  margin: 0 4px;
}

.profile-card-4 .profile-content .social-link li a {
  color: #333;
  width: 30px;
  height: 25px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
}

.pCard_card {
  height: 615px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  background-color: #f6fcfe;
  -webkit-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  -ms-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  -o-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  position: relative;
  overflow: hidden;
}

/****************
Back
****************/
.pCard_card .pCard_back {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -139px;
  font-weight: 600;
  z-index: 1;
}

.pCard_card .pCard_back a {
  text-decoration: none;
}

/****************
UP
****************/
.pCard_card .pCard_up {
  position: absolute;
  width: 100%;
  height: 437px;
  background-image: url(../img/card2.jpg);
  background-position: 50%;
  background-size: cover;
  z-index: 3;
  text-align: center;
  -webkit-border-top-left-radius: 30px;
  -moz-border-top-left-radius: 30px;
  -ms-border-top-left-radius: 30px;
  -o-border-top-left-radius: 30px;
  border-top-left-radius: 30px;
  -webkit-border-top-right-radius: 30px;
  -moz-border-top-right-radius: 30px;
  -ms-border-top-right-radius: 30px;
  -o-border-top-right-radius: 30px;
  border-top-right-radius: 30px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.pCard_on .pCard_up {
  height: 100px;
  box-shadow: 0 0 30px #cfd8dc;
}

.pCard_card .pCard_up .pCard_text {
  position: absolute;
  top: 319px;
  left: 0;
  right: 0;
  color: #f1f7f9;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.pCard_on .pCard_up .pCard_text {
  top: 20px;
}

.pCard_card .pCard_up .pCard_text h2 {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.pCard_card .pCard_up .pCard_text p {
  margin: 0;
  font-size: 16px;
  color: #e3f1f5;
}

.pCard_card .pCard_up .pCard_add {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #ed145b;
  -webkit-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  -ms-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  -o-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  position: absolute;
  top: 392px;
  left: 0;
  right: 0;
  margin: auto;
  width: 88px;
  height: 88px;
  cursor: pointer;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.pCard_on .pCard_up .pCard_add {
  -webkit-transform: rotate(360deg) scale(0.5);
  -moz-transform: rotate(360deg) scale(0.5);
  -ms-transform: rotate(360deg) scale(0.5);
  -o-transform: rotate(360deg) scale(0.5);
  transform: rotate(360deg) scale(0.5);
  top: 470px;
}

/****************
Down
****************/
.pCard_card .pCard_down {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 178px;
  z-index: 2;
  -webkit-border-bottom-left-radius: 30px;
  -moz-border-bottom-left-radius: 30px;
  -ms-border-bottom-left-radius: 30px;
  -o-border-bottom-left-radius: 30px;
  border-bottom-left-radius: 30px;
  -webkit-border-bottom-right-radius: 30px;
  -moz-border-bottom-right-radius: 30px;
  -ms-border-bottom-right-radius: 30px;
  -o-border-bottom-right-radius: 30px;
  border-bottom-right-radius: 30px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.pCard_on .pCard_down {
  height: 100px;
  -webkit-box-shadow: 0 0 30px #cfd8dc;
  -moz-box-shadow: 0 0 30px #cfd8dc;
  -ms-box-shadow: 0 0 30px #cfd8dc;
  -o-box-shadow: 0 0 30px #cfd8dc;
  box-shadow: 0 0 30px #cfd8dc;
}

.pCard_card .pCard_down div {
  width: 33.333%;
  float: left;
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.pCard_on .pCard_down div {
  margin-top: 10px;
}

.pCard_card .pCard_down div p:first-of-type {
  color: #68818c;
  margin-bottom: 5px;
}

.pCard_card .pCard_down div p:last-of-type {
  color: #334750;
  font-weight: 700;
  margin-top: 0;
}

.pCard_card .pCard_back a i {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 36px;
  margin: 10px;
  display: inline-block;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  font-size: 15px;
}

.pCard_card .pCard_back a i:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.pCard_card .pCard_back a:nth-of-type(1) i {
  color: #3b5998;
  border: 2px solid #3b5998;
}

.pCard_card .pCard_back a:nth-of-type(2) i {
  color: #0077b5;
  border: 2px solid #0077b5;
}

.pCard_card .pCard_back a:nth-of-type(3) i {
  color: #1769ff;
  border: 2px solid #1769ff;
}

.pCard_card .pCard_back a:nth-of-type(4) i {
  color: #000000;
  border: 2px solid #000000;
}

.pCard_card .pCard_back a:nth-of-type(5) i {
  color: #eb5e95;
  border: 2px solid #eb5e95;
}

.pCard_card .pCard_back a:nth-of-type(6) i {
  color: #3f729b;
  border: 2px solid #3f729b;
}

.pCard_card .pCard_up .pCard_add i {
  color: white;
  font-size: 38px;
  line-height: 88px;
}

.profile-cart_2 {
  min-height: 500px;
  box-shadow: 0 0 5px 1px rgba(52, 52, 52, 0.224);
  position: relative;
  border-radius: 20px;
  transition: 2s;
}
.profile-cart_2:hover {
  box-shadow: 0 0 15px 1px rgba(52, 52, 52, 0.25);
}
.profile-cart_2::after {
  content: " ";
  background-image: linear-gradient(135deg, #3B76EF 10%, #3B76EF 100%);
  clip-path: circle(50% at 50% 1%);
  display: block;
  min-height: 500px;
  transition: 2s;
  border-radius: 20px;
}
.profile-cart_2:hover::after {
  content: " ";
  clip-path: circle(100% at 50% 170%);
  transition: 2s;
  display: block;
  min-height: 500px;
  transition: 2s;
  border-radius: 20px;
}
.profile-cart_2 img {
  width: 100px !important;
  height: 100px !important;
  position: absolute;
  top: 78px;
  left: 37%;
  z-index: 99999999;
  transition: 2s;
  border-radius: 100%;
}
.profile-cart_2 :hover img {
  transform: scale(1.3) translateY(20px);
  transition: 2s;
}
.profile-cart_2 h1 {
  z-index: 999;
  position: absolute;
  justify-content: center;
  display: flex;
  width: 100%;
  font-family: "poppins bold";
  color: rgba(30, 29, 29, 0.85);
  letter-spacing: 2px;
}
.profile-cart_2 p {
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: center;
  text-align: center;
  top: 50%;
  font-family: "poppins";
  font-weight: 500;
  margin: 0 20px;
  color: rgba(30, 29, 29, 0.85);
}
.profile-cart_2 .social-area {
  position: absolute;
  bottom: 30px;
  z-index: 999;
  justify-content: center;
  display: flex;
  width: 100%;
}
.profile-cart_2 .social-area a {
  color: rgba(30, 29, 29, 0.85);
}
.profile-cart_2 .social-area a {
  margin: 15px 15px 0 15px;
  font-size: 20px;
  transition: 4s;
}
.profile-cart_2 :hover .social-area svg {
  color: white;
  transition: 1s;
}
.profile-cart_2 .social-area svg:hover {
  transform: scale(1.5);
}

.profile_card_5 .cover-photo {
  background: url(https://images.unsplash.com/photo-1540228232483-1b64a7024923?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80);
  height: 160px;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.profile_card_5 .profile {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #1f1a32;
  padding: 7px;
  background: #292343;
  margin-top: 80px;
  margin-left: 10px;
}
.profile_card_5 .profile-name {
  font-size: 25px;
  font-weight: bold;
  margin: 27px 0 0 120px;
}
.profile_card_5 .about {
  margin-top: 35px;
  line-height: 21px;
}
.profile_card_5 button {
  margin: 10px 0 40px 0;
}
.profile_card_5 .msg-btn, .profile_card_5 .follow-btn {
  background: #03bfbc;
  border: 1px solid #03bfbc;
  padding: 10px 25px;
  color: #231e39;
  border-radius: 3px;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
}
.profile_card_5 .follow-btn {
  margin-left: 10px;
  background: transparent;
  color: #02899c;
}
.profile_card_5 .follow-btn:hover {
  color: #231e39;
  background: #03bfbc;
  transition: 0.5s;
}
.profile_card_5 i {
  padding-left: 20px;
  font-size: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.5s;
}
.profile_card_5 i:hover {
  color: #03bfbc;
}

.widget-chart {
  text-align: center;
  padding: 1rem;
  position: relative;
}
.widget-chart .rounded {
  border-radius: 0.25rem !important;
}
.widget-chart .icon-wrapper {
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.widget-chart .icon-wrapper .icon-wrapper-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0.2;
}
.widget-chart .icon-wrapper i {
  margin: 0 auto;
  font-size: 1.7rem;
  position: relative;
  z-index: 5;
  line-height: 54px;
}
.widget-chart .widget-numbers {
  font-weight: bold;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
}
.widget-chart .widget-numbers span {
  font-weight: bold;
  font-size: 2.5rem;
}
.widget-chart .widget-subheading {
  margin-top: -0.5rem;
}
.widget-chart .widget-description {
  margin: 1rem 0 0;
}

.bg-light {
  background-color: #eee !important;
}

.icon-wrapper .icon-wrapper-bg.bg-light {
  opacity: 0.08;
}

.bg-focus {
  background-color: #444054 !important;
}

.bg-primary {
  background-color: #3f6ad8 !important;
}

.accordion_custom h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}
.accordion_custom .card {
  margin-bottom: 15px;
  border: 0;
  overflow: visible;
}
.accordion_custom .card .card-header {
  border: 0;
  padding: 0;
  border-radius: 30px !important;
}
.accordion_custom .card .card-header h2 {
  padding: 0;
}
.accordion_custom .card .card-header h2 a {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  position: relative;
  position: relative;
  padding: 13px 20px;
  background: #3B76EF;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.25);
  color: #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 0;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.accordion_custom .card .card-header h2 a::before {
  content: "\e622";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "themify";
  font-size: 14px;
  right: 30px;
  color: #fff;
}
.accordion_custom .card .card-header h2 a.collapsed {
  background: #F5F7FB;
  color: #415094;
  box-shadow: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.accordion_custom .card .card-header h2 a.collapsed::before {
  content: "\e61a";
  color: #415094;
}
.accordion_custom .card .card-header h2 a:focus {
  text-decoration: none;
}
.accordion_custom .card .card-body {
  padding: 24px 20px 30px 20px;
}
@media (max-width: 575.98px) {
  .accordion_custom .card .card-body {
    padding: 15px;
  }
}
.accordion_custom .card .card-body p {
  font-size: 14px;
  line-height: 26px;
  color: #555555;
  font-weight: 300;
  margin-bottom: 28px;
}
.accordion_custom .card .card-body p:last-child {
  margin-bottom: 0;
}

.messages_box_area {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 30px;
}
@media (max-width: 575.98px) {
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 991px) {
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .messages_box_area {
    grid-template-columns: 6fr 6fr;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  .messages_box_area {
    grid-template-columns: 5fr 7fr;
  }
}
.messages_box_area .messages_list .serach_field_2 {
  width: 100%;
}
.messages_box_area .messages_list ul {
  margin-top: 30px;
  margin-bottom: 30px;
}
.messages_box_area .messages_list ul li a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eee1e2;
  padding: 20px 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.messages_box_area .messages_list ul li a .message_pre_left {
  display: flex;
  align-items: center;
}
.messages_box_area .messages_list ul li a .message_pre_left .message_preview_thumb img {
  width: 50px;
  height: 50px;
}
.messages_box_area .messages_list ul li a .message_pre_left .messges_info {
  padding-left: 20px;
}
.messages_box_area .messages_list ul li a .message_pre_left h4 {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 6px;
}
.messages_box_area .messages_list ul li a .message_pre_left p {
  font-size: 13px;
  font-weight: 300;
  color: #676B84;
  margin-bottom: 0;
}
.messages_box_area .messages_list ul li a .messge_time span {
  font-size: 12px;
  font-weight: 300;
  color: #cec1c2;
  white-space: nowrap;
}
.messages_box_area .messages_list ul li:hover a {
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(255, 247, 248, 0.6);
}
.messages_box_area .messages_chat .messge_time span {
  white-space: nowrap;
}
.messages_box_area .messages_chat .single_message_chat {
  margin-bottom: 50px;
  padding-right: 20%;
}
@media (max-width: 575.98px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
.messages_box_area .messages_chat .single_message_chat .message_content_view {
  background: #fff7f8;
  border-radius: 10px;
  padding: 30px;
}
.messages_box_area .messages_chat .single_message_chat .message_content_view.red_border {
  border: 1px solid #1E50EC;
}
.messages_box_area .messages_chat .single_message_chat .message_content_view span {
  display: block;
  margin: 17px 0 17px 0;
}
.messages_box_area .messages_chat .single_message_chat .message_content_view p {
  font-size: 13px;
  font-weight: 300;
  color: #676B84;
  line-height: 26px;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left .message_preview_thumb img {
  width: 50px;
  height: 50px;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left .messges_info {
  padding-left: 20px;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left h4 {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 2px;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left p {
  font-size: 12px;
  font-weight: 300;
  color: #676B84;
  margin-bottom: 0;
}
.messages_box_area .messages_chat .single_message_chat.sender_message {
  padding-right: 0%;
  padding-left: 20%;
}
@media (max-width: 575.98px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
.messages_box_area .messages_chat .single_message_chat.sender_message .message_pre_left {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.messages_box_area .messages_chat .single_message_chat.sender_message .messges_info {
  padding-left: 0px;
  padding-right: 20px;
  text-align: right;
}
.messages_box_area .messages_chat .message_send_field {
  padding-top: 50px;
  display: grid;
  grid-template-columns: auto 110px;
  grid-gap: 10px;
}
@media (max-width: 575.98px) {
  .messages_box_area .messages_chat .message_send_field {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
}
.messages_box_area .messages_chat .message_send_field input {
  color: #676B84;
  font-size: 13px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 25px;
  border: 1px solid #eee1e2;
  padding-right: 15px;
}
.messages_box_area .messages_chat .message_send_field input::placeholder {
  color: #676B84;
  font-weight: 300;
  opacity: 1;
}

.email-sidebar {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 6;
  height: calc(100% - 3.9375rem);
}
.email-sidebar h4 {
  font-weight: 600;
  font-size: 0.875rem;
  color: #333;
  padding: 2.5rem 0 3.125rem 1.5625rem;
}
.email-sidebar ul {
  margin-bottom: 3.125rem;
}
.email-sidebar ul li {
  display: block;
}
.email-sidebar ul li.active {
  background: #3B76EF;
  box-shadow: 0 0.625rem 1.875rem 0 rgba(59, 118, 239, 0.3);
  color: #fff !important;
}
.email-sidebar ul li.active a {
  color: #fff;
}
.email-sidebar ul li a {
  display: block;
  padding: 0.625rem 0.625rem 0.625rem 1.5rem;
  transition: all 0.5s ease-in-out;
  font-weight: 600;
  font-size: 0.875rem;
  color: #333;
}
.email-sidebar ul li a:hover {
  background: #3B76EF;
  box-shadow: 0 0.625rem 1.875rem 0 rgba(59, 118, 239, 0.3);
  color: #fff !important;
}
.email-sidebar ul li a i {
  margin-right: 0.7rem;
}

/* line 1, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0;
  flex: 18px 0 0;
  line-height: 18px;
}

/* line 9, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox .checkmark {
  position: relative;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  display: block;
  cursor: pointer;
  line-height: 18px;
  flex: 18px 0 0;
  border-radius: 50%;
}

/* line 26, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}

/* line 35, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox input:checked ~ .checkmark:after {
  width: 100%;
  height: 100%;
  border: 0;
  transition: 0.3s;
  transform: scale(0);
}

/* line 44, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox input:checked ~ .checkmark {
  background: #3B76EF !important;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.3) !important;
  transition: 0.3s;
}

/* line 52, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox input:checked ~ .checkmark::before {
  content: "\e64c";
  font-family: "themify";
  position: absolute;
  display: block;
  top: 0px;
  left: 3px;
  text-indent: 1px;
  color: #828BB2;
  background-color: transparent;
  border: 0px;
  -webkit-transform: rotate(8deg);
  -moz-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  -o-transform: rotate(8deg);
  transform: rotate(8deg);
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  z-index: 99;
  color: #fff;
  transition: 0.3s;
}

/* line 76, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
.primary_checkbox .checkmark:after {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #828BB2;
  transition: 0.3s;
  transform: scale(1);
}