.accordion_custom{
    h4{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    .card{
        margin-bottom: 15px;
        border: 0;
        overflow: visible;
        .card-header{
            border: 0;
            padding: 0;
            border-radius: 30px !important;
            h2{
                padding: 0;
                
                a{
                    padding: 0;
                    font-size: 14px;
                    font-weight: 500;
                    width: 100%;
                    text-align: left;
                    position: relative;
                    position: relative;
                    padding: 13px 20px;
                    background: #3B76EF;
                    box-shadow: 0px 5px 10px rgba(59, 118, 239,.25);
                    color: #fff;
                    @include transition(.3s);
                    border: 0;
                    @include border-radius(30px);
                    // border: 1px solid transparent;
                    @media #{$small_mobile} {
                    }
                    &::before{
                        content: "\e622";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: 'themify';
                        font-size: 14px;
                        right: 30px;
                        color: #fff;
                    }
                    &.collapsed{
                        background: #F5F7FB;
                        color:#415094;
                        box-shadow: none;
                        @include border-radius(30px);
                        &::before{
                            content: "\e61a";
                            color: #415094;
                        }
                    }
                    &:focus{
                        text-decoration: none;
                    }
                }
            }
        }
        .card-body{
            padding: 24px 20px 30px 20px;
            @media #{$small_mobile}{
                padding: 15px;
            }
            p{
                font-size: 14px;
                line-height: 26px;
                color: #555555;
                font-weight: 300;
                margin-bottom: 28px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}


.messages_box_area{
    display: grid;
    grid-template-columns: 4fr 8fr;
    grid-gap: 30px;
    @media #{$small_mobile} {
        grid-template-columns: 1fr;
    }
    @media #{$tab} {
        grid-template-columns: 1fr;
    }
    @media #{$medium_device} {
        grid-template-columns: 1fr;
    }
    @media #{$large_device} {
        grid-template-columns: 6fr 6fr;
    }
    @media #{$extra_big_screen} {
        grid-template-columns: 5fr 7fr;
    }
    .messages_list{
        .serach_field_2{
            width: 100%;
        }
        ul{
            margin-top: 30px;
            margin-bottom: 30px;
            li{
                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    border-bottom: 1px solid #eee1e2;
                    padding: 20px 0;
                    @include transition(.3s);
                    .message_pre_left{
                       
                        display: flex;
                        align-items: center;

                        .message_preview_thumb{
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
                        .messges_info{
                            padding-left: 20px;
                        }
                        h4{
                            font-size: 14px;
                            font-weight: 500;
                            color: #222222;
                            margin-bottom: 6px;
                        }
                        p{
                            font-size: 13px;
                            font-weight: 300;
                            color: #676B84;
                            margin-bottom: 0;
                        }
                    }
                    .messge_time{
                        span{
                            font-size: 12px;
                            font-weight: 300;
                            color: #cec1c2;
                            white-space: nowrap;
                        }
                    }
                }
                &:hover a{
                    padding-left: 10px;
                    padding-right: 10px;
                    background: rgba(255, 247, 248,.60);
                }
            }
        }
    }
    .messages_chat{
        .messge_time{
            span{
                white-space: nowrap;
            }
        }
        .single_message_chat{
            margin-bottom: 50px;
            padding-right: 20%;
            @media #{$small_mobile}{
                padding-right: 0%;
                margin-bottom: 30px;
            }
            @media #{$tab}{
                padding-right: 0%;
                margin-bottom: 30px;
            }
            @media #{$large_device}{
                padding-right: 0%;
                margin-bottom: 30px;
            }
            @media #{$medium_device}{
                padding-right: 0%;
                margin-bottom: 30px;
            }
            .message_content_view {
                background: #fff7f8;
                border-radius: 10px;
                padding: 30px;
                &.red_border{
                    border: 1px solid #1E50EC;
                }
                span{
                    display: block;
                    margin: 17px 0 17px 0;
                }
                p{
                    font-size: 13px;
                    font-weight: 300;
                    color: #676B84;
                    line-height: 26px;
                }
            }
            .message_pre_left{
                margin-bottom: 20px;
                display: flex;
                align-items: center;
    
                .message_preview_thumb{
                    img{
                        width: 50px;
                        height: 50px;
                    }
                }
                .messges_info{
                    padding-left: 20px;
                }
                h4{
                    font-size: 16px;
                    font-weight: 500;
                    color: #222222;
                    margin-bottom: 2px;
                }
                p{
                    font-size: 12px;
                    font-weight: 300;
                    color: #676B84;
                    margin-bottom: 0;
                }
            }
            &.sender_message{
                padding-right: 0%;
                padding-left: 20%;
                @media #{$small_mobile}{
                    padding-right: 0%;
                    padding-left: 0%;
                    margin-bottom: 30px;
                }
                @media #{$tab}{
                    padding-right: 0%;
                    padding-left: 0%;
                    margin-bottom: 30px;
                }
                @media #{$large_device}{
                    padding-right: 0%;
                    padding-left: 0%;
                    margin-bottom: 30px;
                }
                @media #{$medium_device}{
                    padding-right: 0%;
                    padding-left: 0%;
                    margin-bottom: 30px;
                }
                .message_pre_left {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
                .messges_info {
                    padding-left: 0px;
                    padding-right: 20px;
                    text-align: right;
                }
            }
        }
        .message_send_field{
            padding-top: 50px;
            display: grid;
            grid-template-columns: auto 110px;
            grid-gap: 10px;
            @media #{$small_mobile} {
                grid-template-columns: 1fr;
                margin-top: 20px;
            }
            input{
                color: #676B84;
                font-size: 13px;
                height: 40px;
                width: 100%;
                border-radius: 5px;
                padding-left: 25px;
                border: 1px solid #eee1e2;
                padding-right: 15px;
                &::placeholder{
                    color: #676B84;
                    font-weight: 300;
                    opacity: 1;
                }
            }
        }
    }
}