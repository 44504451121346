/* Normal desktop :1200px. */
/* Normal desktop :992px. */
/* Tablet desktop :768px. */
/* small mobile :320px. */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* line 1, G:/admin_project/2 admin/finance/scss/_extends.scss */
.CRM_dropdown.dropdown .dropdown-toggle:hover, .CRM_dropdown.dropdown .dropdown-toggle:focus {
  background: -webkit-gradient(linear, left top, right top, from(#7C32FF), color-stop(70%, #A235EC), to(#C738D8));
  background: -o-linear-gradient(left, #7C32FF 0%, #A235EC 70%, #C738D8 100%);
  background: linear-gradient(90deg, #7C32FF 0%, #A235EC 70%, #C738D8 100%);
}

/**************** common css start ****************/
/* line 3, G:/admin_project/2 admin/finance/scss/_reset.scss */
body {
  font-family: "Muli", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px;
  background: #F7FAFF;
}

@media (min-width: 1200px) {
  /* line 12, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .container {
    max-width: 1310px;
  }
}

/* line 16, G:/admin_project/2 admin/finance/scss/_reset.scss */
.body_white_bg {
  background: #fff;
}

/* line 19, G:/admin_project/2 admin/finance/scss/_reset.scss */
.br_5 {
  border-radius: 5px;
}

/* line 23, G:/admin_project/2 admin/finance/scss/_reset.scss */
.message_submit_form:focus {
  outline: none;
}

/* line 26, G:/admin_project/2 admin/finance/scss/_reset.scss */
input:hover, input:focus {
  outline: none !important;
  box-shadow: 0px 0px 0px 0px transparent !important;
}

/* line 30, G:/admin_project/2 admin/finance/scss/_reset.scss */
:focus {
  outline: -webkit-focus-ring-color auto 0;
}

/* line 33, G:/admin_project/2 admin/finance/scss/_reset.scss */
.custom-file-input:focus ~ .custom-file-label {
  border-color: transparent;
  box-shadow: none;
}

/* line 37, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form-control {
  border-color: #BBC1C9;
}

/* line 40, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form-control:focus {
  box-shadow: 0 0 0 0rem transparent !important;
  border-color: #BBC1C9;
}

/* line 44, G:/admin_project/2 admin/finance/scss/_reset.scss */
.dropdown-menu {
  margin: 0;
  padding: 0;
}

/* line 48, G:/admin_project/2 admin/finance/scss/_reset.scss */
.section_padding {
  padding: 120px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 48, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_padding {
    padding: 80px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 48, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_padding {
    padding: 70px 0px;
  }
}

@media (max-width: 575.98px) {
  /* line 48, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_padding {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 48, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_padding {
    padding: 70px 0px;
  }
}

/* line 63, G:/admin_project/2 admin/finance/scss/_reset.scss */
.padding_top {
  padding-top: 120px;
}

@media (max-width: 991px) {
  /* line 63, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .padding_top {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 63, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .padding_top {
    padding-top: 80px;
  }
}

/* line 72, G:/admin_project/2 admin/finance/scss/_reset.scss */
.padding_bottom {
  padding-bottom: 120px;
}

@media (max-width: 991px) {
  /* line 72, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .padding_bottom {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 72, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .padding_bottom {
    padding-bottom: 80px;
  }
}

/* line 82, G:/admin_project/2 admin/finance/scss/_reset.scss */
a {
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 85, G:/admin_project/2 admin/finance/scss/_reset.scss */
a:hover {
  text-decoration: none;
}

/* line 91, G:/admin_project/2 admin/finance/scss/_reset.scss */
h1, h2, h3, h4, h5, h6 {
  color: #222222;
  font-family: "Muli", sans-serif;
  font-weight: 600;
  line-height: 1.2;
}

/* line 97, G:/admin_project/2 admin/finance/scss/_reset.scss */
h2 {
  font-size: 30px;
}

/* line 100, G:/admin_project/2 admin/finance/scss/_reset.scss */
h3 {
  font-size: 26px;
}

/* line 103, G:/admin_project/2 admin/finance/scss/_reset.scss */
h4 {
  font-size: 20px;
}

/* line 106, G:/admin_project/2 admin/finance/scss/_reset.scss */
h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
}

/* line 111, G:/admin_project/2 admin/finance/scss/_reset.scss */
li {
  list-style: none;
}

/* line 114, G:/admin_project/2 admin/finance/scss/_reset.scss */
ul, ol {
  margin: 0;
  padding: 0;
}

/* line 118, G:/admin_project/2 admin/finance/scss/_reset.scss */
p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0px;
  color: #828BB2;
  font-family: "Muli", sans-serif;
  font-weight: 300;
}

/* line 127, G:/admin_project/2 admin/finance/scss/_reset.scss */
a:focus, .button:focus, button:focus, .btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  -webkit-transition: 1s;
  transition: 1s;
}

/* line 133, G:/admin_project/2 admin/finance/scss/_reset.scss */
.p_30 {
  padding: 30px;
}

@media (max-width: 991px) {
  /* line 133, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .p_30 {
    padding: 20px;
  }
}

/* line 139, G:/admin_project/2 admin/finance/scss/_reset.scss */
.p_20 {
  padding: 20px;
}

/* line 142, G:/admin_project/2 admin/finance/scss/_reset.scss */
.p_15 {
  padding: 20px 20px 15px;
}

/* line 145, G:/admin_project/2 admin/finance/scss/_reset.scss */
.pb_20 {
  padding-bottom: 20px;
}

/* line 148, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_60 {
  margin-top: 60px;
}

@media (max-width: 991px) {
  /* line 148, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .mt_60 {
    margin-top: 30px;
  }
}

/* line 154, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_25 {
  margin-top: 25px;
}

/* line 158, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_30 {
  margin-top: 30px;
}

@media (max-width: 991px) {
  /* line 158, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .mt_30 {
    margin-top: 15px;
  }
}

/* line 165, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_10 {
  margin-top: 10px;
}

/* line 168, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_15 {
  margin-bottom: 15px;
}

/* line 171, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_15_imp {
  margin-bottom: 13px !important;
}

/* line 174, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_20 {
  margin-bottom: 20px;
}

/* line 177, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_20_imp {
  margin-bottom: 20px;
}

/* line 180, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_25 {
  margin-bottom: 20px;
}

/* line 183, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_50 {
  margin-bottom: 50px;
}

@media (max-width: 575.98px) {
  /* line 183, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .mb_50 {
    margin-bottom: 30px;
  }
}

/* line 189, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_50 {
  margin-top: 50px;
}

@media (max-width: 575.98px) {
  /* line 189, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .mt_50 {
    margin-top: 25px;
  }
}

/* line 195, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_20 {
  margin-top: 20px;
}

/* line 198, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_10 {
  margin-bottom: 10px !important;
}

/* line 202, G:/admin_project/2 admin/finance/scss/_reset.scss */
th:focus, td:focus {
  outline: -webkit-focus-ring-color auto 0;
}

/* line 206, G:/admin_project/2 admin/finance/scss/_reset.scss */
table.dataTable tbody th, table.dataTable tbody td {
  vertical-align: middle;
}

/* line 209, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_40 {
  margin-top: 40px;
}

/* line 212, G:/admin_project/2 admin/finance/scss/_reset.scss */
.section_bg {
  background-color: #fef1f2;
}

/* line 215, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 226, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* line 233, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #e4e8ec;
}

/* line 244, G:/admin_project/2 admin/finance/scss/_reset.scss */
.section_tittle {
  text-align: center;
}

/* line 246, G:/admin_project/2 admin/finance/scss/_reset.scss */
.section_tittle h2 {
  font-size: 36px;
  margin-bottom: 25px;
  line-height: 33px;
}

@media (max-width: 991px) {
  /* line 246, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_tittle h2 {
    margin-bottom: 15px;
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  /* line 246, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_tittle h2 {
    font-size: 30px;
  }
}

/* line 258, G:/admin_project/2 admin/finance/scss/_reset.scss */
.section_tittle p {
  margin-bottom: 72px;
}

@media (max-width: 991px) {
  /* line 258, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_tittle p {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 258, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .section_tittle p {
    margin-bottom: 50px;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
/* line 269, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
/* line 276, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_checkbox input:checked ~ .checkmark:after {
  display: block;
  left: 6px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #7e7172;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: "";
}

/* line 291, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_checkbox span {
  padding: 0 !important;
}

/* line 297, G:/admin_project/2 admin/finance/scss/_reset.scss */
.custom_checkbox tr th:first-child, .custom_checkbox tr td:first-child {
  padding: 16px 0 16px 0 !important;
}

/* line 302, G:/admin_project/2 admin/finance/scss/_reset.scss */
.border_1px {
  border-right: 1px solid rgba(130, 139, 178, 0.3);
  height: 40px;
  margin-right: 20px;
  margin-left: 8px;
}

/* line 308, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select {
  border: 0;
  border-radius: 0px;
  padding-left: 0;
  padding-right: 30px;
  color: #415094;
}

/* line 314, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select .nice-select-search-box {
  width: 200px !important;
}

/* line 317, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select.bgLess {
  background: transparent;
  border: 0 !important;
}

/* line 321, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select:after {
  content: "\e62a";
  font-family: 'themify';
  border: 0;
  transform: rotate(0deg);
  margin-top: -16px;
  font-size: 12px;
  font-weight: 500;
  right: 18px;
  transform-origin: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-top: -22px;
}

/* line 338, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select.open::after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 15px;
  margin-top: 12px;
  right: 12px;
}

/* line 348, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select:focus {
  box-shadow: none;
  border: 0 !important;
}

/* line 352, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select:hover {
  border: 0;
}

/* line 355, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select.open .list {
  min-width: 200px;
  left: 0;
  overflow-y: auto;
}

/* line 359, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select.open .list li:first-child {
  color: #000;
}

/* line 363, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select .current {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

/* line 369, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select .list {
  width: 100%;
  left: auto;
  right: 0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 1px;
  z-index: 9999 !important;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3);
}

/* line 377, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select .list li {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

/* line 385, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2 {
  border: 0;
  border-radius: 0px;
  padding-left: 0;
  padding-right: 30px;
  color: #415094;
  background: transparent;
  border: 1px solid #EAF0F4;
  padding-left: 25px;
  padding-right: 47px;
  border-radius: 4px;
  height: 32px;
  line-height: 31px;
  font-size: 12px;
  text-transform: capitalize;
}

/* line 400, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2 .nice-select-search-box {
  width: 100% !important;
  margin-top: 5px;
}

/* line 404, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2:after {
  content: "\e62a";
  font-family: 'themify';
  border: 0;
  transform: rotate(0deg);
  margin-top: -16px;
  font-size: 12px;
  font-weight: 500;
  right: 18px;
  transform-origin: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-top: -16px;
  right: 30px;
}

/* line 422, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2:active, .nice_Select2.open, .nice_Select2:focus {
  border-color: #EAF0F4;
}

/* line 425, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2.open::after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 15px;
  margin-top: 8px;
  right: 24px;
}

/* line 435, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2:focus {
  box-shadow: none;
  border: 1px solid #EAF0F4 !important;
}

/* line 439, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2:hover {
  border: 1px solid #EAF0F4 !important;
}

/* line 442, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2.open .list {
  min-width: 100%;
  margin-top: 5px;
  left: 0;
  overflow-y: auto;
}

/* line 447, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2.open .list li:first-child {
  color: #000;
}

/* line 451, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2 .current {
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

/* line 457, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2 .list {
  width: 100%;
  left: auto;
  right: 0;
  border-radius: 0px 0px 10px 10px;
  margin-top: 1px;
  z-index: 9999 !important;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3);
}

/* line 465, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nice_Select2 .list li {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

/* line 477, G:/admin_project/2 admin/finance/scss/_reset.scss */
.white_box {
  background-color: #fff;
  padding: 20px;
}

@media (max-width: 991px) {
  /* line 477, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .white_box {
    padding: 20px 20px;
  }
}

@media (max-width: 575.98px) {
  /* line 477, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .white_box {
    padding: 25px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 477, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .white_box {
    padding: 30px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* line 477, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .white_box {
    padding: 30px 20px;
  }
}

/* line 492, G:/admin_project/2 admin/finance/scss/_reset.scss */
.white_box h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 22px;
  font-family: "Muli", sans-serif;
}

@media (max-width: 991px) {
  /* line 492, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .white_box h3 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 492, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .white_box h3 {
    margin-bottom: 10px;
  }
}

/* line 504, G:/admin_project/2 admin/finance/scss/_reset.scss */
.white_box h3 span {
  font-weight: 300;
  color: #7e7172;
}

/* line 510, G:/admin_project/2 admin/finance/scss/_reset.scss */
.progress-bar {
  text-align: right;
  padding-right: 8px;
}

/* line 514, G:/admin_project/2 admin/finance/scss/_reset.scss */
.white_box_p_30 {
  padding: 30px;
  background-color: #fff;
}

@media (max-width: 991px) {
  /* line 514, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .white_box_p_30 {
    padding: 25px 15px;
  }
}

/* line 523, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cu_dropdown .btn {
  border: 1px solid #f1f2f3;
  background-color: #fef1f2;
  color: #7e7172;
  font-size: 12px;
  font-weight: 300;
  padding: 5px 15px;
}

/* line 531, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cu_dropdown .btn_1 {
  padding: 11px 20px;
}

/* line 534, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cu_dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 10px;
  vertical-align: 0;
  content: "\e64b";
  border-top: none;
  font-family: 'themify';
  font-size: 10px;
}

/* line 543, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cu_dropdown .dropdown-menu {
  min-width: auto;
  background-color: #222222;
  min-width: 102px;
  left: 0 !important;
  border-radius: 0 0 5px 5px;
  top: -2px !important;
  padding: 17px 0;
}

/* line 551, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cu_dropdown .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  padding: 7px 20px;
  padding: 7px 20px;
  color: #fff;
}

/* line 561, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cu_dropdown .left_arrow:hover {
  background-color: transparent;
  padding-left: 38px;
}

/* line 565, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cu_dropdown .left_arrow:after {
  left: 18px;
  top: 11px;
}

/* line 584, G:/admin_project/2 admin/finance/scss/_reset.scss */
.switch {
  -webkit-appearance: none;
  background-color: gray;
  border-radius: 43px;
  position: relative;
  cursor: pointer;
  padding: 14px 25px !important;
  margin-right: 20px;
  height: 30px;
}

/* line 593, G:/admin_project/2 admin/finance/scss/_reset.scss */
.switch::after {
  top: 6px;
  left: 6px;
  content: '';
  width: 15px;
  height: 15px;
  background-color: #4D4F5C;
  position: absolute;
  border-radius: 100%;
  transition: 1s;
}

/* line 604, G:/admin_project/2 admin/finance/scss/_reset.scss */
.switch:checked {
  background-color: #4D4F5C !important;
}

/* line 606, G:/admin_project/2 admin/finance/scss/_reset.scss */
.switch:checked::after {
  background-color: #fff !important;
}

/* line 609, G:/admin_project/2 admin/finance/scss/_reset.scss */
.switch:checked::after {
  transform: translateX(25px);
}

/* line 613, G:/admin_project/2 admin/finance/scss/_reset.scss */
.switch:focus {
  outline-color: transparent;
}

/* line 617, G:/admin_project/2 admin/finance/scss/_reset.scss */
.menu_bropdown {
  display: inline-block;
}

/* line 619, G:/admin_project/2 admin/finance/scss/_reset.scss */
.menu_bropdown .dropdown-menu {
  min-width: 168px !important;
  right: 0;
}

@media (max-width: 991px) {
  /* line 624, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .sidebar_widget {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 624, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .sidebar_widget {
    margin-top: 0;
  }
}

/* line 632, G:/admin_project/2 admin/finance/scss/_reset.scss */
.text_underline {
  color: #222222;
  text-decoration: underline;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 636, G:/admin_project/2 admin/finance/scss/_reset.scss */
.text_underline:hover {
  color: #222222;
  text-decoration: underline;
}

/* line 641, G:/admin_project/2 admin/finance/scss/_reset.scss */
.check_box_item {
  margin-top: 11px;
}

/* line 643, G:/admin_project/2 admin/finance/scss/_reset.scss */
.check_box_item .single_check_box {
  padding: 15px 0;
  border-bottom: 1px solid #e4e8ec;
}

/* line 646, G:/admin_project/2 admin/finance/scss/_reset.scss */
.check_box_item .single_check_box .switch {
  -webkit-appearance: none;
  background-color: transparent;
  padding: 14px 27px !important;
  margin-right: 0;
  border: 1px solid #e4e8ec;
}

/* line 652, G:/admin_project/2 admin/finance/scss/_reset.scss */
.check_box_item .single_check_box .switch:after {
  top: 4px;
  width: 20px;
  height: 20px;
}

/* line 658, G:/admin_project/2 admin/finance/scss/_reset.scss */
.check_box_item .single_check_box .switch:checked::after {
  background-color: #7e7172 !important;
  left: 4px;
}

/* line 662, G:/admin_project/2 admin/finance/scss/_reset.scss */
.check_box_item .single_check_box .switch:checked {
  background-color: transparent !important;
}

/* line 668, G:/admin_project/2 admin/finance/scss/_reset.scss */
.list_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}

@media (max-width: 575.98px) {
  /* line 668, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header {
    display: block;
  }
}

/* line 679, G:/admin_project/2 admin/finance/scss/_reset.scss */
.list_header p {
  color: #222222;
  font-weight: 500;
}

/* line 683, G:/admin_project/2 admin/finance/scss/_reset.scss */
.list_header .nice_Select {
  margin-left: 15px;
}

@media (max-width: 575.98px) {
  /* line 683, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header .nice_Select {
    margin-left: 10px;
  }
}

/* line 689, G:/admin_project/2 admin/finance/scss/_reset.scss */
.list_header .list_header_filter {
  display: flex;
  align-items: center;
}

@media (max-width: 575.98px) {
  /* line 689, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header .list_header_filter {
    margin-top: 15px;
  }
}

/* line 695, G:/admin_project/2 admin/finance/scss/_reset.scss */
.list_header .list_header_filter img {
  width: 17px;
  margin-right: 8px;
}

/* line 703, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-content {
  background-color: #fef1f2;
  padding: 0 30px;
}

@media (max-width: 575.98px) {
  /* line 703, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .cs_modal .modal-content {
    padding: 0 20px;
  }
}

/* line 710, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-header {
  padding: 34px 0 30px 0px;
  align-items: center;
  border-bottom: 0px solid transparent;
}

/* line 714, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-header h5 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

/* line 720, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-header .close {
  padding: 0;
  margin: 0;
  opacity: 1;
}

/* line 724, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-header .close i {
  font-size: 14px;
  color: #707070;
}

/* line 731, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body {
  padding: 35px 30px !important;
  background: #fff;
  border-radius: 5px;
}

@media (max-width: 575.98px) {
  /* line 731, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .cs_modal .modal-body {
    padding: 25px 20px !important;
  }
}

/* line 738, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .chose_thumb_title {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}

/* line 749, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .input_wrap label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}

/* line 759, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .input_wrap textarea {
  height: 100px;
  padding: 0;
  line-height: 28px;
  padding: 13px 25px;
}

/* line 766, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .nice_Select, .cs_modal .modal-body input, .cs_modal .modal-body textarea {
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  border: 1px solid #eee1e2;
  padding: 10px 25px;
  color: #7e7172;
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  display: block;
  margin-bottom: 21px;
  font-weight: 300;
  border-radius: 3px;
}

/* line 780, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .nice_Select::placeholder, .cs_modal .modal-body input::placeholder, .cs_modal .modal-body textarea::placeholder {
  color: #7e7172;
  font-weight: 300;
  opacity: 1;
}

/* line 786, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .nice_Select {
  line-height: 30px;
}

/* line 788, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .nice_Select :after {
  left: 22px;
}

/* line 792, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body ::placeholder {
  color: #222222;
}

/* line 796, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal_btn {
  display: flex;
  justify-content: start;
  align-items: center;
}

/* line 800, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal_btn .btn_1 {
  padding: 16px 26px !important;
  margin: 0 5px;
  text-align: center;
}

/* line 807, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-footer {
  border-top: 0px solid transparent;
  padding: 30px 0 40px 0;
}

/* line 813, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group {
  display: block;
  width: 100%;
  margin-bottom: 23px;
}

/* line 817, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #707070;
}

/* line 823, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group .nice_Select, .form_box .from_group input, .form_box .from_group textarea {
  width: 100%;
  display: block;
  background-color: #fef1f2;
  border: 1px solid #f1f2f3;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  padding: 10px 20px;
}

/* line 833, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group textarea {
  height: 115px;
  line-height: 27px;
}

/* line 837, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group .bootstrap-tagsinput input {
  border: none !important;
  width: auto !important;
  display: inline-block !important;
  background-color: transparent !important;
}

/* line 843, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group .bootstrap-tagsinput .badge [data-role="remove"]:after {
  background-color: transparent !important;
}

/* line 846, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group .bootstrap-tagsinput .badge {
  margin: 0 5px;
  padding: 9px 8px;
  line-height: 12px;
  font-weight: 500;
  font-size: 13px;
}

/* line 853, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group .badge-info {
  color: #fff;
  background-color: #2EC9B8;
}

/* line 857, G:/admin_project/2 admin/finance/scss/_reset.scss */
.form_box .from_group .nice_Select {
  line-height: 30px;
}

/* line 862, G:/admin_project/2 admin/finance/scss/_reset.scss */
.note-editable {
  background-color: #fef1f2;
}

/* line 866, G:/admin_project/2 admin/finance/scss/_reset.scss */
.note-btn-group.note-style {
  display: none;
}

/* line 873, G:/admin_project/2 admin/finance/scss/_reset.scss */
.plr_30 {
  padding: 0 30px !important;
}

@media (max-width: 767.98px) {
  /* line 873, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .plr_30 {
    padding: 0 15px !important;
  }
}

/* line 880, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_8 {
  margin-bottom: 8px !important;
}

/* line 883, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_7 {
  margin-bottom: 7px !important;
}

/* line 894, G:/admin_project/2 admin/finance/scss/_reset.scss */
.ml-10 {
  margin-left: 10px;
}

/* line 897, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_30 {
  margin-bottom: 30px !important;
}

@media (max-width: 575.98px) {
  /* line 900, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .lms_block {
    display: block !important;
  }
}

@media (max-width: 575.98px) {
  /* line 906, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .add_button {
    margin-top: 15px;
    margin-left: 0;
  }
}

/* line 914, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_pagination_wrap {
  margin-top: 30px;
}

/* line 917, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_pagination_wrap ul li {
  display: inline-block;
  margin: 0 .8px;
}

/* line 920, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_pagination_wrap ul li a {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #fff;
  line-height: 40px;
  color: #cec1c2;
  text-align: center;
  border-radius: 3px;
  font-size: 14px;
}

/* line 930, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_pagination_wrap ul li a.active {
  background: #fe1724;
  color: #fff;
}

/* line 934, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_pagination_wrap ul li a:hover {
  background: #fe1724;
  color: #fff;
}

@media (min-width: 768px) {
  /* line 947, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .modal-dialog.custom-modal-dialog {
    max-width: 650px;
  }
}

@media (min-width: 992px) {
  /* line 952, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .modal-dialog.custom-modal-dialog {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  /* line 957, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .modal-dialog.custom-modal-dialog {
    max-width: 1050px;
  }
}

/* line 963, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload {
  border: 1px solid #eee1e2 !important;
  padding: 9px 24px;
  border-radius: 3px;
  /* input file style */
}

/* line 967, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload label {
  margin-bottom: 0 !important;
}

/* line 970, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .form-group {
  padding: 0;
  margin-bottom: 0;
}

/* line 974, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .btn_file_upload {
  color: #fff;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  display: inline-block;
}

/* line 981, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .btn_file_upload:hover, .file_upload .btn_file_upload:focus {
  color: #888888;
  border-color: #888888;
}

/* line 990, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .input-file, .file_upload .input-file2 {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/* line 997, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .input-file + .js-labelFile, .file_upload .input-file + .js-labelFile2, .file_upload .input-file2 + .js-labelFile, .file_upload .input-file2 + .js-labelFile2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}

/* line 1005, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .btn {
  padding: 0px 16px !important;
  border-radius: 5px;
  background-color: #fe1724;
  display: inline-block !important;
  font-size: 14px;
  color: #fff !important;
  border-radius: 30px;
  bottom: 0;
  margin-bottom: 0;
}

/* line 1015, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .btn:hover {
  background-color: #4D4F5C;
  color: #fff;
  border: 0;
}

/* line 1021, G:/admin_project/2 admin/finance/scss/_reset.scss */
.file_upload .form-group {
  background-color: #fff;
  width: 100%;
  padding: 0px;
  border-radius: 5px;
}

/* line 1028, G:/admin_project/2 admin/finance/scss/_reset.scss */
td:focus, th:focus {
  outline: none !important;
}

/* line 1031, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_20 {
  margin-bottom: 20px;
}

/* line 1034, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mr_10 {
  margin-right: 10px !important;
}

/* line 1039, G:/admin_project/2 admin/finance/scss/_reset.scss */
.checkbox_wrap p {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  margin-bottom: 0;
}

/* line 1046, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 50px;
  margin-bottom: 0;
  margin-right: 15px;
}

/* line 1053, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 input {
  display: none !important;
}

/* line 1056, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 .slider-check {
  background-color: #7e7172;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

/* line 1065, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 .slider-check.round {
  border-radius: 34px;
}

/* line 1068, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 .slider-check::before {
  background-color: #222222;
  bottom: 5px;
  content: "";
  height: 20px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 20px;
}

/* line 1078, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 .slider-check.round:before {
  border-radius: 50%;
}

/* line 1082, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 input:checked + .slider-check {
  background-color: #fef1f2;
  color: #fff;
  content: "oui";
}

/* line 1087, G:/admin_project/2 admin/finance/scss/_reset.scss */
.lms_checkbox_1 input:checked + .slider-check:before {
  transform: translateX(20px);
  background: #fe1724;
}

/* line 1097, G:/admin_project/2 admin/finance/scss/_reset.scss */
.input_wrap label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}

@media (min-width: 991px) {
  /* line 1112, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .medium_modal_width .modal-dialog {
    max-width: 780px;
  }
}

/* line 1120, G:/admin_project/2 admin/finance/scss/_reset.scss */
.list_header_btn_wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
  margin-right: 60px;
}

@media (max-width: 575.98px) {
  /* line 1120, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_btn_wrapper {
    display: block;
    margin-bottom: 15px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 1120, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_btn_wrapper {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 1120, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_btn_wrapper {
    margin-right: 0;
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 1120, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_btn_wrapper {
    margin-right: 0;
    display: block;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* line 1120, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_btn_wrapper {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 1148, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_block {
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 1152, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_block .success_faild_btn {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* line 1157, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_block .serach_field_2 {
    width: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 1157, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_block .serach_field_2 {
    width: 380%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 1165, G:/admin_project/2 admin/finance/scss/_reset.scss */
  .list_header_block .box_right {
    justify-content: space-between;
  }
}

/* line 1173, G:/admin_project/2 admin/finance/scss/_reset.scss */
.gj-datepicker .fa-calendar-alt {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
  color: #cec1c2;
  right: 25px;
}

/* line 1183, G:/admin_project/2 admin/finance/scss/_reset.scss */
.white_box_border {
  border: 1px solid rgba(202, 206, 213, 0.7);
}

/* line 1187, G:/admin_project/2 admin/finance/scss/_reset.scss */
.nowrap {
  white-space: nowrap;
}

/* line 1191, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb-25 {
  margin-bottom: 25px;
}

/* line 1194, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_15 {
  margin-bottom: 15px;
}

/* line 1197, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mr-10 {
  margin-right: 10px;
}

/* line 1200, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mr_5 {
  margin-right: 5px;
}

/* line 1203, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mb_30 {
  margin-bottom: 30px;
}

/* line 1206, G:/admin_project/2 admin/finance/scss/_reset.scss */
.mt_10px {
  margin-top: 10px;
}

/* line 1209, G:/admin_project/2 admin/finance/scss/_reset.scss */
.pt_30 {
  padding-top: 30px !important;
}

/* line 1216, G:/admin_project/2 admin/finance/scss/_reset.scss */
.wrapper .progress {
  margin-bottom: 15px;
}

/* line 1230, G:/admin_project/2 admin/finance/scss/_reset.scss */
.apexcharts-datalabels-group {
  position: relative;
  top: -13px !important;
}

/* line 1234, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal {
  background-color: #fef1f2;
}

/* line 1236, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-header {
  background-color: #F7FAFF;
  padding: 23px 30px;
  border-bottom: 0px solid transparent;
}

/* line 1240, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-header h5 {
  font-size: 22px;
  font-weight: 600;
}

/* line 1244, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-header span {
  font-size: 14px;
  color: #707070;
  opacity: 1;
}

/* line 1250, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body {
  padding: 35px 30px;
}

/* line 1252, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body input, .cs_modal .modal-body .nice_Select {
  height: 50px;
  line-height: 50px;
  padding: 10px 20px;
  border: 1px solid #F1F3F5;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  width: 100%;
}

/* line 1263, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .nice_Select {
  line-height: 29px;
}

/* line 1265, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .nice_Select:after {
  right: 22px;
}

/* line 1268, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .nice_Select .list {
  width: 100%;
}

/* line 1272, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .form-group {
  margin-bottom: 12px;
}

/* line 1275, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body textarea {
  height: 168px;
  padding: 15px 20px;
  border: 1px solid #F1F3F5;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
}

/* line 1283, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body p {
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  color: #707070;
  text-align: center;
}

/* line 1289, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body p a {
  color: #4D4F5C;
}

/* line 1294, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .btn_1 {
  width: 100%;
  display: block;
  margin-top: 20px;
}

/* line 1300, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .social_login_btn .btn_1 {
  color: #fff;
  background-color: #3B5998;
  border: 1px solid #3B5998;
  margin-top: 0;
}

/* line 1305, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .social_login_btn .btn_1:hover {
  color: #fff !important;
}

/* line 1308, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .social_login_btn .btn_1 i {
  margin-right: 10px;
}

/* line 1314, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .social_login_btn .form-group:last-child .btn_1 {
  background-color: #4285F4;
  border: 1px solid #4285F4;
}

/* line 1321, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .pass_forget_btn {
  color: #707070;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}

/* line 1328, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_modal .modal-body .pass_forget_btn:hover {
  color: #4D4F5C;
}

/* line 1336, G:/admin_project/2 admin/finance/scss/_reset.scss */
.border_style {
  width: 100%;
  display: block;
  text-align: center;
  padding: 13px 0 17px;
  position: relative;
  z-index: 1;
}

/* line 1343, G:/admin_project/2 admin/finance/scss/_reset.scss */
.border_style span {
  padding: 0 25px;
  text-align: center;
  display: inline-block;
  background-color: #fff;
}

/* line 1349, G:/admin_project/2 admin/finance/scss/_reset.scss */
.border_style:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #BBC1C9;
  top: 24px;
  left: 0;
  z-index: -1;
}

/* line 1361, G:/admin_project/2 admin/finance/scss/_reset.scss */
p a {
  color: #7e7172;
}

/* line 1363, G:/admin_project/2 admin/finance/scss/_reset.scss */
p a:hover {
  color: #4D4F5C;
}

/* line 1369, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box {
  display: flex;
  margin-top: 20px;
}

/* line 1372, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box input, .cs_check_box label {
  line-height: 20px !important;
  height: auto !important;
  width: auto !important;
  max-width: auto;
}

/* line 1378, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box .common_checkbox {
  display: none;
}

/* line 1381, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box label {
  position: relative;
  padding-left: 34px;
  margin-bottom: 0;
}

/* line 1386, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box .common_checkbox + label {
  display: block;
  cursor: pointer;
  color: #7e7172;
}

/* line 1391, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box .common_checkbox + label:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #7e7172;
  border-radius: 5px;
  content: "";
}

/* line 1401, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box .common_checkbox:checked + label:after {
  content: '\f00c';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  padding: 2px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

/* line 1411, G:/admin_project/2 admin/finance/scss/_reset.scss */
.cs_check_box .common_checkbox {
  display: none !important;
}

/******** base color css ********/
/* line 2, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1 {
  background-color: #3B76EF;
  border: 1px solid #3B76EF;
  color: #fff;
  display: inline-block;
  padding: 11px 23px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 15, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1 i {
  font-size: 15px;
  padding-right: 7px;
}

/* line 19, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1:hover {
  background-color: #fff;
  border: 1px solid #3B76EF;
  color: #3B76EF;
}

/* line 24, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1.light {
  background-color: rgba(77, 79, 92, 0.1);
  border: 1px solid transparent;
  color: #4D4F5C;
}

/* line 29, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1.green {
  background-color: rgba(46, 201, 184, 0.1);
  border: 1px solid transparent;
  color: #2EC9B8;
}

/* line 34, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1.sm {
  font-size: 12px;
  padding: 6px 15px;
}

/* line 38, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1.big_btn {
  padding: 11px 36px;
}

/* line 41, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1.form_big_btn_1 {
  padding: 16px 62px !important;
}

@media (max-width: 575.98px) {
  /* line 41, G:/admin_project/2 admin/finance/scss/_button.scss */
  .btn_1.form_big_btn_1 {
    padding: 16px 30px !important;
  }
}

/* line 47, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_1.big_btn2 {
  padding: 11px 37px;
}

/* line 51, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_2 {
  color: #4D4F5C;
  border: 1px solid #4D4F5C;
  display: inline-block;
  padding: 11px 23px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 64, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_2:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

/* line 70, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_3 {
  color: #222222;
  border: 1px solid #e4e8ec;
  display: inline-block;
  padding: 11px 27px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 83, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_3.border_color_1 {
  border: 1px solid #eee1e2;
}

/* line 87, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_3:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

/* line 93, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_4 {
  display: inline-block;
  border: 1px solid #e4e8ec;
  border-radius: 5px;
  color: #7e7172;
  text-align: center;
  padding: 9px 15px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 300;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 104, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_4:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

/* line 111, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_5 {
  border: 1px solid #fff;
  display: inline-block;
  padding: 16px 26px !important;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #fff !important;
  text-shadow: none !important;
  opacity: 1;
  color: #222222;
}

/* line 127, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_5:hover {
  background-color: #4D4F5C !important;
  border: 1px solid #4D4F5C !important;
  color: #fff;
  opacity: 1 !important;
}

/* line 133, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_5.small_btn {
  padding: 11px 26px !important;
}

@media (max-width: 575.98px) {
  /* line 133, G:/admin_project/2 admin/finance/scss/_button.scss */
  .btn_5.small_btn {
    padding: 11px 19px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 133, G:/admin_project/2 admin/finance/scss/_button.scss */
  .btn_5.small_btn {
    padding: 11px 19px !important;
  }
}

/* line 143, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_6 {
  color: #222222;
  border: 1px solid #eee1e2;
  display: inline-block;
  padding: 11px 25px;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 156, G:/admin_project/2 admin/finance/scss/_button.scss */
.btn_6:hover {
  background-color: #4D4F5C;
  border: 1px solid #4D4F5C;
  color: #fff;
}

/* line 162, G:/admin_project/2 admin/finance/scss/_button.scss */
.sm_btn {
  line-height: 15px;
  background-color: #fff;
  height: 34px;
  padding: 9px 20px;
  color: #212e40;
  width: 150px;
  text-transform: capitalize;
  border: 1px solid #eee1e2;
}

/* line 172, G:/admin_project/2 admin/finance/scss/_button.scss */
.view_archive_btn {
  border: 1px solid #e4e8ec;
  color: #7e7172;
  padding: 16px 30px;
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 182, G:/admin_project/2 admin/finance/scss/_button.scss */
.view_archive_btn i {
  margin-right: 15px;
}

/* line 185, G:/admin_project/2 admin/finance/scss/_button.scss */
.view_archive_btn:hover {
  color: #4D4F5C;
  border: 1px solid #4D4F5C;
}

/* line 191, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn {
  display: inline-block;
  color: #7e7172;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  transition: .3s;
  padding: 6px 20px;
  border-radius: 3px;
}

/* line 200, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn:hover {
  background: #3B76EF;
  color: #fff;
}

/* line 206, G:/admin_project/2 admin/finance/scss/_button.scss */
.red_btn {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  background: #3B76EF;
  transition: .3s;
  padding: 6px 20px;
  border-radius: 3px;
}

/* line 215, G:/admin_project/2 admin/finance/scss/_button.scss */
.red_btn:hover {
  background: #fff;
  color: #7e7172;
}

/* line 220, G:/admin_project/2 admin/finance/scss/_button.scss */
.close.white_btn2 {
  border: 1px solid #fff;
  display: inline-block;
  padding: 16px 26px !important;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #fff !important;
  text-shadow: none !important;
  opacity: 1;
  color: #222222;
  margin-right: 10px;
}

/* line 237, G:/admin_project/2 admin/finance/scss/_button.scss */
.close.white_btn2:hover {
  background-color: #4D4F5C !important;
  border: 1px solid #4D4F5C !important;
  color: #fff;
  opacity: 1 !important;
}

/* line 244, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn3 {
  border: 1px solid #fff;
  display: inline-block;
  padding: 11px 30px !important;
  text-transform: capitalize;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #fff !important;
  text-shadow: none !important;
  opacity: 1;
  color: #222222;
  margin-right: 10px;
}

/* line 261, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn3:hover {
  background-color: #4D4F5C !important;
  border: 1px solid #4D4F5C !important;
  color: #fff;
  opacity: 1 !important;
}

/* line 269, G:/admin_project/2 admin/finance/scss/_button.scss */
.status_btn {
  display: inline-block;
  padding: 2px 15px;
  font-size: 11px;
  font-weight: 300;
  color: #fff !important;
  background: #05d34e;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  text-transform: capitalize;
  white-space: nowrap;
  min-width: 70px;
  text-align: center;
}

/* line 281, G:/admin_project/2 admin/finance/scss/_button.scss */
.status_btn.yellow_btn {
  background: #ffba00;
}

/* line 284, G:/admin_project/2 admin/finance/scss/_button.scss */
.status_btn:hover {
  color: #fff;
}

/* line 289, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown .dropdown-toggle.lms_drop_1 {
  background: #fff;
  padding: 4px 15px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-align: left;
  color: #7e7172;
  display: inline-block;
  width: 102px;
  border: 0;
  border: 1px solid #eee1e2;
  position: relative;
  transition: .3s;
}

/* line 304, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown .dropdown-toggle.lms_drop_1::after {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  top: 12px;
  color: #cec1c2;
  font-size: 12px;
  font-weight: 900;
  margin: 0;
  padding: 0;
  border: 0;
  border: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 14px;
}

/* line 321, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown .dropdown-toggle.lms_drop_1.lms_drop_2 {
  min-width: 140px;
}

/* line 326, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown.show .dropdown-toggle.lms_drop_1 {
  background: #3B76EF;
  padding: 4px 15px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-align: left;
  color: #7e7172;
  display: inline-block;
  width: 102px;
  border: 0;
  color: #fff;
  border: 1px solid #3B76EF;
}

/* line 340, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown.show .dropdown-toggle.lms_drop_1::after {
  content: "\f0d8";
}

/* line 345, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown .dropdown-menu {
  border: 0;
  border: 1px solid #eee1e2;
  padding: 12px 0 20px 0;
  margin: 0;
  border-radius: 0 !important;
}

/* line 351, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: 300;
  padding: 4px 20px;
  color: #7f7778;
}

/* line 356, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown .dropdown-menu .dropdown-item:hover {
  color: #3B76EF;
  background: transparent;
}

/* line 361, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown .dropdown-menu.dropdown-menu-w140 {
  min-width: 140px;
}

/* line 366, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn_line {
  white-space: nowrap;
  height: 50px;
  background: transparent;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  border: 0;
  padding: 0 19px;
  border-radius: 3px;
  border: 1px solid #eee1e2;
  color: #7e7172;
  transition: .3s;
}

/* line 380, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn_line i {
  font-size: 13px;
  color: #7e7172;
  margin-right: 6px;
}

/* line 385, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn_line:hover {
  background: #3B76EF;
  color: #fff;
  border: 1px solid #3B76EF;
}

/* line 389, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn_line:hover i {
  color: #fff;
  transition: .3s;
}

/* line 395, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn_line2 {
  white-space: nowrap;
  height: 30px;
  background: transparent;
  font-size: 12px;
  font-weight: 300;
  display: inline-block;
  text-transform: capitalize;
  border: 0;
  border: 1px solid #eee1e2;
  color: #7e7172;
  transition: .3s;
  border-radius: 30px;
  line-height: 28px;
  padding: 0 18px;
}

/* line 410, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn_line2:hover {
  background: #3B76EF;
  color: #fff !important;
  border: 1px solid #3B76EF;
}

/* line 414, G:/admin_project/2 admin/finance/scss/_button.scss */
.white_btn_line2:hover i {
  color: #fff;
  transition: .3s;
}

/* line 422, G:/admin_project/2 admin/finance/scss/_button.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before {
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  content: "\e61a";
  font-family: 'themify';
  font-size: 8px;
  line-height: 14px;
  font-weight: 600;
  background-color: #fef1f2;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.08), -6px -6px 12px white;
}

/* line 438, G:/admin_project/2 admin/finance/scss/_button.scss */
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {
  content: "\e622";
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.08), inset -6px -6px 12px white;
}

/* line 452, G:/admin_project/2 admin/finance/scss/_button.scss */
.CRM_dropdown.dropdown .dropdown-toggle {
  background: transparent;
  color: #415094;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #7c32ff;
  border-radius: 32px;
  padding: 5px 20px;
  text-transform: uppercase;
  overflow: hidden;
  transition: .3s;
}

/* line 463, G:/admin_project/2 admin/finance/scss/_button.scss */
.CRM_dropdown.dropdown .dropdown-toggle:after {
  content: "\e62a";
  font-family: "themify";
  border: none;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: medium;
  border-top: 0px;
  font-size: 10px;
  position: relative;
  top: 3px;
  left: 0;
  font-weight: 600;
  transition: .3s;
}

/* line 478, G:/admin_project/2 admin/finance/scss/_button.scss */
.CRM_dropdown.dropdown .dropdown-toggle:hover, .CRM_dropdown.dropdown .dropdown-toggle:focus {
  color: #fff;
  border: 1px solid transparent;
  box-shadow: none;
}

/* line 493, G:/admin_project/2 admin/finance/scss/_button.scss */
.CRM_dropdown.dropdown .dropdown-menu {
  border-radius: 5px 5px 10px 10px;
  border: 0px;
  padding: 15px 0px;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3);
}

/* line 498, G:/admin_project/2 admin/finance/scss/_button.scss */
.CRM_dropdown.dropdown .dropdown-menu .dropdown-item {
  color: #828bb2;
  text-align: right;
  font-size: 12px;
  padding: 4px 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
}

/* line 505, G:/admin_project/2 admin/finance/scss/_button.scss */
.CRM_dropdown.dropdown .dropdown-menu .dropdown-item:hover {
  color: #4D4F5C;
}

/* line 512, G:/admin_project/2 admin/finance/scss/_button.scss */
.dropdown-menu.option_width_8 {
  min-width: 150px;
}

/******** header css here *********/
/* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  padding: 30px;
  position: relative;
  margin: 27px 30px;
  border-radius: 7px;
}

@media (max-width: 575.98px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner {
    justify-content: flex-end !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner {
    justify-content: flex-end !important;
  }
}

@media (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner {
    padding: 20px;
    margin: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner {
    margin: 0 10px;
  }
}

@media (max-width: 575.98px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner {
    margin: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner {
    margin: 0;
  }
}

@media only screen and (min-width: 1200px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner {
    margin-bottom: 20px;
  }
}

/* line 49, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .page_tittle h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: #222222;
  margin-bottom: 2px;
}

@media (max-width: 991px) {
  /* line 49, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .page_tittle h3 {
    margin-right: 15px;
  }
}

/* line 59, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .page_tittle .page_subtitle {
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  margin-bottom: 0;
}

/* line 64, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr {
  color: #7e7172;
}

/* line 66, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr.active_subcat {
  color: #fe1724;
  white-space: nowrap;
}

/* line 70, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr i {
  margin-left: 10px;
  margin-right: 7px;
  font-size: 12px;
}

/* line 75, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .page_tittle .page_subtitle .page_subtitle_inenr svg {
  position: relative;
  margin: 0 7px 0 10px;
  top: -1px;
}

@media (max-width: 575.98px) {
  /* line 75, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .page_tittle .page_subtitle .page_subtitle_inenr svg {
    margin: 0 2px 0 2px;
  }
}

/* line 87, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .btn_1 {
  margin: 0 30px;
}

@media (max-width: 991px) {
  /* line 90, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .notification {
    margin: 0 10px;
  }
}

/* line 94, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .notification i {
  color: #707070;
  position: relative;
  font-size: 20px;
}

/* line 98, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .notification i:after {
  position: absolute;
  content: "";
  right: -1px;
  top: 0;
  height: 9px;
  width: 9px;
  background-color: #4D4F5C;
  border-radius: 50%;
}

@media (max-width: 767.98px) {
  /* line 110, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .serach_field-area {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 110, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .serach_field-area {
    width: auto;
    left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 110, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .serach_field-area {
    width: auto;
    margin-right: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 110, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .serach_field-area {
    width: auto;
    margin-right: 70px;
  }
}

/* line 128, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .header_right .header_notification_warp {
  margin-right: 50px;
  margin-left: 40px;
}

@media (max-width: 575.98px) {
  /* line 128, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .header_right .header_notification_warp {
    margin-right: 25px;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 128, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .header_right .header_notification_warp {
    margin-right: 35px;
    margin-left: 25px;
  }
}

/* line 139, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .header_right .header_notification_warp li {
  display: inline-block;
}

/* line 141, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .header_right .header_notification_warp li:last-child a {
  margin-right: 0;
}

/* line 144, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .header_right .header_notification_warp li a {
  font-size: 20px;
  color: #929BB5;
  position: relative;
  margin-right: 29px;
  display: inline-block;
}

@media (max-width: 575.98px) {
  /* line 167, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .header_right .select_style {
    display: none !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 167, G:/admin_project/2 admin/finance/scss/_header.scss */
  .header_iner .header_right .select_style {
    display: none !important;
  }
}

/* line 176, G:/admin_project/2 admin/finance/scss/_header.scss */
.header_iner .nice_Select {
  color: #929BB5;
}

/* line 180, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info {
  position: relative;
}

/* line 184, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info img {
  max-width: 77px;
  max-height: 77px;
  border-radius: 50%;
  border: 2px solid #A5ADC6;
  cursor: pointer;
}

@media (max-width: 991px) {
  /* line 184, G:/admin_project/2 admin/finance/scss/_header.scss */
  .profile_info img {
    max-width: 30px;
  }
}

/* line 194, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_iner {
  position: absolute;
  right: 0;
  background-color: #4D4F5C;
  text-align: right;
  width: 215px;
  padding: 30px 30px 20px;
  opacity: 0;
  visibility: hidden;
  top: 77px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/* line 206, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_iner::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  top: -14px;
  right: 10px;
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent #4D4F5C transparent;
}

/* line 217, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_iner a {
  display: block;
}

/* line 221, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_iner p {
  font-size: 12px;
  font-weight: 300;
}

/* line 225, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_iner h5 {
  font-size: 12px;
  color: #fff;
}

/* line 231, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info:hover .profile_info_iner {
  opacity: 1;
  visibility: visible;
  top: 77px;
}

/* line 237, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_details {
  margin-top: 20px;
  border-top: 1px solid #79838b;
  padding-top: 10px;
}

/* line 241, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_details a {
  color: #fff;
  font-size: 13px;
  display: block;
  padding: 10px 0;
}

/* line 246, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_details a:hover {
  color: #fff;
}

/* line 248, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_details a:hover i {
  color: #fff;
}

/* line 253, G:/admin_project/2 admin/finance/scss/_header.scss */
.profile_info .profile_info_details i {
  color: #cccccc;
  font-size: 12px;
  margin-left: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 261, G:/admin_project/2 admin/finance/scss/_header.scss */
.sidebar_icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: auto;
  left: 20px;
}

@media (max-width: 575.98px) {
  /* line 261, G:/admin_project/2 admin/finance/scss/_header.scss */
  .sidebar_icon {
    right: auto;
    left: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 261, G:/admin_project/2 admin/finance/scss/_header.scss */
  .sidebar_icon {
    right: auto;
    left: 20px;
  }
}

/* line 276, G:/admin_project/2 admin/finance/scss/_header.scss */
.sidebar_icon i {
  font-size: 20px;
}

/******** header css end *********/
/* line 285, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field-area {
  width: 480px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 285, G:/admin_project/2 admin/finance/scss/_header.scss */
  .serach_field-area {
    width: 400px;
  }
}

/* line 293, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field-area .search_inner input {
  color: #000;
  font-size: 17px;
  height: 60px;
  width: 100%;
  padding-left: 82px;
  border: 0;
  padding-right: 15px;
  border-bottom: 1px solid #F4F7FC;
  background: #F7FAFF;
  border-radius: 30px;
}

/* line 304, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field-area .search_inner input::placeholder {
  font-size: 17px;
  font-weight: 500;
  color: #818E94;
  font-family: "Muli", sans-serif;
}

/* line 310, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field-area .search_inner input:focus {
  outline: none;
}

/* line 314, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field-area .search_inner button {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: transparent;
  font-size: 12px;
  border: 0;
  padding-left: 40px;
  padding-right: 11px;
}

/* line 324, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field-area .search_inner button i {
  font-size: 14px;
  color: #818E94;
}

/* line 334, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field_2 {
  width: 430px;
  position: relative;
  margin-right: 0px;
}

@media (max-width: 575.98px) {
  /* line 334, G:/admin_project/2 admin/finance/scss/_header.scss */
  .serach_field_2 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 334, G:/admin_project/2 admin/finance/scss/_header.scss */
  .serach_field_2 {
    width: 190px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 334, G:/admin_project/2 admin/finance/scss/_header.scss */
  .serach_field_2 {
    width: 230px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  /* line 334, G:/admin_project/2 admin/finance/scss/_header.scss */
  .serach_field_2 {
    width: 300px;
  }
}

/* line 351, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field_2 .search_inner input {
  color: #000;
  font-size: 13px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 55px;
  border: 1px solid #144BF6;
  padding-right: 15px;
}

/* line 360, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field_2 .search_inner input::placeholder {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
}

/* line 365, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field_2 .search_inner input:focus {
  outline: none;
}

/* line 369, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field_2 .search_inner button {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: transparent;
  font-size: 12px;
  border: 0;
  padding-left: 19px;
  padding-right: 11px;
}

/* line 380, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field_2 .search_inner button i {
  font-size: 12px;
  color: #144BF6;
}

/* line 384, G:/admin_project/2 admin/finance/scss/_header.scss */
.serach_field_2 .search_inner button::before {
  position: absolute;
  width: 1px;
  height: 24px;
  content: '';
  background: #144BF6;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

/* line 402, G:/admin_project/2 admin/finance/scss/_header.scss */
table.dataTable {
  width: 100% !important;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
}

/* line 415, G:/admin_project/2 admin/finance/scss/_header.scss */
.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 0 !important;
}

/************ sidebar css here ************/
/* line 2, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar {
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  padding-bottom: 50px;
  position: fixed;
  width: 270px;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
  transition: 0.5s;
  /* background: #fff; */
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 17, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar.hide_vertical_menu {
    left: -20%;
  }
}

@media (min-width: 1201px) {
  /* line 17, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar.hide_vertical_menu {
    left: -270px;
  }
}

@media (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar {
    left: -280px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    top: 0;
    width: 280px;
    background: #fff;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar {
    width: 20%;
  }
}

/* line 36, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .logo {
  margin: 30px;
  background: #fff;
  padding: 33px 30px;
  border-radius: 7px;
}

/* line 41, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .logo img {
  width: 100%;
}

@media (max-width: 991px) {
  /* line 36, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar .logo {
    padding: 30px;
  }
}

/* line 51, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle i {
  font-size: 18px;
  margin-right: 12px;
  color: #f8dbdd;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 57, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  padding: 10px 25px 10px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: .3s;
  position: relative;
  z-index: 0;
  border-top: 1px solid transparent;
  background: transparent;
}

/* line 72, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a::before {
  position: absolute;
  right: 0;
  background: #4D4F5C;
  width: 0px;
  top: 0;
  bottom: 0;
  content: '';
  opacity: 0;
  transition: .3s;
  right: 0px;
  visibility: hidden;
}

/* line 86, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a.current_active i {
  color: #4D4F5C;
}

/* line 89, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a.current_active::before {
  opacity: 1;
  width: 3px;
  visibility: visible;
}

/* line 95, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a:hover::before {
  opacity: 1;
  right: 0;
  visibility: visible;
  width: 3px;
}

/* line 101, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a.normal_title {
  padding: 28px 25px 18px 40px;
  background: #fff7f8;
  border-top: 1px solid #eee1e2;
}

/* line 105, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a.normal_title:after {
  position: absolute;
  right: 40px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f0d8";
  font-family: "Font Awesome 5 Free";
  color: #cec1c2;
  font-weight: 600;
  font-size: 14px;
}

/* line 116, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a.normal_title.collapsed {
  padding: 10px 25px 10px 40px;
  background: #fff;
  border-top: 1px solid transparent;
}

/* line 120, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle a.normal_title.collapsed:after {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  color: #cec1c2;
  font-weight: 600;
  font-size: 14px;
}

/* line 134, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle:hover {
  background-color: #192434;
}

/* line 136, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_sidebar_tittle:hover i {
  color: #4D4F5C;
}

/* line 141, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_menu_item {
  padding-left: 36px;
  margin: 6px 0;
  line-height: 21px;
}

/* line 145, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .single_menu_item:hover {
  padding-left: 55px !important;
}

/* line 151, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .sidebar_iner ul li {
  list-style: none;
}

/* line 153, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .sidebar_iner ul li .submenu {
  background: #fff7f8;
  padding: 0px 0px 0px 70px !important;
  border-bottom: 1px solid #eee1e2;
}

/* line 158, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .sidebar_iner ul li .submenu li a {
  font-size: 13px;
  line-height: 36px;
  color: #7e7172;
  font-weight: 400;
}

/* line 164, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar .sidebar_iner ul li .submenu li:last-child {
  padding-bottom: 18px;
}

@media (max-width: 991px) {
  /* line 177, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .active_sidebar {
    left: 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }
}

/* line 182, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_close_icon i {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  position: absolute;
  right: 30px;
  font-size: 25px;
  top: 29px;
  cursor: pointer;
  color: #222222;
}

/* line 197, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .white_box {
  padding: 30px;
}

@media (max-width: 991px) {
  /* line 197, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar_widget .white_box {
    padding: 25px 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* line 197, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar_widget .white_box {
    padding: 15px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  /* line 197, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar_widget .white_box {
    padding: 25px 15px;
  }
}

/* line 208, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .white_box .btn_2 {
  margin-top: 25px;
  width: 100%;
  text-align: center;
  padding: 16px 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 208, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar_widget .white_box .btn_2 {
    padding: 12px 7px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* line 217, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar_widget .white_box .btn_1 {
    padding: 8px 10px;
    font-size: 11px;
  }
}

/* line 224, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .date_range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0 18px;
}

/* line 229, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .date_range .btn_3 {
  border-radius: 5px;
  font-size: 13px;
  font-weight: 300;
}

/* line 234, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .date_range i {
  display: none;
}

/* line 237, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .date_range .start_date, .sidebar_widget .date_range .end_date {
  width: 115px;
}

/* line 239, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .date_range .start_date input, .sidebar_widget .date_range .end_date input {
  width: 100%;
  border: 1px solid #e4e8ec;
  color: #7e7172;
  font-size: 13px;
  padding: 11px 20px;
  border-radius: 5px;
  font-weight: 300;
}

/* line 248, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar_widget .date_range .start_date ::placeholder, .sidebar_widget .date_range .end_date ::placeholder {
  color: #7e7172;
}

/************ sidebar css end ************/
/* line 260, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li a {
  font-size: 15px;
  font-weight: 400;
  color: #676B84;
  padding: 15px 25px 15px 30px;
  background: #fff;
  transition: .3s;
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 26px auto;
  grid-gap: 15px;
  align-items: center;
  position: relative;
  background: transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 260, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar #sidebar_menu li a {
    padding: 18px 25px 18px 30px;
  }
}

/* line 291, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li a i {
  font-size: 15px;
  margin-right: 12px;
  color: #A5ADC6;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 304, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li a:hover::before {
  width: 6px;
  opacity: 1;
  visibility: visible;
}

/* line 313, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li.mm-active > a {
  color: #1F253A;
}

/* line 317, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li.mm-active.metis_dropmenu > a {
  padding-top: 29px;
  padding-bottom: 30px;
  color: #A5ADC6;
}

/* line 324, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li.mm-active > a i {
  color: #A5ADC6;
}

/* line 327, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li.mm-active > a::before {
  opacity: 1;
  width: 6px;
  visibility: visible;
}

/* line 334, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li ul {
  padding: 0px 0px 0px 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 334, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .sidebar #sidebar_menu li ul {
    padding: 0px 0px 0px 55px;
  }
}

/* line 342, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li ul li:last-child a {
  padding-bottom: 30px;
}

/* line 346, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li ul li a {
  font-size: 13px;
  color: #676B84;
  font-weight: 400;
  padding: 0;
  white-space: nowrap;
  padding: 0;
  padding: 10px 0 !important;
}

/* line 354, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li ul li a::before {
  display: none;
}

/* line 357, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li ul li a.active {
  color: #1F253A;
}

/* line 361, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar #sidebar_menu li ul li ul {
  padding-left: 5px;
}

/* line 371, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap {
  margin-bottom: 30px;
}

/* line 375, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li {
  background: #fff7f8;
  border-top: 1px solid #eee1e2;
  border-bottom: 1px solid #eee1e2;
  padding: 30px 0 30px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 375, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .admin_profile_Wrap ul li {
    padding: 30px 0 30px 20px;
  }
}

/* line 386, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li a {
  display: block;
}

/* line 391, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li a .admin_profile_inner .thumb img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* line 397, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext {
  margin-left: 15px;
}

/* line 399, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext h5 {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 1px;
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 399, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext h5 {
    font-size: 12px;
  }
}

/* line 409, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext span {
  display: block;
  font-size: 12px;
  color: #7e7172;
  font-weight: 300;
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 409, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .admin_profile_Wrap ul li a .admin_profile_inner .welcome_rext span {
    font-size: 11px;
  }
}

/* line 422, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li ul {
  padding-left: 70px;
}

/* line 425, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li ul li {
  padding: 0;
  border: 0;
}

/* line 428, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li ul li a {
  font-size: 13px;
  color: #7e7172;
  font-weight: 400;
  padding-bottom: 17px;
}

/* line 435, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li ul li:last-child a {
  padding-bottom: 0;
}

/* line 440, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap ul li ul li:first-child a {
  margin-top: 29px;
}

/* line 448, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.admin_profile_Wrap .metismenu .has-arrow::after {
  top: 38%;
}

/* line 455, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background-color: #ddd;
}

/* line 460, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 6px;
  background-color: #ddd;
}

/* line 465, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
.sidebar::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: #f8dbdd;
}

/* line 471, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
body::-webkit-scrollbar {
  width: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 478, G:/admin_project/2 admin/finance/scss/_sidebar.scss */
  .metismenu .has-arrow::after {
    right: 25px;
  }
}

/* line 1, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.body_content {
  display: flex;
}

/* line 6, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.main-title h3 {
  font-family: "Muli", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #4D4F5C;
  line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1480px) {
  /* line 6, G:/admin_project/2 admin/finance/scss/_body_content.scss */
  .main-title h3 {
    font-size: 13px;
  }
}

/* line 16, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.main-title span {
  font-size: 12px;
  font-weight: 400;
  color: #A3A0FB;
  display: block;
  margin-top: 6px;
}

/* line 24, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.box_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}

@media (max-width: 575.98px) {
  /* line 29, G:/admin_project/2 admin/finance/scss/_body_content.scss */
  .box_header.box_header_block {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media (max-width: 575.98px) {
  /* line 34, G:/admin_project/2 admin/finance/scss/_body_content.scss */
  .box_header.box_header_block .main-title {
    margin-bottom: 15px;
  }
}

/* line 41, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.box_header .title_info p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #a3a0fb;
}

/* line 51, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.legend_style li {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  margin-right: 10px;
}

/* line 57, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.legend_style li span {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #4400eb;
  margin-right: 4px;
}

/* line 65, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.legend_style li.inactive {
  opacity: .29;
}

/* line 69, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.legend_style.legend_style_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}

/* line 73, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.legend_style.legend_style_grid li {
  margin: 0 !important;
}

/* line 78, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.title_btn {
  margin-bottom: 20px;
}

/* line 81, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.title_btn ul li {
  display: inline-block;
}

/* line 83, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.title_btn ul li a {
  display: inline-block;
  padding: 4px;
  background: #fff;
  font-size: 11px;
  text-transform: capitalize;
  color: #706F9A;
  border-radius: 5px;
  line-height: 25px;
  padding: 0 10px;
  transition: .3s;
}

/* line 94, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.title_btn ul li a:hover {
  background: #A66DD4;
  color: #fff;
}

/* line 98, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.title_btn ul li a.active {
  background: #A66DD4;
  color: #fff;
}

/* line 108, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.radial_footer .radial_footer_inner .left_footer {
  padding-left: 10px;
  margin-bottom: 20px;
}

/* line 111, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.radial_footer .radial_footer_inner .left_footer h5 {
  font-size: 13px;
  color: #43425D;
  font-weight: 500;
  margin-bottom: 0px;
  position: relative;
}

/* line 117, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.radial_footer .radial_footer_inner .left_footer h5 span {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #A4A1FB;
  display: inline-block;
  position: absolute;
  left: -15px;
  top: 6px;
}

/* line 128, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.radial_footer .radial_footer_inner .left_footer p {
  font-size: 12px;
  color: #4D4F5C;
  opacity: .5;
  font-weight: 400;
  margin-bottom: 0;
}

/* line 137, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.radial_footer .radial_bottom {
  border-top: 1px solid #EFEFEF;
  text-align: center;
  padding-top: 15px;
  margin-top: 4px;
}

/* line 143, G:/admin_project/2 admin/finance/scss/_body_content.scss */
.radial_footer .radial_bottom p a {
  font-size: 12px;
  color: #3B86FF;
  font-weight: 600;
}

/******** main content css here *********/
/* line 2, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.main_content {
  padding-left: 270px;
  width: 100%;
  padding-top: 0px !important;
  transition: .5s;
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;
  overflow: hidden;
}

/* line 12, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.main_content.main_content_padding_hide {
  padding-left: 0px;
  padding-top: 100px !important;
}

@media (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content {
    padding: 90px 0 100px 0;
  }
}

@media (max-width: 575.98px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content {
    padding: 120px 0 100px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content {
    padding: 120px 0 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content {
    padding: 120px 0 100px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content {
    padding-left: 20%;
  }
}

/* line 33, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.main_content .main_content_iner {
  min-height: 68vh;
  transition: .5s;
  margin: 30px;
}

@media (max-width: 991px) {
  /* line 33, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content .main_content_iner {
    margin: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 42, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content .main_content_iner.main_content_iner_padding {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  /* line 42, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content .main_content_iner.main_content_iner_padding {
    padding: 0 60px 0 60px;
  }
}

@media (min-width: 1680px) {
  /* line 42, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content .main_content_iner.main_content_iner_padding {
    padding: 0 135px 0 135px;
  }
}

@media (max-width: 575.98px) {
  /* line 33, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .main_content .main_content_iner {
    margin-bottom: 20px;
  }
}

/* line 62, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.address_book_hint p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 8px;
}

/* line 69, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element h4 {
  margin-bottom: 30px;
}

/* line 72, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .quick_activity_wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 50px;
}

@media (max-width: 575.98px) {
  /* line 72, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 72, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  /* line 72, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  /* line 72, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 72, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .single_element .quick_activity_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* line 100, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity {
  background-color: #fff;
  border-radius: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 41px 30px;
  position: relative;
  background: #3B76EF;
}

/* line 107, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity:nth-child(2) {
  background: #63C7FF;
}

/* line 110, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity:nth-child(3) {
  background: #A66DD4;
}

/* line 113, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity:nth-child(4) {
  background: #6DD4B1;
}

/* line 116, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: cover;
}

@media (max-width: 575.98px) {
  /* line 100, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .single_element .single_quick_activity {
    padding: 20px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 100, G:/admin_project/2 admin/finance/scss/_main_content.scss */
  .single_element .single_quick_activity {
    padding: 20px 15px;
  }
}

/* line 143, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity h4 {
  font-size: 17px;
  margin-bottom: 0;
  font-weight: 700;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #fff;
  margin-bottom: 12px;
}

/* line 151, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity h3 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
  font-weight: 900;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #fff;
  margin-bottom: 7px;
}

/* line 160, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity p {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}

/* line 166, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity:hover {
  background: #3B76EF;
}

/* line 168, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity:hover::before {
  background-image: url(../img/diamond.png);
}

/* line 174, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity:hover .quick_activity_icon {
  background-color: rgba(255, 247, 248, 0.2);
}

/* line 176, G:/admin_project/2 admin/finance/scss/_main_content.scss */
.single_element .single_quick_activity:hover .quick_activity_icon i {
  color: #ffffff;
}

/******** main content css end *********/
/* line 2, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.dashboard_part .notification_tab {
  padding: 27px 25px;
  background-color: #F5ECEC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 10, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.dashboard_part .notification_tab p {
  font-size: 16px;
  color: #222222;
}

/* line 13, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.dashboard_part .notification_tab p a {
  color: #4D4F5C;
}

/* line 18, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.dashboard_part .hide_icon {
  cursor: pointer;
}

/* line 20, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.dashboard_part .hide_icon i {
  color: #4D4F5C;
  font-size: 10px;
}

/* line 26, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.white_box {
  padding: 30px;
  background-color: rgba(247, 250, 255, 0.7);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

/* line 30, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.white_box.box_border {
  border: 1px solid rgba(202, 206, 213, 0.7);
}

@media (max-width: 575.98px) {
  /* line 26, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .white_box {
    padding: 30px 25px;
  }
}

/* line 37, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.white_box .white_box_tittle h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  /* line 37, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .white_box .white_box_tittle h4 {
    margin-bottom: 15px;
  }
}

/* line 47, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.white_box2 {
  padding: 40px 40px 25px 40px;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@media (max-width: 575.98px) {
  /* line 47, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .white_box2 {
    padding: 30px 25px;
  }
}

/* line 55, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.white_box2 .white_box_tittle h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  /* line 55, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .white_box2 .white_box_tittle h4 {
    margin-bottom: 15px;
  }
}

/* line 66, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .white_box_tittle {
  margin-bottom: 20px;
}

/* line 68, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .white_box_tittle h4 {
  margin-bottom: 2px;
}

@media (max-width: 991px) {
  /* line 68, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .chart_box .white_box_tittle h4 {
    margin-bottom: 15px;
  }
}

/* line 75, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box p {
  margin-bottom: 29px;
  color: #707070;
}

/* line 82, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box label {
  margin-bottom: 0;
}

/* line 85, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .b {
  display: block !important;
}

/* line 88, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .toggle {
  position: relative;
  width: 44px;
  height: 22px;
  border-radius: 50px;
  background-color: #222222;
  border: 1px solid #222222;
  overflow: hidden;
}

/* line 97, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

/* line 108, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .check:checked ~ .switch {
  right: 5px;
  left: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0.08s, 0s;
}

/* line 115, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .switch {
  position: absolute;
  left: 5px;
  top: 3px;
  bottom: 2px;
  right: 57.5%;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.08s;
  height: 13px;
  width: 13px;
  padding: 6px !important;
  margin-right: 0;
}

/* line 131, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .switch:after {
  display: none;
}

/* line 135, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box .hide {
  display: none !important;
}

/* line 144, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table .table {
  background: #FFFFFF;
  box-shadow: 0px 10px 15px rgba(235, 215, 241, 0.22);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 35px;
  padding-top: 20px;
  padding-bottom: 40px;
}

/* line 153, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table th, .QA_section .QA_table td {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.9;
  color: #222222;
  padding: 16px 30px;
  vertical-align: middle;
}

/* line 161, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table .question_content {
  color: #7e7172;
  font-size: 13px;
}

/* line 164, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table .question_content:hover {
  color: #4D4F5C;
}

/* line 170, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table thead th {
  white-space: nowrap;
  border-bottom: 1px solid rgba(130, 139, 178, 0.3) !important;
}

/* line 176, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody th {
  font-size: 14px;
  color: #415094;
  font-weight: 400 !important;
}

/* line 182, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody th, .QA_section .QA_table tbody td {
  color: #7e7172;
  font-size: 14px;
  color: #828BB2;
  font-weight: 400;
  border-bottom: 1px solid rgba(130, 139, 178, 0.15);
}

/* line 188, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody th a, .QA_section .QA_table tbody td a {
  font-size: 14px;
  font-weight: 400;
  color: #828BB2;
}

/* line 192, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody th a:hover, .QA_section .QA_table tbody td a:hover {
  color: #7C32FF;
}

/* line 196, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody th.pending, .QA_section .QA_table tbody td.pending {
  color: #E09079;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

/* line 203, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody th.priority_hight, .QA_section .QA_table tbody td.priority_hight {
  font-size: 14px;
  color: #D7598F;
  font-weight: 400;
}

/* line 212, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody tr {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 219, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table tbody tr:hover {
  background: #fff;
}

/* line 225, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table .view_btn {
  color: #4D4F5C;
}

/* line 227, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table .view_btn:hover {
  text-decoration: underline;
}

/* line 231, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table .table {
  margin-bottom: 0 !important;
}

/* line 233, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.QA_section .QA_table .table thead th {
  border-bottom: 0 solid transparent;
  background-color: #fff;
  padding: 17px 30px;
  line-height: 16px;
  border: 0px solid transparent;
  font-size: 12px;
  font-weight: 400;
  color: #4D4F5C;
  white-space: nowrap;
  text-transform: uppercase;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 252, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .chart_box_1 .box_header {
    flex-direction: column;
  }
}

/* line 256, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_left {
  flex-basis: 50%;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  /* line 256, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .chart_box_1 .box_header .box_header_left {
    flex-basis: 55%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 256, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .chart_box_1 .box_header .box_header_left {
    flex-basis: 55%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 256, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .chart_box_1 .box_header .box_header_left {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}

/* line 269, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_left ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* line 273, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_left ul li {
  display: inline-block;
  text-align: center;
}

@media (max-width: 575.98px) {
  /* line 273, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .chart_box_1 .box_header .box_header_left ul li {
    flex: 50% 0 0;
    margin-bottom: 20px;
  }
}

/* line 280, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_left ul li h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
}

/* line 285, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_left ul li p {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0;
}

/* line 296, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_right .legend_circle ul li {
  color: #828BB2;
  font-size: 12px;
  font-weight: 400;
  margin-right: 30px;
  display: inline-block;
}

@media (max-width: 575.98px) {
  /* line 296, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
  .chart_box_1 .box_header .box_header_right .legend_circle ul li {
    margin-right: 14px;
  }
}

/* line 305, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_right .legend_circle ul li:last-child {
  margin-right: 0;
}

/* line 308, G:/admin_project/2 admin/finance/scss/_dashboard_part.scss */
.chart_box_1 .box_header .box_header_right .legend_circle ul li span {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

/* line 4, G:/admin_project/2 admin/finance/scss/_form_style.scss */
.form_style .form-group label, .form_style .form-group p {
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 13px;
  color: #7e7172;
}

/* line 11, G:/admin_project/2 admin/finance/scss/_form_style.scss */
.form_style .form-group input {
  border: 1px solid #e4e8ec;
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  padding: 15px 25px;
  background-color: #fef1f2;
  height: auto;
  line-height: 18px;
}

/* line 2, G:/admin_project/2 admin/finance/scss/_revenue_part.scss */
.revenue_part .btn_2 {
  padding: 8px 13px;
  margin-left: 30px;
}

/* line 7, G:/admin_project/2 admin/finance/scss/_revenue_part.scss */
.revenue_part .courses_price .btn_1 {
  padding: 13px 32px;
  line-height: 16px;
  height: auto;
  width: auto;
}

/* line 15, G:/admin_project/2 admin/finance/scss/_revenue_part.scss */
.revenue_part .courses_details span {
  font-size: 16px;
  font-weight: 600;
  color: #7e7172;
  margin-top: 3px;
}

/* line 23, G:/admin_project/2 admin/finance/scss/_revenue_part.scss */
.revenue_part .courses_duration {
  position: relative;
  padding-left: 25px;
}

/* line 26, G:/admin_project/2 admin/finance/scss/_revenue_part.scss */
.revenue_part .courses_duration h6 {
  font-size: 14px;
  margin-bottom: 8px;
}

/* line 29, G:/admin_project/2 admin/finance/scss/_revenue_part.scss */
.revenue_part .courses_duration h6 i {
  color: #4D4F5C;
  position: absolute;
  left: 0;
  top: 2px;
}

/* line 2, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

/* line 7, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_mathord_content {
  background-color: #fff;
  padding: 30px;
}

/* line 10, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_mathord_content h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 28px 0 7px;
}

/* line 15, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_mathord_content .btn_1 {
  margin-top: 28px;
}

/* line 19, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .nav-tabs {
  border-bottom: 0 solid transparent;
}

/* line 22, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_methord_logo {
  display: flex;
  align-items: center;
}

/* line 26, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_methord_logo .single_logo_iner {
  width: 188px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #BBC1C9;
  border-left: 0px solid transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 35, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_methord_logo .single_logo_iner:first-child {
  border-left: 1px solid #BBC1C9;
  border-radius: 5px 0 0 5px;
}

/* line 39, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_methord_logo .single_logo_iner:last-child {
  border-radius: 0 5px 5px 0;
}

/* line 42, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_methord_logo .single_logo_iner img {
  max-width: 100px;
}

/* line 45, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.payment_methord .payment_methord_logo .single_logo_iner.active {
  border: 1px solid #4D4F5C !important;
}

/* line 54, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.form_group {
  margin-top: 24px;
}

/* line 56, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.form_group input {
  width: 100%;
  background-color: #fef1f2;
  border-radius: 5px;
  border: 1px solid #F1F3F5;
  padding: 12px 20px;
}

/* line 64, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.form_group .single_form_item label {
  width: 100%;
  display: block;
}

/* line 68, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.form_group .single_form_item input {
  width: auto;
  display: inline-block;
  padding: 12px 20px;
  max-width: 85px;
  color: #9C9C9C;
}

/* line 76, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.form_group #CVV {
  max-width: 118px !important;
}

/* line 79, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.form_group .nice_Select {
  background-color: #fef1f2;
  margin-right: 10px;
  color: #9C9C9C;
}

/* line 86, G:/admin_project/2 admin/finance/scss/_payment_setting.scss */
.checkbox label {
  margin-bottom: 0;
  margin-top: 25px;
  color: #9C9C9C;
  margin-left: 8px;
}

/************** footer css ****************/
/* line 2, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_part {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 6px;
  background: transparent;
  padding-left: 270px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_footer.scss */
  .footer_part {
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_footer.scss */
  .footer_part {
    padding-left: 20%;
  }
}

/* line 22, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_part p {
  font-size: 14px;
  font-weight: 300;
  color: #828BB2;
}

/* line 26, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_part p span {
  margin: 0 10px;
}

/* line 29, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_part p a {
  color: #3B76EF;
}

/* line 31, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_part p a:hover {
  text-decoration: none;
}

/* line 36, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_part .footer_iner {
  padding: 0 15px;
}

@media (max-width: 575.98px) {
  /* line 36, G:/admin_project/2 admin/finance/scss/_footer.scss */
  .footer_part .footer_iner {
    text-align: center;
  }
}

/* line 43, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_links {
  padding: 0 15px;
}

@media (max-width: 575.98px) {
  /* line 45, G:/admin_project/2 admin/finance/scss/_footer.scss */
  .footer_links ul {
    justify-content: center !important;
    margin-top: 15px;
  }
}

/* line 51, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_links ul li a {
  color: #3B76EF;
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
  text-transform: capitalize;
}

/* line 59, G:/admin_project/2 admin/finance/scss/_footer.scss */
.footer_links ul li:first-child a {
  margin-left: 0;
}

/************** footer css end ****************/
/********* breadcrumd css here **********/
/* line 2, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content {
  background-color: #fff;
  padding: 19px 30px;
  line-height: 22px;
}

@media (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
  .breadcrumb_content {
    padding: 25px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
  .breadcrumb_content {
    display: block !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
  .breadcrumb_content {
    display: block !important;
  }
}

/* line 17, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .active {
  color: #4D4F5C;
}

/* line 20, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  /* line 20, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
  .breadcrumb_content h2 {
    margin-bottom: 15px;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 20, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
  .breadcrumb_content h2 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 20, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
  .breadcrumb_content h2 {
    margin-bottom: 15px;
  }
}

/* line 36, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a {
  position: relative;
  color: #7e7172;
  padding-left: 30px;
}

/* line 40, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 3px;
  clip-path: polygon(0 0, 100% 55%, 0 100%);
  background-color: #7e7172;
  width: 10px;
  height: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 51, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a:first-child {
  padding-left: 0;
}

/* line 53, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a:first-child:after {
  display: none;
}

/* line 57, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a:hover {
  color: #4D4F5C;
}

/* line 58, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a:hover:after {
  background-color: #4D4F5C;
}

/* line 63, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a:last-child {
  color: #4D4F5C;
}

/* line 65, G:/admin_project/2 admin/finance/scss/_breadcrumb.scss */
.breadcrumb_content .sa_breadcrumb_iner a:last-child:after {
  background-color: #4D4F5C;
}

/********* breadcrumd css end **********/
/* line 1, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
#morris_bar {
  height: 300px;
}

/* line 4, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
#morris_bar_bold {
  height: 300px;
}

/* line 8, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
tspan {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  text-transform: capitalize;
}

/* line 15, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
  font-size: 13px;
  color: #fff !important;
  font-size: 12px;
  font-weight: 500;
  font-family: "Muli", sans-serif;
}

/* line 25, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  line-height: 12px;
  font-family: "Muli", sans-serif;
}

/* line 35, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
.morris-hover.morris-default-style {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-align: center;
  background: #000 !important;
  border: 0;
  padding: 7px 22px 5px 22px !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
}

/* line 47, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
.morris-hover.morris-default-style span {
  font-weight: bold;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  line-height: 12px;
  font-family: "Muli", sans-serif;
}

/* line 58, G:/admin_project/2 admin/finance/scss/_bar-chart.scss */
canvas#highlights {
  height: 300px !important;
  overflow: hidden;
}

/* line 1, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
.lms_category_wrap {
  margin-bottom: 50px;
}

/* line 3, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
.lms_category_wrap .category_header {
  background: #fef1f2;
  border-radius: 5px;
  padding: 17px 30px;
}

/* line 8, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
.lms_category_wrap .category_header .category_left h4 {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  margin-bottom: 0;
}

/* line 14, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
.lms_category_wrap .category_header .category_left p {
  color: #7e7172;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  /* line 21, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
  .lms_category_wrap .category_header .category_right {
    margin-top: 15px;
  }
}

/* line 28, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
.lms_category_wrap ul li {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 15px 30px;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid #eee1e2;
  transition: .3s;
}

@media (max-width: 575.98px) {
  /* line 28, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
  .lms_category_wrap ul li {
    padding: 15px 20px;
  }
}

/* line 41, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
.lms_category_wrap ul li .category_info p {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
}

/* line 47, G:/admin_project/2 admin/finance/scss/_lms_category.scss */
.lms_category_wrap ul li:hover {
  background: #fff7f8;
}

/* line 1, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame {
  border: 1px solid #eee1e2;
}

/* line 3, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-popover .note-popover-content, .note-editor.note-frame .note-toolbar {
  padding: 4px 15px 8px 15px;
  margin: 0;
  border-radius: 5px 5px 0 0;
  background-color: #fff7f8;
}

/* line 12, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-btn i, .note-editor.note-frame .note-current-fontname {
  color: #7e7172;
  font-size: 12px;
}

/* line 17, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-btn {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  color: #7e7172;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}

/* line 26, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-btn:hover {
  color: #fff;
  background: #fe1724;
}

/* line 30, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-btn:hover i {
  color: #fff;
  background: #fe1724;
}

/* line 34, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-btn .note-current-fontname:hover {
  color: #fff;
}

/* line 39, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .show .dropdown-toggle::after {
  transform: rotate(180deg);
  color: red;
}

/* line 43, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-icon-font.note-recent-color {
  background: transparent !important;
  color: #7e7172 !important;
}

/* line 48, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .show .dropdown-toggle::after {
  color: #7e7172 !important;
}

/* line 51, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-btn:hover span {
  color: #fff !important;
}

/* line 54, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-btn:hover i {
  color: #fff !important;
}

/* line 57, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-editing-area .note-editable {
  background-color: #fff;
}

/* line 61, G:/admin_project/2 admin/finance/scss/_summer_note.scss */
.note-editor.note-frame .note-placeholder {
  padding: 18px 25px 0 25px;
  font-size: 14px;
  color: #7e7172;
  line-height: 28px;
  font-weight: 300;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 1, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .lms_sm_block {
    display: block;
  }
}

@media (max-width: 575.98px) {
  /* line 8, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .lms_xs_small_btn a {
    padding: 11px 20px;
  }
}

@media (max-width: 575.98px) {
  /* line 14, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .mb_xs_20px {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 19, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .mb_sm_20px {
    margin-bottom: 20px;
  }
}

/* line 24, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.lms_supper_text {
  vertical-align: super !important;
}

/* line 27, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.gj-picker-md {
  border: 1px solid #fef1f2;
  padding: 10px;
}

/* line 31, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.gj-picker-md table tr td.today div {
  color: #fff;
  background: #3B76EF;
  border-radius: 50%;
}

/* line 36, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.gj-picker-md table tr td.gj-cursor-pointer div:hover {
  border-radius: 50%;
  background: #3B76EF;
  color: #fff;
}

/* line 42, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.gj-picker-md div[role="navigator"] div:first-child, .gj-picker-md div[role="navigator"] div:last-child {
  max-width: 42px;
  background: #fef1f2;
  color: #000;
  border-radius: 50%;
  font-size: 14px;
  line-height: 44px;
  height: 42px;
  font-weight: 900;
}

/* line 51, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.gj-picker-md div[role="navigator"] div:first-child i, .gj-picker-md div[role="navigator"] div:last-child i {
  font-weight: 900;
  font-size: 14px;
}

/* line 60, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.dropdown-menu.show .dropdown-toggle.lms_drop_1::after {
  color: #fff !important;
}

/* line 64, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.dropdown-menu.option_width {
  min-width: 102px;
}

/* line 68, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.dropdown.show .dropdown-toggle.lms_drop_1::after {
  color: #fff;
}

/* line 75, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.lms_common_header .lms_common_title h4 {
  margin-bottom: 3px;
}

/* line 78, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.lms_common_header .lms_common_title p {
  font-size: 12px;
  font-weight: 300;
  color: #7e7172;
  margin-bottom: 0;
}

/* line 87, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.common_line_separator {
  display: inline-block;
  width: 2px;
  height: 11px;
  background: #b9b3b3;
  margin: 0 7px 0 7px;
}

/* line 94, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.h-120px {
  height: 120px !important;
}

/* line 97, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.h-260px {
  height: 260px !important;
}

@media (max-width: 991px) {
  /* line 97, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .h-260px {
    height: 160px !important;
  }
}

/* line 103, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.h-230px {
  height: 230px !important;
}

@media (max-width: 991px) {
  /* line 103, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .h-230px {
    height: 160px !important;
  }
}

/* line 111, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.discription_list_wrap p {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  line-height: 26px;
  margin-bottom: 17px;
}

/* line 118, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.discription_list_wrap h5 {
  font-size: 12px;
  font-weight: 400;
  color: #222222;
  margin-top: 19px;
  margin-bottom: 14px;
}

/* line 128, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.common_list_style li {
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  line-height: 26px;
  position: relative;
  padding-left: 15px;
}

/* line 135, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.common_list_style li:before {
  position: absolute;
  left: 0;
  top: 10px;
  width: 3px;
  height: 3px;
  background: #7e7172;
  content: '';
  border-radius: 50%;
}

/* line 150, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.result_modal_table_form .modal-body {
  margin: 30px 0;
  padding: 120px 100px 104px 100px !important;
}

@media (max-width: 991px) {
  /* line 150, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .result_modal_table_form .modal-body {
    padding: 30px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 150, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .result_modal_table_form .modal-body {
    padding: 50px 50px !important;
  }
}

/* line 163, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.result_modal_table_form .table thead th {
  border-bottom: 0 solid transparent;
  background-color: #fef1f2;
  padding: 17px 30px;
  line-height: 16px;
  border: 0px solid transparent;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #222222;
  white-space: nowrap;
}

/* line 179, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.result_modal_table_form .table tbody th, .result_modal_table_form .table tbody td {
  color: #7e7172;
  font-size: 13px !important;
  color: #7e7172;
  font-weight: 300;
  border-bottom: 1px solid #eee1e2;
  padding: 10px 25px !important;
}

/* line 189, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.result_modal_table_form .result_header_modal {
  margin-bottom: 45px;
}

/* line 192, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.result_modal_table_form .result_header_modal h2 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 15px;
}

@media (max-width: 575.98px) {
  /* line 192, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .result_modal_table_form .result_header_modal h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 192, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .result_modal_table_form .result_header_modal h2 {
    font-size: 20px;
  }
}

/* line 204, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.result_modal_table_form .result_header_modal p {
  font-size: 14px;
  font-weight: 300;
  color: #7e7172;
  line-height: 26px;
}

/* line 210, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.result_modal_table_form .result_header_modal .passed_text {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-top: 29px;
  margin-bottom: 35px;
}

@media (max-width: 575.98px) {
  /* line 210, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .result_modal_table_form .result_header_modal .passed_text {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 210, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .result_modal_table_form .result_header_modal .passed_text {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 210, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .result_modal_table_form .result_header_modal .passed_text {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

/* line 237, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.student_list_img img {
  width: 50px;
  height: 50px;
}

/* line 243, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.common_date_picker input {
  width: 100%;
  border: 1px solid #eee1e2;
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  color: #7e7172;
  padding: 0px 25px;
  font-weight: 300;
}

/* line 256, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.website_settings_wrap .modal-body {
  padding: 0 !important;
}

/* line 260, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.input_form_persent {
  position: relative;
}

/* line 262, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.input_form_persent:before {
  content: "\f295";
  border-bottom: 0;
  border-right: 0;
  display: block;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: auto;
  right: 25px;
  border: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #cec1c2;
  transform: translateY(-50%);
  font-size: 13px;
}

/* line 286, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field {
  margin-bottom: 21px;
}

/* line 288, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}

/* line 298, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field .bootstrap-tagsinput {
  text-align: left;
  border: 1px solid #eee1e2;
  padding: 9px 25px 4px 25px;
  box-shadow: none;
  border-radius: 3px;
  min-height: 50px;
}

/* line 305, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field .bootstrap-tagsinput input {
  border: none !important;
  width: auto !important;
  display: inline-block !important;
  background-color: transparent !important;
  height: 0;
  margin: 0;
  min-width: 20px;
  padding: 7px !important;
}

/* line 316, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field .bootstrap-tagsinput .badge {
  margin: 0 5px 5px 0;
  height: 30px;
  line-height: 30px;
  padding: 0 30px 0 20px;
  font-size: 12px;
  color: #3B76EF;
  font-weight: 400;
  background: #ffff;
  border: 1px solid #3B76EF;
  border-radius: 30px;
  position: relative;
}

/* line 329, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field .bootstrap-tagsinput .badge [data-role="remove"]::after {
  font-size: 10px;
  font-family: 'themify';
  content: "\e646";
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 0px;
  background: transparent;
  margin-left: 5px;
  padding: 0;
  border: 1px solid transparent;
  padding: 2px;
}

/* line 343, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field .bootstrap-tagsinput .badge [data-role="remove"]:hover::after {
  background: #3B76EF;
  color: #fff;
  border-color: #3B76EF;
}

/* line 348, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.tagInput_field span.badge.badge-info span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

/* line 357, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_striped_progressbar .progress {
  height: 24px;
  background: transparent;
  border-radius: 30px;
  width: 256px;
}

/* line 364, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_striped_progressbar .progress-bar {
  position: relative;
  border-radius: 30px;
  text-align: left;
  font-size: 15px;
  padding-left: 15px;
  color: #fff;
  font-weight: 300;
  background: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* line 375, G:/admin_project/2 admin/finance/scss/_common_component.scss */
/*.custom_striped_progressbar .progress-bar.green_bar {
  background-image: url(../img/progress/progress-bg.png);
  background-size: cover;
}*/

/* line 379, G:/admin_project/2 admin/finance/scss/_common_component.scss */
/*.custom_striped_progressbar .progress-bar.yellow_bar {
  background-image: url(../img/progress/design-progress-yellow.png);
  background-size: cover;
}*/

/* line 384, G:/admin_project/2 admin/finance/scss/_common_component.scss */
/*.custom_striped_progressbar .progress-bar.red_bar {
  background-image: url(../img/progress/design-progress-red.png);
  background-size: cover;*/
/*}*/

/* line 394, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  color: #7e7172;
  margin-left: 0;
  padding-bottom: 15px;
  margin-top: 0;
}

/* line 405, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 label span {
  display: inline-block;
  position: relative;
  background-color: transparent;
  width: 20px;
  height: 20px;
  transform-origin: center;
  border: 1px solid #eee2e3;
  border-radius: 50%;
  vertical-align: -6px;
  margin-right: 15px;
  transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}

/* line 419, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 label span:before {
  content: "";
  width: 0px;
  height: 1px;
  border-radius: 2px;
  background: #3B76EF;
  position: absolute;
  transform: rotate(38deg);
  top: 13px;
  left: 9px;
  transition: width 50ms ease 50ms;
  transform-origin: 0% 0%;
  top: 9.69px;
  left: 4.99px;
  border-radius: 0;
}

/* line 436, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 label span:after {
  content: "";
  width: 0;
  height: 1px;
  border-radius: 2px;
  background: #3B76EF;
  position: absolute;
  transform: rotate(305deg);
  top: 12.8px;
  left: 7.48px;
  transition: width 50ms ease;
  transform-origin: 0% 0%;
  border-radius: 0;
}

/* line 453, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 label:hover span:before {
  width: 4px;
  transition: width 100ms ease;
}

/* line 458, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 label:hover span:after {
  width: 10px;
  transition: width 150ms ease 100ms;
}

/* line 465, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 input[type="radio"] {
  display: none;
}

/* line 469, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 input[type="radio"]:checked + label span {
  background-color: #3B76EF;
  transform: scale(1);
  border-color: #3B76EF;
}

/* line 474, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 input[type="radio"]:checked + label span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

/* line 480, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 input[type="radio"]:checked + label span:before {
  width: 4px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

/* line 487, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 input[type="radio"]:checked + label:hover span {
  background-color: #3B76EF;
  transform: scale(1.25);
  border-color: #3B76EF;
}

/* line 492, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 input[type="radio"]:checked + label:hover span:after {
  width: 10px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

/* line 497, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.custom_lms_radio_2 input[type="radio"]:checked + label:hover span:before {
  width: 4px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

/* line 510, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.import_topic_wrapper.modal_btn {
  display: grid !important;
  grid-gap: 10px;
  grid-template-columns: 3fr 9fr;
  align-items: end;
}

/* line 515, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.import_topic_wrapper.modal_btn .btn_1 {
  margin: 0 !important;
}

@media (max-width: 575.98px) {
  /* line 510, G:/admin_project/2 admin/finance/scss/_common_component.scss */
  .import_topic_wrapper.modal_btn {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* line 528, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .btn_1.big_btn {
  padding: 16px 36px;
}

/* line 531, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .chose_thumb_title {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}

/* line 542, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .input_wrap label {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
  text-align: left;
  margin: 0;
  margin-bottom: 0px;
  display: block;
  margin-bottom: 8px;
}

/* line 552, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .input_wrap textarea {
  height: 100px;
  padding: 0;
  line-height: 28px;
  padding: 13px 25px;
}

/* line 557, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .input_wrap textarea.max_textarea {
  height: 290px;
}

/* line 563, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .nice_Select, .indvisual_form input, .indvisual_form textarea {
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  border: 1px solid #eee1e2;
  padding: 10px 25px;
  color: #7e7172;
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  display: block;
  margin-bottom: 21px;
  font-weight: 300;
  border-radius: 3px;
}

/* line 577, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .nice_Select::placeholder, .indvisual_form input::placeholder, .indvisual_form textarea::placeholder {
  color: #7e7172;
  font-weight: 300;
  opacity: 1;
}

/* line 583, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .nice_Select {
  line-height: 30px;
}

/* line 585, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .nice_Select :after {
  left: 22px;
}

/* line 589, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .nice-select.nice_Select2 {
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #eee1e2;
  padding: 0 37px 0 20px;
  color: #7e7172;
  font-weight: 500;
  font-size: 13px;
  width: 100%;
  display: block;
  margin-bottom: 21px;
  font-weight: 300;
  border-radius: 3px;
  margin: 0;
  width: auto;
}

/* line 605, G:/admin_project/2 admin/finance/scss/_common_component.scss */
.indvisual_form .nice-select.nice_Select2::after {
  top: 20%;
}

/* line 2, G:/admin_project/2 admin/finance/scss/_projects.scss */
.assign_list li {
  display: inline-block;
  margin-right: 5px;
}

/* line 5, G:/admin_project/2 admin/finance/scss/_projects.scss */
.assign_list li:last-child {
  margin-right: 0;
}

/* line 9, G:/admin_project/2 admin/finance/scss/_projects.scss */
.assign_list li a img {
  width: 100%;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 23px;
  height: 23px;
  transform: translateY(0px);
}

/* line 17, G:/admin_project/2 admin/finance/scss/_projects.scss */
.assign_list li a img:hover {
  transform: translateY(-2px);
}

/* line 27, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_paginate {
  margin-top: 0;
  margin-top: 30px;
}

/* line 30, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_paginate a {
  width: 32px;
  height: 32px;
  background: #FFFFFF !important;
  border-radius: 3px !important;
  text-align: center !important;
  line-height: 32px;
  padding: 0 !important;
  margin: 0 !important;
  margin: 0 5px !important;
}

/* line 40, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_paginate a.current {
  background: #3B76EF !important;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.3) !important;
  border: 0 !important;
  color: #fff !important;
}

/* line 46, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_paginate a:hover {
  background: #3B76EF !important;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.3);
  border: 0 !important;
  color: #fff !important;
}

/* line 51, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_paginate a:hover i {
  color: #fff !important;
}

/* line 55, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_paginate a i {
  line-height: 32px;
  font-size: 12px;
  color: #415094;
}

/* line 62, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #fff !important;
  border: 0;
}

/* line 67, G:/admin_project/2 admin/finance/scss/_projects.scss */
#DataTables_Table_0_info {
  font-size: 14px;
  color: #415094;
  font-weight: 400;
  margin-top: 35px;
  padding-top: 0;
}

/* line 74, G:/admin_project/2 admin/finance/scss/_projects.scss */
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
  border: 1px solid #3B76EF;
  background-color: #3B76EF !important;
}

/* line 1, G:/admin_project/2 admin/finance/scss/_pie.scss */
.min_400 {
  height: calc(100% - 30px);
}

/* line 4, G:/admin_project/2 admin/finance/scss/_pie.scss */
.min_430 {
  height: calc(100% - 30px);
}

/* line 7, G:/admin_project/2 admin/finance/scss/_pie.scss */
.mb-55 {
  margin-bottom: 55px;
}

/* line 4, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
}

/* line 11, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-picture {
  position: absolute;
  top: 240px;
  left: 50%;
  margin-left: -55px;
}

/* line 17, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-picture img {
  border: 5px solid #fff;
  border-radius: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  width: 100px;
  height: 100px;
}

/* line 25, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-cover-image {
  height: 300px;
  overflow: hidden;
}

/* line 29, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-cover-image img {
  width: 100%;
}

/* line 34, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-content {
  padding: 40px 20px;
  text-align: center;
}

/* line 38, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-content h1 {
  font-weight: normal;
  margin-bottom: 0.2em;
}

/* line 43, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-content p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #d2d2d2;
}

/* line 50, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-content .socials a {
  color: #d2d2d2;
  margin: 0 15px;
  font-size: 1.6em;
  transform: translateY(4px);
}

/* line 56, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_box_1 .profile-content .socials a:hover {
  color: #aaa;
  transform: translateY(0);
}

/* line 74, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile {
  background-color: #e6e5e1;
  border-radius: 0;
  border: 0;
  box-shadow: 1em 1em 2em rgba(0, 0, 0, 0.2);
}

/* line 80, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-img-top {
  border-radius: 0;
}

/* line 84, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-img-profile {
  max-width: 100%;
  border-radius: 50%;
  margin-top: -95px;
  margin-bottom: 35px;
  border: 5px solid #e6e5e1;
}

/* line 92, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-title {
  margin-bottom: 50px;
}

/* line 95, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-title small {
  display: block;
  font-size: .6em;
  margin-top: .2em;
}

/* line 102, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links {
  margin-bottom: 25px;
}

/* line 105, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa {
  margin: 0 1em;
  font-size: 1.6em;
}

/* line 109, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa:focus, .card-profile .card-links .fa:hover {
  text-decoration: none;
}

/* line 114, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa.fa-dribbble {
  color: #ea4b89;
  font-weight: bold;
}

/* line 118, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa.fa-dribbble:hover {
  color: #e51d6b;
}

/* line 123, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa.fa-twitter {
  color: #68aade;
}

/* line 126, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa.fa-twitter:hover {
  color: #3e92d5;
}

/* line 131, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa.fa-facebook {
  color: #3b5999;
}

/* line 134, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.card-profile .card-links .fa.fa-facebook:hover {
  color: #2d4474;
}

/* line 142, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px #222;
}

/* line 148, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4:hover .profile-img img {
  transform: scale(1.2);
}

/* line 152, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-img img {
  width: 100%;
  height: auto;
  transition: transform 1s;
}

/* line 157, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40%;
  border: 3px solid #333;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: scale(1, 1);
  transition: all 0.50s linear;
}

/* line 170, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 40%;
  border: 3px solid #222;
  border-left: 3px solid transparent;
  border-top: 3px solid transparent;
  transition: all 0.50s linear;
}

/* line 183, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4:hover .profile-content:before {
  top: 20px;
  left: 20px;
}

/* line 187, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4:hover .profile-content:after {
  bottom: 20px;
  right: 20px;
}

/* line 192, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50% 4%;
  text-align: center;
}

/* line 202, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content:hover {
  background-color: rgba(255, 43, 131, 0.5);
}

/* line 205, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content .title {
  font-size: 24px;
  color: #333;
  opacity: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  transform: translateY(-100px);
  transition: all 900ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}

/* line 214, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4:hover .title {
  opacity: 1;
  transform: translateY(0);
}

/* line 218, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4:hover .title::after {
  content: "";
  display: block;
  width: 50%;
  margin: 10px auto;
  border-bottom: 2px solid #222;
}

/* line 225, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content .title > span {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  text-transform: capitalize;
}

/* line 231, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content .social-link {
  margin: 20px 0;
  padding: 0;
  opacity: 0;
  transform: translateY(100px);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}

/* line 238, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4:hover .social-link {
  opacity: 1;
  transform: translateY(0);
}

/* line 242, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content .social-link li {
  display: inline-block;
  list-style: none;
  margin: 0 4px;
}

/* line 247, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-card-4 .profile-content .social-link li a {
  color: #333;
  width: 30px;
  height: 25px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
}

/* line 261, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card {
  height: 615px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  background-color: #f6fcfe;
  -webkit-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  -ms-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  -o-box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 20px 70px 0px rgba(0, 0, 0, 0.21);
  position: relative;
  overflow: hidden;
}

/****************
  Back
  ****************/
/* line 282, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -139px;
  font-weight: 600;
  z-index: 1;
}

/* line 292, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a {
  text-decoration: none;
}

/****************
  UP
  ****************/
/* line 300, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_up {
  position: absolute;
  width: 100%;
  height: 437px;
  background-image: url(../img/card2.jpg);
  background-position: 50%;
  background-size: cover;
  z-index: 3;
  text-align: center;
  -webkit-border-top-left-radius: 30px;
  -moz-border-top-left-radius: 30px;
  -ms-border-top-left-radius: 30px;
  -o-border-top-left-radius: 30px;
  border-top-left-radius: 30px;
  -webkit-border-top-right-radius: 30px;
  -moz-border-top-right-radius: 30px;
  -ms-border-top-right-radius: 30px;
  -o-border-top-right-radius: 30px;
  border-top-right-radius: 30px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

/* line 326, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_on .pCard_up {
  height: 100px;
  box-shadow: 0 0 30px #cfd8dc;
}

/* line 331, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_up .pCard_text {
  position: absolute;
  top: 319px;
  left: 0;
  right: 0;
  color: #f1f7f9;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

/* line 344, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_on .pCard_up .pCard_text {
  top: 20px;
}

/* line 348, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_up .pCard_text h2 {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

/* line 355, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_up .pCard_text p {
  margin: 0;
  font-size: 16px;
  color: #e3f1f5;
}

/* line 361, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_up .pCard_add {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #ed145b;
  -webkit-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  -ms-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  -o-box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.43);
  position: absolute;
  top: 392px;
  left: 0;
  right: 0;
  margin: auto;
  width: 88px;
  height: 88px;
  cursor: pointer;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

/* line 388, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_on .pCard_up .pCard_add {
  -webkit-transform: rotate(360deg) scale(0.5);
  -moz-transform: rotate(360deg) scale(0.5);
  -ms-transform: rotate(360deg) scale(0.5);
  -o-transform: rotate(360deg) scale(0.5);
  transform: rotate(360deg) scale(0.5);
  top: 470px;
}

/****************
  Down
  ****************/
/* line 401, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_down {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 178px;
  z-index: 2;
  -webkit-border-bottom-left-radius: 30px;
  -moz-border-bottom-left-radius: 30px;
  -ms-border-bottom-left-radius: 30px;
  -o-border-bottom-left-radius: 30px;
  border-bottom-left-radius: 30px;
  -webkit-border-bottom-right-radius: 30px;
  -moz-border-bottom-right-radius: 30px;
  -ms-border-bottom-right-radius: 30px;
  -o-border-bottom-right-radius: 30px;
  border-bottom-right-radius: 30px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

/* line 425, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_on .pCard_down {
  height: 100px;
  -webkit-box-shadow: 0 0 30px #cfd8dc;
  -moz-box-shadow: 0 0 30px #cfd8dc;
  -ms-box-shadow: 0 0 30px #cfd8dc;
  -o-box-shadow: 0 0 30px #cfd8dc;
  box-shadow: 0 0 30px #cfd8dc;
}

/* line 434, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_down div {
  width: 33.333%;
  float: left;
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

/* line 447, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_on .pCard_down div {
  margin-top: 10px;
}

/* line 451, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_down div p:first-of-type {
  color: #68818c;
  margin-bottom: 5px;
}

/* line 456, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_down div p:last-of-type {
  color: #334750;
  font-weight: 700;
  margin-top: 0;
}

/* line 461, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a i {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 36px;
  margin: 10px;
  display: inline-block;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  font-size: 15px;
}

/* line 481, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a i:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

/* line 489, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a:nth-of-type(1) i {
  color: #3b5998;
  border: 2px solid #3b5998;
}

/* line 494, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a:nth-of-type(2) i {
  color: #0077b5;
  border: 2px solid #0077b5;
}

/* line 499, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a:nth-of-type(3) i {
  color: #1769ff;
  border: 2px solid #1769ff;
}

/* line 504, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a:nth-of-type(4) i {
  color: #000000;
  border: 2px solid #000000;
}

/* line 509, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a:nth-of-type(5) i {
  color: #eb5e95;
  border: 2px solid #eb5e95;
}

/* line 514, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_back a:nth-of-type(6) i {
  color: #3f729b;
  border: 2px solid #3f729b;
}

/* line 519, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.pCard_card .pCard_up .pCard_add i {
  color: white;
  font-size: 38px;
  line-height: 88px;
}

/* line 528, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 {
  min-height: 500px;
  box-shadow: 0 0 5px 1px rgba(52, 52, 52, 0.224);
  position: relative;
  border-radius: 20px;
  transition: 2s;
}

/* line 534, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2:hover {
  box-shadow: 0 0 15px 1px rgba(52, 52, 52, 0.25);
}

/* line 537, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2::after {
  content: " ";
  background-image: linear-gradient(135deg, #3B76EF 10%, #3B76EF 100%);
  clip-path: circle(50% at 50% 1%);
  display: block;
  min-height: 500px;
  transition: 2s;
  border-radius: 20px;
}

/* line 546, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2:hover::after {
  content: " ";
  clip-path: circle(100% at 50% 170%);
  transition: 2s;
  display: block;
  min-height: 500px;
  transition: 2s;
  border-radius: 20px;
}

/* line 556, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 img {
  width: 100px !important;
  height: 100px !important;
  position: absolute;
  top: 78px;
  left: 37%;
  z-index: 99999999;
  transition: 2s;
  border-radius: 100%;
}

/* line 567, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 :hover img {
  transform: scale(1.3) translateY(20px);
  transition: 2s;
}

/* line 574, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 h1 {
  z-index: 999;
  position: absolute;
  justify-content: center;
  display: flex;
  width: 100%;
  font-family: "poppins bold";
  color: rgba(30, 29, 29, 0.85);
  letter-spacing: 2px;
}

/* line 585, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 p {
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: center;
  text-align: center;
  top: 50%;
  font-family: "poppins";
  font-weight: 500;
  margin: 0 20px;
  color: rgba(30, 29, 29, 0.85);
}

/* line 598, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 .social-area {
  position: absolute;
  bottom: 30px;
  z-index: 999;
  justify-content: center;
  display: flex;
  width: 100%;
}

/* line 607, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 .social-area a {
  color: rgba(30, 29, 29, 0.85);
}

/* line 611, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 .social-area a {
  margin: 15px 15px 0 15px;
  font-size: 20px;
  transition: 4s;
}

/* line 617, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 :hover .social-area svg {
  color: white;
  transition: 1s;
}

/* line 622, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile-cart_2 .social-area svg:hover {
  transform: scale(1.5);
}

/* line 631, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 .cover-photo {
  background: url(https://images.unsplash.com/photo-1540228232483-1b64a7024923?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80);
  height: 160px;
  width: 100%;
  border-radius: 5px 5px 0 0;
}

/* line 637, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 .profile {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #1f1a32;
  padding: 7px;
  background: #292343;
  margin-top: 80px;
  margin-left: 10px;
}

/* line 648, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 .profile-name {
  font-size: 25px;
  font-weight: bold;
  margin: 27px 0 0 120px;
}

/* line 653, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 .about {
  margin-top: 35px;
  line-height: 21px;
}

/* line 657, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 button {
  margin: 10px 0 40px 0;
}

/* line 660, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 .msg-btn, .profile_card_5 .follow-btn {
  background: #03bfbc;
  border: 1px solid #03bfbc;
  padding: 10px 25px;
  color: #231e39;
  border-radius: 3px;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
}

/* line 669, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 .follow-btn {
  margin-left: 10px;
  background: transparent;
  color: #02899c;
}

/* line 674, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 .follow-btn:hover {
  color: #231e39;
  background: #03bfbc;
  transition: .5s;
}

/* line 679, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 i {
  padding-left: 20px;
  font-size: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: .5s;
}

/* line 686, G:/admin_project/2 admin/finance/scss/_profilebox.scss */
.profile_card_5 i:hover {
  color: #03bfbc;
}

/* line 1, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart {
  text-align: center;
  padding: 1rem;
  position: relative;
}

/* line 5, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .rounded {
  border-radius: .25rem !important;
}

/* line 8, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .icon-wrapper {
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

/* line 14, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .icon-wrapper .icon-wrapper-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: .2;
}

/* line 21, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .icon-wrapper i {
  margin: 0 auto;
  font-size: 1.7rem;
  position: relative;
  z-index: 5;
  line-height: 54px;
}

/* line 30, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .widget-numbers {
  font-weight: bold;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
}

/* line 36, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .widget-numbers span {
  font-weight: bold;
  font-size: 2.5rem;
}

/* line 41, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .widget-subheading {
  margin-top: -.5rem;
}

/* line 44, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.widget-chart .widget-description {
  margin: 1rem 0 0;
}

/* line 49, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.bg-light {
  background-color: #eee !important;
}

/* line 52, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.icon-wrapper .icon-wrapper-bg.bg-light {
  opacity: .08;
}

/* line 55, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.bg-focus {
  background-color: #444054 !important;
}

/* line 58, G:/admin_project/2 admin/finance/scss/_chart_box.scss */
.bg-primary {
  background-color: #3f6ad8 !important;
}

/* line 2, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}

/* line 7, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card {
  margin-bottom: 15px;
  border: 0;
  overflow: visible;
}

/* line 11, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-header {
  border: 0;
  padding: 0;
  border-radius: 30px !important;
}

/* line 15, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-header h2 {
  padding: 0;
}

/* line 18, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-header h2 a {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  position: relative;
  position: relative;
  padding: 13px 20px;
  background: #3B76EF;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.25);
  color: #fff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 0;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* line 36, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-header h2 a::before {
  content: "\e622";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'themify';
  font-size: 14px;
  right: 30px;
  color: #fff;
}

/* line 47, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-header h2 a.collapsed {
  background: #F5F7FB;
  color: #415094;
  box-shadow: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* line 52, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-header h2 a.collapsed::before {
  content: "\e61a";
  color: #415094;
}

/* line 57, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-header h2 a:focus {
  text-decoration: none;
}

/* line 63, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-body {
  padding: 24px 20px 30px 20px;
}

@media (max-width: 575.98px) {
  /* line 63, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .accordion_custom .card .card-body {
    padding: 15px;
  }
}

/* line 68, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-body p {
  font-size: 14px;
  line-height: 26px;
  color: #555555;
  font-weight: 300;
  margin-bottom: 28px;
}

/* line 74, G:/admin_project/2 admin/finance/scss/_faq.scss */
.accordion_custom .card .card-body p:last-child {
  margin-bottom: 0;
}

/* line 83, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 30px;
}

@media (max-width: 575.98px) {
  /* line 83, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  /* line 83, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 83, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 83, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area {
    grid-template-columns: 6fr 6fr;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  /* line 83, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area {
    grid-template-columns: 5fr 7fr;
  }
}

/* line 103, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list .serach_field_2 {
  width: 100%;
}

/* line 106, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* line 110, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #eee1e2;
  padding: 20px 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 117, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li a .message_pre_left {
  display: flex;
  align-items: center;
}

/* line 123, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li a .message_pre_left .message_preview_thumb img {
  width: 50px;
  height: 50px;
}

/* line 128, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li a .message_pre_left .messges_info {
  padding-left: 20px;
}

/* line 131, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li a .message_pre_left h4 {
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 6px;
}

/* line 137, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li a .message_pre_left p {
  font-size: 13px;
  font-weight: 300;
  color: #676B84;
  margin-bottom: 0;
}

/* line 145, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li a .messge_time span {
  font-size: 12px;
  font-weight: 300;
  color: #cec1c2;
  white-space: nowrap;
}

/* line 153, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_list ul li:hover a {
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(255, 247, 248, 0.6);
}

/* line 163, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .messge_time span {
  white-space: nowrap;
}

/* line 167, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat {
  margin-bottom: 50px;
  padding-right: 20%;
}

@media (max-width: 575.98px) {
  /* line 167, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  /* line 167, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 167, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 167, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}

/* line 186, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_content_view {
  background: #fff7f8;
  border-radius: 10px;
  padding: 30px;
}

/* line 190, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_content_view.red_border {
  border: 1px solid #1E50EC;
}

/* line 193, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_content_view span {
  display: block;
  margin: 17px 0 17px 0;
}

/* line 197, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_content_view p {
  font-size: 13px;
  font-weight: 300;
  color: #676B84;
  line-height: 26px;
}

/* line 204, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_pre_left {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

/* line 210, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_pre_left .message_preview_thumb img {
  width: 50px;
  height: 50px;
}

/* line 215, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_pre_left .messges_info {
  padding-left: 20px;
}

/* line 218, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_pre_left h4 {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 2px;
}

/* line 224, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat .message_pre_left p {
  font-size: 12px;
  font-weight: 300;
  color: #676B84;
  margin-bottom: 0;
}

/* line 231, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat.sender_message {
  padding-right: 0%;
  padding-left: 20%;
}

@media (max-width: 575.98px) {
  /* line 231, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  /* line 231, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 231, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 231, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}

/* line 254, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat.sender_message .message_pre_left {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* line 260, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .single_message_chat.sender_message .messges_info {
  padding-left: 0px;
  padding-right: 20px;
  text-align: right;
}

/* line 267, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .message_send_field {
  padding-top: 50px;
  display: grid;
  grid-template-columns: auto 110px;
  grid-gap: 10px;
}

@media (max-width: 575.98px) {
  /* line 267, G:/admin_project/2 admin/finance/scss/_faq.scss */
  .messages_box_area .messages_chat .message_send_field {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
}

/* line 276, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .message_send_field input {
  color: #676B84;
  font-size: 13px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 25px;
  border: 1px solid #eee1e2;
  padding-right: 15px;
}

/* line 285, G:/admin_project/2 admin/finance/scss/_faq.scss */
.messages_box_area .messages_chat .message_send_field input::placeholder {
  color: #676B84;
  font-weight: 300;
  opacity: 1;
}

/* line 1, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 6;
  height: calc(100% - 3.9375rem);
}

/* line 7, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar h4 {
  font-weight: 600;
  font-size: .875rem;
  color: #333;
  padding: 2.5rem 0 3.125rem 1.5625rem;
}

/* line 13, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar ul {
  margin-bottom: 3.125rem;
}

/* line 15, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar ul li {
  display: block;
}

/* line 17, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar ul li.active {
  background: #3B76EF;
  box-shadow: 0 0.625rem 1.875rem 0 rgba(59, 118, 239, 0.3);
  color: #fff !important;
}

/* line 21, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar ul li.active a {
  color: #fff;
}

/* line 25, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar ul li a {
  display: block;
  padding: .625rem .625rem .625rem 1.5rem;
  transition: all .5s ease-in-out;
  font-weight: 600;
  font-size: .875rem;
  color: #333;
}

/* line 32, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar ul li a:hover {
  background: #3B76EF;
  box-shadow: 0 0.625rem 1.875rem 0 rgba(59, 118, 239, 0.3);
  color: #fff !important;
}

/* line 37, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.email-sidebar ul li a i {
  margin-right: .7rem;
}

/* line 1, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
/* line 47, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.primary_checkbox {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0;
  flex: 18px 0 0;
  line-height: 18px;
}

/* line 9, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
/* line 58, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.primary_checkbox .checkmark {
  position: relative;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  display: block;
  cursor: pointer;
  line-height: 18px;
  flex: 18px 0 0;
  border-radius: 50%;
}

/* line 26, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
/* line 72, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.primary_checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}

/* line 35, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
/* line 83, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.primary_checkbox input:checked ~ .checkmark:after {
  width: 100%;
  height: 100%;
  border: 0;
  transition: .3s;
  transform: scale(0);
}

/* line 44, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
/* line 92, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.primary_checkbox input:checked ~ .checkmark {
  background: #3B76EF !important;
  box-shadow: 0px 5px 10px rgba(59, 118, 239, 0.3) !important;
  transition: .3s;
}

/* line 52, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
/* line 99, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.primary_checkbox input:checked ~ .checkmark::before {
  content: "\e64c";
  font-family: "themify";
  position: absolute;
  display: block;
  top: 0px;
  left: 3px;
  text-indent: 1px;
  color: #828BB2;
  background-color: transparent;
  border: 0px;
  -webkit-transform: rotate(8deg);
  -moz-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  -o-transform: rotate(8deg);
  transform: rotate(8deg);
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  z-index: 99;
  color: #fff;
  transition: .3s;
}

/* line 76, ../../../../../../xampp/htdocs/CRM_frontend/admin/public/frontend/scss/_predefine.scss */
/* line 124, G:/admin_project/2 admin/finance/scss/_mailbox.scss */
.primary_checkbox .checkmark:after {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  content: "";
  width: 18px;
  height: 18px;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #828BB2;
  transition: .3s;
  transform: scale(1);
}

/*# sourceMappingURL=style.css.map */
